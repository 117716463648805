import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { normalize } from 'normalizr';
import { Box, Input } from '@chakra-ui/react';
import { AsyncSelect } from 'chakra-react-select';

import { FormInput, FormSelect } from '../../../common';
import request from '../../../util/request';
import { restaurant as restaurantSchema } from '../../../entities/api/schema';

import { addEntities as addEntitiesAction } from '../../../entities/redux/actions';

/* =============================================================================
<RestaurantFormType />
============================================================================= */
const RestaurantFormType = ({
  type,
  isNew,
  errors,
  watch,
  register,
  setValue,
  addEntities,
}) => {
  const fileInput = useRef();
  const photo = watch('photo', null);
  const isBranch = type === 'branch';
  const chainName = watch('chainName', null);

  useEffect(() => {
    if (!photo && fileInput.current) {
      fileInput.current.value = '';
    }
  }, [photo]);

  const _handlePhotoChange = (event) => {
    setValue('photo', event.target.files[0]);
  };

  const _handleLoadRestaurants = async (value) => {
    try {
      const payload = await request({
        url: `/restaurants/find?q=${value}`,
        method: 'GET',
      });

      const { entities } = normalize(payload.data, [restaurantSchema]);
      addEntities(entities);

      return payload.data.map((_restaurant) => ({
        label: _restaurant.name,
        value: _restaurant.id,
      }));
    } catch (e) {
      return [];
    }
  };

  const _handleRestaurantsChange = ({ value }) => {
    setValue('chain', value);
  };

  return (
    <Box flex={1}>
      {!isBranch && (
        <FormInput mb={6} label="Upload Logo" error={errors.photo}>
          <Input
            ref={fileInput}
            type="file"
            name="photo"
            accept="image/*"
            onChange={_handlePhotoChange}
          />
        </FormInput>
      )}
      <FormSelect
        mb={6}
        name="type"
        label="Type"
        options={TYPES}
        error={errors.type}
        selectProps={{ disabled: !isNew }}
        register={register}
      />
      {isBranch && (
        <FormInput mb={6} name="chain" label="Chain" error={errors.chain}>
            {isNew ? (
              <AsyncSelect
                id="restaurants"
                disabled={isNew}
                cacheOptions
                loadOptions={_handleLoadRestaurants}
                onChange={_handleRestaurantsChange}
              />
            ) : (
              <Input value={chainName} disabled />
            )}
        </FormInput>
      )}
      {!isBranch && (
        <FormInput
          mb={6}
          name="website"
          label="Website Link"
          error={errors.website}
          register={register}
        />
      )}
    </Box>
  );
};

const TYPES = [
  {
    label: 'Single',
    value: 'single',
  },
  {
    label: 'Chain',
    value: 'chain',
  },
  {
    label: 'Branch',
    value: 'branch',
  },
];

const mapDispatchToProps = {
  addEntities: addEntitiesAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(RestaurantFormType);
