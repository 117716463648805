import React, { useEffect, useState, useMemo } from 'react';
import { Box, Button, Grid, HStack, Image, Link, Text } from '@chakra-ui/react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { Card } from '../../common';
import request from '../../util/request';
import { MERCHANT_URL } from '../../config/secrets';

/* eslint-disable */
const SearchScreen = () => {
  const [neighborhoodId, setNeighborhoodId] = useState(null);
  const [restaurantId, setRestaurantId] = useState(null);
  const [results, setResults] = useState([]);
  const restaurant = useMemo(() => {
    let r;
    for (let i = 0; i < results.length; i++) {
      const c = results[i];
      r = c.restaurants.find((r) => r.id === restaurantId);
      if (r) break;
    }
    if (!r) return null;
    return r;
  }, [restaurantId]);

  const searchRestaurants = async (lat, lng) => {
    const d = new Date();
    const payload = await request({
      url: '/restaurants/delivery',
      method: 'GET',
      params: {
        d: d.getDay(),
        t: d.getHours() * 60 + d.getMinutes(),
        lat,
        lng,
      },
    });
    setResults(payload.data);
    setNeighborhoodId(payload.neighborhoodId);
  };

  const defaultBounds = {
    north: 25.113819,
    south: 24.705638,
    east: 67.570431,
    west: 66.68641,
  };

  const onNavigateBack = () => {
    setRestaurantId(null);
  };
  return (
    <Box>
      {restaurantId && restaurant ? (
        <RestaurantScreen
          id={restaurantId}
          restaurant={restaurant}
          neighborhoodId={neighborhoodId}
          onNavigateBack={onNavigateBack}
        />
      ) : (
        <>
          <ReactGoogleAutocomplete
            apiKey={'AIzaSyASyGzDB5MAtfVEm-hongiYytM2wMFLWsU'}
            options={{
              types: [],
              componentRestrictions: { country: 'pk' },
              bounds: defaultBounds,
            }}
            onPlaceSelected={(place) =>
              searchRestaurants(
                place.geometry.location.lat(),
                place.geometry.location.lng(),
              )
            }
            style={{
              width: '100%',
              borderRadius: '0.5rem',
              padding: '0.5rem 1rem',
            }}
          />
          <Grid
            mt={4}
            gridTemplateColumns="repeat(2, calc(50% - 8px))"
            gridGap={4}
          >
            {results.length > 0 &&
              results.map((c) => (
                <Card key={c.id} w="full" px={4} py={2}>
                  <Text fontWeight="bold" fontSize="lg">
                    {c.category.name}
                  </Text>

                  {c.restaurants.map((r) => (
                    <HStack mt={2} key={r.id}>
                      <Image
                        w="30px"
                        h="30px"
                        src={r.photos?.[0] || r.chain?.photos?.[0]}
                      />
                      <Link
                        tabIndex={0}
                        _hover={{
                          color: 'blue.500',
                          textDecoration: 'underline',
                        }}
                        onClick={() => setRestaurantId(r.id)}
                      >
                        {r.name || r.chain?.name}
                      </Link>
                    </HStack>
                  ))}
                </Card>
              ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

const RestaurantScreen = ({
  id,
  restaurant,
  neighborhoodId,
  onNavigateBack,
}) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    request({
      url: `/restaurants/${id}/token`,
      method: 'post',
    }).then(setResult);
  }, [id]);

  if (!result) return null;

  return (
    <Box>
      <HStack justify="space-between">
        <HStack>
          <Button my={4} onClick={onNavigateBack}>
            Go Back
          </Button>
          <Text fontSize="lg" fontWeight="bold">
            {restaurant.name || restaurant?.chain?.name}
          </Text>
        </HStack>
        <Text>Delivery Fees: {restaurant.deliveryFees[neighborhoodId]}</Text>
      </HStack>
      <iframe
        width="100%"
        height="550px"
        src={`${MERCHANT_URL}?code=${btoa(JSON.stringify(result))}`}
      />
    </Box>
  );
};

export default SearchScreen;
