import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
} from '@chakra-ui/react';

import NotificationTableItem from './NotificationTableItem';
import NotificationTableHeadItem from './NotificationTableHeadItem';

/* =============================================================================
<NotificationTable />
============================================================================= */
const NotificationTable = ({ data }) => (
  <Box flex={1} overflow="auto">
    <Table variant="simple" colorScheme="gray" size="lg">
      <Thead>
        <Tr>
          {columns.map(renderColumn)}
        </Tr>
      </Thead>
      <Tbody>
        {data.map(renderItem)}
      </Tbody>
    </Table>
  </Box>
);

const renderColumn = (item, i) => <NotificationTableHeadItem key={`${i}`} title={item} />;
const renderItem = (item) => <NotificationTableItem key={item} id={item} />;

const columns = [
  'Title',
  'Notification',
  'Screen',
  'Created At',
  'Schedule',
  'Action',
];

/* Export
============================================================================= */
export default NotificationTable;
