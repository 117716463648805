import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  List,
  Text,
  Divider,
  Button,
  Center,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { Card } from '../../../common';
import DealCreateModal from '../DealModals/DealCreateModal';
import DealsListItem from './DealsListItem';
import { reorder } from '../../../util/functions';

import { getDeals as selectDeals } from '../../redux/selectors';
import {
  getDeals as getDealsAction,
  updateDeals as updateDealsAction,
  updateDeal as updateDealAction,
} from '../../redux/actions';

/* =============================================================================
<DealsList />
============================================================================= */
const DealsList = ({
  deals,
  selected,
  onItemClick,
  getDeals,
  updateDeal,
  updateDeals,
}) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [items, setItems] = useState([]);

  // Get deals
  useEffect(() => {
    getDeals();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set deals
  useEffect(() => {
    setItems(deals);
  }, [deals]);

  const _updateDeal = (update, reordered) => {
    updateDeal(update, (err) => {
      if (!err) {
        updateDeals(reordered);
      } else {
        toast({
          title: 'Error',
          description: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  const _handleDragEnd = async (result) => {
    // Dropped outside the categories list
    if (result?.destination?.droppableId !== 'deals') {
      return;
    }

    // Dropped at the same index
    if (result.source.index === result.destination.index) {
      return;
    }

    const id = items[result.source.index];
    const reordered = reorder(
      items,
      result.source.index,
      result.destination.index,
    );
    setItems(reordered);
    _updateDeal({
      id,
      rank: result.destination.index + 1,
    }, reordered);
  };

  return (
    <Card layerStyle="street.card">
      <Text textStyle="street.title">Deals And Promotion</Text>
      <Divider mt={4} />
      <DragDropContext onDragEnd={_handleDragEnd}>
        <Droppable droppableId="deals">
          {(provided, snapshot) => (
            <List
              ref={provided.innerRef}
              {...provided.droppableProps}
              layerStyle="street.list"
              bg={snapshot.isDraggingOver ? 'blue.50' : 'inherit'}
            >
              {items?.map((item, index) => (
                <DealsListItem
                  id={item}
                  key={item}
                  index={index}
                  selected={selected === item}
                  onClick={onItemClick}
                />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      <Center>
        <Button
          mb={5}
          size="sm"
          colorScheme="purple"
          onClick={onOpen}
        >
          Create
        </Button>

      </Center>
      <DealCreateModal isOpen={isOpen} onClose={onClose} />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  deals: selectDeals(state),
});

const mapDispatchTopProps = {
  getDeals: getDealsAction,
  updateDeals: updateDealsAction,
  updateDeal: updateDealAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchTopProps)(DealsList);
