import React from 'react';
import { Switch, Route } from 'react-router-dom';

import QRCodesScreen from './QRCodesScreen';
import EditQRCodeScreen from './EditQRCodeScreen';
import CreateQRCodeScreen from './CreateQRCodeScreen';

/* =============================================================================
<QRCodesScreens />
============================================================================= */
const QRCodesScreens = () => (
  <Switch>
    <Route path="/qrcodes" exact>
      <QRCodesScreen />
    </Route>
    <Route path="/qrcodes/create" exact>
      <CreateQRCodeScreen />
    </Route>
    <Route path="/qrcodes/:id" exact>
      <EditQRCodeScreen />
    </Route>
  </Switch>
);

/* Export
============================================================================= */
export default QRCodesScreens;
