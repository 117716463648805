import React from 'react';
import {
  Drawer,
  Button,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
} from '@chakra-ui/react';
import { ArrowLeft } from 'react-feather';

import PostDetailsTimingAndVotes from './PostDetailsTimingAndVotes';
import PostDetailsUserInfo from './PostDetailsUserInfo';
import PostDetailsRestaurantInfo from './PostDetailsRestaurantInfo';

/* =============================================================================
<PostDetailsModal />
============================================================================= */
const PostDetailsModal = ({
  id,
  onClose,
  isOpen,
}) => (
  <Drawer size="lg" isOpen={isOpen} placement="right" onClose={onClose}>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerHeader>
        <Button
          variant="outline"
          colorScheme="gray"
          leftIcon={<ArrowLeft size="20px" />}
          onClick={onClose}
        >
          Back to Post
        </Button>
      </DrawerHeader>
      <DrawerBody>
        <PostDetailsTimingAndVotes id={id} />
        <PostDetailsUserInfo id={id} />
        <PostDetailsRestaurantInfo id={id} />
      </DrawerBody>
    </DrawerContent>
  </Drawer>
);

/* Export
============================================================================= */
export default PostDetailsModal;
