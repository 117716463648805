/* eslint-disable react/no-array-index-key */
import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Text,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';

import { getOrder } from '../../../entities/redux/selectors';

/* =============================================================================
<LiveOrdersCardNotes />
============================================================================= */
const LiveOrdersCardNotes = ({ order }) => {
  const notes = order?.items
    ?.map((item) => item?.notes || '')
    ?.filter((note) => note);

  if (!notes?.length) {
    return null;
  }

  return (
    <Box>
      <Text mb={1} fontSize="sm">Special Instructions:</Text>
      <UnorderedList>
        {notes?.map((note, i) => (
          <ListItem key={i}>
            <Text fontSize="sm" fontWeight="semibold" mb={2}>{note}</Text>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.items?.map((item) => item?.notes)?.toString()
    === nextProps.order?.items?.map((item) => item?.notes)?.toString()
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(LiveOrdersCardNotes, propsAreEqual));
