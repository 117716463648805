import * as constants from './constants';

export const INITIAL_STATE = {
  transactions: {},
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // WALLET_FIND
    case constants.WALLET_FIND.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.WALLET_FIND.FAIL:
      return {
        ...state,
        error,
      };
    case constants.WALLET_FIND.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.WALLET_FIND.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // WALLET_CREATE
    case constants.WALLET_CREATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.WALLET_CREATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.WALLET_CREATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.WALLET_CREATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // WALLET_UPDATE
    case constants.WALLET_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.WALLET_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.WALLET_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.WALLET_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // WALLET_TRANSACTIONS_GET
    case constants.WALLET_TRANSACTIONS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.WALLET_TRANSACTIONS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.WALLET_TRANSACTIONS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        transactions: {
          ...state.transactions,
          [payload.wallet]: {
            data: [
              ...new Set([
                ...state.transactions[payload.wallet]?.data,
                ...payload.data
              ])
            ],
            nextCursor: payload.nextCursor,
          }
        },
      };
    case constants.WALLET_TRANSACTIONS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // WALLET_TRANSACTIONS_REFRESH
    case constants.WALLET_TRANSACTIONS_REFRESH.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.WALLET_TRANSACTIONS_REFRESH.FAIL:
      return {
        ...state,
        error,
      };
    case constants.WALLET_TRANSACTIONS_REFRESH.SUCCESS:
      return {
        ...state,
        error: null,
        transactions: {
          ...state.transactions,
          [payload.wallet]: {
            data: payload.data,
            nextCursor: payload.nextCursor,
          }
        },
      };
    case constants.WALLET_TRANSACTIONS_REFRESH.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
