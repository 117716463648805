import React from 'react';
import {
  Font,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import moment from 'moment';
import LogoLong from '../../assets/images/logo-long.png';
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    fontSize: 10,
    fontFamily: 'Open Sans',
    paddingHorizontal: 45,
  },
  header: {
    flexDirection: 'row',
  },
  topLeftSection: {},
  topRightSection: {
    margin: 15,
    paddingVertical: 5,
    borderLeftStyle: 'solid',
    borderLeftColor: '#000000',
    borderLeftWidth: 1,
    paddingLeft: 5,
    textAlign: 'justify',
  },
  logo: {
    width: 220,
    marginTop: 32,
  },
});

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
  ],
});

const InvoicePreview = ({
  invoiceId,
  customerLegalName,
  customerName,
  customerAddress,
  customerVatId,
  customerBankAccName,
  customerBankAccId,
  customerBankName,
  invoiceTaxPerc,
  serviceFee,
  onlineBankFeePerc,
  onlineBankFee,
  onlineBase,
  onlineQty,
  onlineTotal,
  ordersTotalQty,
  commissionPerc,
  commissionBase,
  commission,
  invoicePeriodFrom,
  invoicePeriodTo,
  invoiceDate,
  invoiceNet,
  invoiceTax,
  invoiceGross,
  invoiceBalance,
  bykeaCodBase,
  fsDiscount,
  //   fsDiscountQty,
  penaltyQty,
  //   penaltyBase,
  penaltyTotal,
}) => (
  <Document
    title={`Invoice ${invoiceId} ${customerName} ${invoicePeriodFrom}_${invoicePeriodTo}`}
    author="Food Street"
    language="en-us"
  >
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <View style={{ flexGrow: 1 }}>
          <Image style={styles.logo} src={LogoLong} />
        </View>
        <View style={styles.topRightSection}>
          <Text style={{ fontWeight: 600 }}>
            FOOD STREET TECHNOLOGIES PVT. LTD.
          </Text>
          <View style={{ fontSize: 8 }}>
            <Text style={{ maxWidth: 190, marginTop: 2 }}>
              Plot # 2C, 2B Jami Commercial Street 11, D.H.A. Phase 7, Karachi,
              Sindh 77550, Pakistan
            </Text>
            <View style={{ flexDirection: 'row', marginTop: 2 }}>
              <Text>NTN:</Text>
              <Text style={{ marginLeft: 5 }}>9572778</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text>W</Text>
              <Text style={{ marginLeft: 5 }}>foodstreetpk.com</Text>
            </View>
          </View>
        </View>
      </View>
      <View>
        <Text style={{ fontSize: 8 }}>Sales Tax Invoice</Text>
      </View>
      <View style={{ marginTop: 25, width: 370 }}>
        <Text style={{ textTransform: 'uppercase' }}>{customerLegalName}</Text>
        <Text>{customerName}</Text>
        <Text style={{ textTransform: 'uppercase' }}>{customerAddress}</Text>
        <View style={{ flexDirection: 'row', fontSize: 8, marginTop: 5 }}>
          <Text>Vat No.</Text>
          <Text style={{ marginLeft: 5 }}>{customerVatId}</Text>
        </View>
      </View>
      <View
        style={{
          paddingVertical: 5,
          paddingHorizontal: 10,
          marginTop: 25,
          borderTopStyle: 'solid',
          borderBottomStyle: 'solid',
          borderTopWidth: 1,
          borderBottomWidth: 1,
          flexDirection: 'row',
          fontWeight: 600,
        }}
      >
        <View style={{ flexDirection: 'row', flexGrow: 1 }}>
          <Text>Invoice No.:</Text>
          <Text style={{ marginLeft: 5 }}>{invoiceId}</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text>Invoice Date:</Text>
          <Text style={{ marginLeft: 5 }}>
            {moment(invoiceDate).format('DD.MM.YYYY')}
          </Text>
        </View>
      </View>
      <View style={{ marginTop: 15 }}>
        <Text>Dear Sir or Madam</Text>
        <View style={{ flexDirection: 'row' }}>
          <Text>We hereby invoice you our services for the period </Text>
          <Text style={{ fontWeight: 600 }}>
            {moment(invoicePeriodFrom).format('DD.MM.YYYY')}{' '}
          </Text>
          <Text>to </Text>
          <Text style={{ fontWeight: 600 }}>
            {moment(invoicePeriodTo).format('DD.MM.YYYY')}{' '}
          </Text>
          <Text>as follows:</Text>
        </View>
      </View>
      <View style={{ marginTop: 10 }}>
        <View
          style={{
            borderStyle: 'solid',
            borderWidth: 1,
            paddingVertical: 4,
            paddingHorizontal: 2,
            fontWeight: 600,
            flexDirection: 'row',
          }}
        >
          <View style={{ width: 120 }}>
            <Text>Item Description</Text>
          </View>
          <View style={{ width: 100, textAlign: 'right' }}>
            <Text>Quantity</Text>
          </View>
          <View style={{ width: 130, textAlign: 'center' }}>
            <Text>Price</Text>
          </View>
          <View style={{ width: 100, textAlign: 'center' }}>
            <Text>Comm. Base</Text>
          </View>
          <View style={{ width: 150, textAlign: 'center' }}>
            <Text>Total Value</Text>
          </View>
        </View>
        <View
          style={{
            marginTop: 2,
            borderStyle: 'solid',
            borderWidth: 1,
          }}
        >
          <View
            style={{
              paddingVertical: 4,
              paddingHorizontal: 2,
              flexDirection: 'row',
            }}
          >
            <View style={{ width: 120, fontWeight: 600 }}>
              <Text>Service Fee</Text>
            </View>
            <View style={{ width: 100, textAlign: 'right' }}>
              <Text>1</Text>
            </View>
            <View style={{ width: 130 }} />
            <View style={{ width: 100 }} />
            <View style={{ width: 150, textAlign: 'right' }}>
              <Text>{serviceFee.toLocaleString()}.00 PKR</Text>
            </View>
          </View>
          <View
            style={{
              paddingVertical: 4,
              paddingHorizontal: 2,
              flexDirection: 'row',
            }}
          >
            <View style={{ width: 120, fontWeight: 600 }}>
              <Text>Penalty</Text>
            </View>
            <View style={{ width: 100, textAlign: 'right' }}>
              <Text>{penaltyQty.toLocaleString()}</Text>
            </View>
            <View style={{ width: 130 }} />
            <View style={{ width: 100 }} />
            <View style={{ width: 150, textAlign: 'right' }}>
              <Text>{penaltyTotal}.00 PKR</Text>
            </View>
          </View>
          <View
            style={{
              paddingVertical: 4,
              paddingHorizontal: 2,
              flexDirection: 'row',
            }}
          >
            <View style={{ width: 170, fontWeight: 600 }}>
              <Text>Online payments Bank fee</Text>
            </View>
            <View style={{ width: 50, textAlign: 'right' }}>
              <Text>{onlineQty.toLocaleString()}</Text>
            </View>
            <View style={{ width: 130, textAlign: 'center' }}>
              <Text>{onlineBankFeePerc}%</Text>
            </View>
            <View style={{ width: 100, textAlign: 'right' }}>
              <Text>{onlineBase.toLocaleString()}.00 PKR</Text>
            </View>
            <View style={{ width: 150, textAlign: 'right' }}>
              <Text>{onlineBankFee.toLocaleString()}.00 PKR</Text>
            </View>
          </View>
          <View
            style={{
              paddingVertical: 4,
              paddingHorizontal: 2,
              flexDirection: 'row',
            }}
          >
            <View style={{ width: 120, fontWeight: 600 }}>
              <Text>Commission</Text>
            </View>
            <View style={{ width: 100, textAlign: 'right' }}>
              <Text>{ordersTotalQty.toLocaleString()}</Text>
            </View>
            <View style={{ width: 130, textAlign: 'center' }}>
              <Text>{commissionPerc}%</Text>
            </View>
            <View style={{ width: 100, textAlign: 'right' }}>
              <Text>{commissionBase.toLocaleString()}.00 PKR</Text>
            </View>
            <View style={{ width: 150, textAlign: 'right' }}>
              <Text>{commission.toLocaleString()}.00 PKR</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            borderLeftStyle: 'solid',
            borderBottomStyle: 'solid',
            borderRightStyle: 'solid',
            borderLeftWidth: 1,
            borderBottomWidth: 1,
            borderRightWidth: 1,
          }}
        >
          <View
            style={{
              paddingVertical: 4,
              paddingHorizontal: 2,
              flexDirection: 'row',
            }}
          >
            <View style={{ width: 120, fontWeight: 600 }} />
            <View style={{ width: 100, textAlign: 'right' }} />
            <View style={{ width: 130 }} />
            <View style={{ width: 100, textAlign: 'right', fontWeight: 600 }}>
              <Text>Net Invoice total</Text>
            </View>
            <View style={{ width: 150, textAlign: 'right' }}>
              <Text>{invoiceNet.toLocaleString()}.00 PKR</Text>
            </View>
          </View>
          <View
            style={{
              paddingVertical: 4,
              paddingHorizontal: 2,
              flexDirection: 'row',
            }}
          >
            <View style={{ width: 120, fontWeight: 600 }} />
            <View style={{ width: 100, textAlign: 'right' }}>
              <Text>Net Tax Base</Text>
            </View>
            <View style={{ width: 130, textAlign: 'center' }}>
              <Text>{invoiceNet.toLocaleString()}.00 PKR</Text>
            </View>
            <View style={{ width: 100, fontWeight: 600, textAlign: 'right' }}>
              <Text>GST {invoiceTaxPerc}%</Text>
            </View>
            <View style={{ width: 150, textAlign: 'right' }}>
              <Text>{invoiceTax.toLocaleString()}.00 PKR</Text>
            </View>
          </View>
          <View
            style={{
              borderTopWidth: 1,
              paddingVertical: 4,
              paddingHorizontal: 2,
              flexDirection: 'row',
            }}
          >
            <View style={{ width: 120, fontWeight: 600 }} />
            <View style={{ width: 100, textAlign: 'right' }} />
            <View style={{ width: 100, textAlign: 'center' }} />
            <View style={{ width: 130, fontWeight: 600, textAlign: 'right' }}>
              <Text>Gross Invoice total</Text>
            </View>
            <View style={{ width: 150, textAlign: 'right' }}>
              <Text>{invoiceGross.toLocaleString()}.00 PKR</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={{ marginTop: 25 }}>
        <View
          style={{
            borderStyle: 'solid',
            borderWidth: 1,
            paddingVertical: 4,
            paddingHorizontal: 2,
            fontWeight: 600,
            flexDirection: 'row',
          }}
        >
          <View style={{ width: 120 }}>
            <Text>Payout Summary</Text>
          </View>
        </View>
        <View
          style={{
            borderLeftWidth: 1,
            borderBottomWidth: 1,
            borderRightWidth: 1,
          }}
        >
          <View
            style={{
              paddingVertical: 4,
              paddingHorizontal: 2,
              flexDirection: 'row',
            }}
          >
            <View style={{ width: 245, fontWeight: 600 }}>
              <Text>Online dues</Text>
            </View>
            <View style={{ width: 100 }} />
            <View style={{ width: 100 }} />
            <View style={{ width: 150, textAlign: 'right' }}>
              <Text>-{onlineTotal.toLocaleString()}.00 PKR</Text>
            </View>
          </View>
          <View
            style={{
              paddingVertical: 4,
              paddingHorizontal: 2,
              flexDirection: 'row',
            }}
          >
            <View style={{ width: 180, fontWeight: 600 }}>
              <Text>Food Street Discounts</Text>
            </View>
            <View style={{ width: 100 }} />
            <View style={{ width: 70 }} />
            <View style={{ width: 100 }} />
            <View style={{ width: 150, textAlign: 'right' }}>
              <Text>-{fsDiscount.toLocaleString()}.00 PKR</Text>
            </View>
          </View>
          <View
            style={{
              paddingVertical: 4,
              paddingHorizontal: 2,
              flexDirection: 'row',
            }}
          >
            <View style={{ width: 180, fontWeight: 600 }}>
              <Text>COD collected by Bykea</Text>
            </View>
            <View style={{ width: 100 }} />
            <View style={{ width: 70 }} />
            <View style={{ width: 100 }} />
            <View style={{ width: 150, textAlign: 'right' }}>
              <Text>-{bykeaCodBase.toLocaleString()}.00 PKR</Text>
            </View>
          </View>
          <View
            style={{
              paddingVertical: 4,
              paddingHorizontal: 2,
              flexDirection: 'row',
            }}
          >
            <View style={{ width: 180, fontWeight: 600 }}>
              <Text>Gross Invoice total</Text>
            </View>
            <View style={{ width: 100, textAlign: 'right' }} />
            <View style={{ width: 70 }} />
            <View style={{ width: 100 }} />
            <View style={{ width: 150, textAlign: 'right' }}>
              <Text>{invoiceGross.toLocaleString()}.00 PKR</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            borderLeftStyle: 'solid',
            borderBottomStyle: 'solid',
            borderRightStyle: 'solid',
            borderLeftWidth: 1,
            borderBottomWidth: 1,
            borderRightWidth: 1,
          }}
        >
          <View
            style={{
              paddingVertical: 4,
              paddingHorizontal: 2,
              flexDirection: 'row',
            }}
          >
            <View style={{ width: 120, fontWeight: 600 }} />
            <View style={{ width: 100, textAlign: 'right' }} />
            <View style={{ width: 0 }} />
            <View style={{ width: 230, textAlign: 'right', fontWeight: 600 }}>
              <Text>
                Total Amount payable by{' '}
                {invoiceBalance < 0 ? 'Food Street' : 'Customer'}
              </Text>
            </View>
            <View style={{ width: 150, textAlign: 'right' }}>
              <Text>{invoiceBalance.toLocaleString()}.00 PKR</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={{ marginTop: 10, fontSize: 8 }}>
        <Text>Customer Bank Details</Text>
        <View style={{ flexDirection: 'row' }}>
          <Text>Beneficiary:</Text>
          <Text style={{ marginLeft: 41 }}>{customerBankAccName}</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text>Account Number:</Text>
          <Text style={{ marginLeft: 20 }}>{customerBankAccId}</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text>Bank Name:</Text>
          <Text style={{ marginLeft: 40 }}>{customerBankName}</Text>
        </View>
      </View>
      <View style={{ marginTop: 45, fontSize: 6 }}>
        <Text>
          This is a system generated invoice and doesn&apos;t require a
          signature. If you have any questions, feel free to contact us.
        </Text>
      </View>
      <View
        style={{
          marginTop: 20,
          flexDirection: 'row',
          fontSize: 8,
          color: '#9b9b9b',
        }}
      >
        <View style={{ width: 150, paddingRight: 10 }}>
          <Text>Food Street Technologies Pvt. Ltd.</Text>
          <Text>
            Plot # 2C, 2B Jami Commercial Street 11, D.H.A. Phase 7, Karachi,
            Sindh 77550, Pakistan
          </Text>
        </View>
        <View style={{ flexGrow: 1, paddingLeft: 20, paddingRight: 20 }}>
          <View style={{ flexDirection: 'row' }}>
            <Text>T</Text>
            <Text style={{ marginLeft: 7 }}>021-3802-4713</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text>E</Text>
            <Text style={{ marginLeft: 7 }}>info@foodstreetpk.com</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text>W</Text>
            <Text style={{ marginLeft: 5 }}>foodstreetpk.com</Text>
          </View>
        </View>
        <View>
          <View style={{ flexDirection: 'row' }}>
            <Text>Bank Acc. Name:</Text>
            <Text style={{ marginLeft: 5 }}>
              Food Street Technologies (Private) Ltd.
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text>Bank Acc. Number:</Text>
            <Text style={{ marginLeft: 5 }}>PK54 BAHL 1087 0981 0102 3001</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text>Bank Name</Text>
            <Text style={{ marginLeft: 5 }}>Bank AL Habib Limited</Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default InvoicePreview;
