import React from 'react';
import { FastField } from 'formik';
import {
  Radio,
  Input,
  HStack,
  FormLabel,
  RadioGroup,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<DiscountTitleAndType />
============================================================================= */
const DiscountTitleAndType = () => (
  <HStack spacing={7} align="flex-start">
    <FastField name="name">
      {({ field, form }) => (
        <FormControl mb={8} isInvalid={form.errors.name && form.touched.name}>
          <FormLabel fontSize="16px" fontWeight="normal">Discount Title</FormLabel>
          <Input id="name" placeholder="Enter Discount Title" {...field} />
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.name}
          </FormErrorMessage>
        </FormControl>
      )}
    </FastField>
    <FastField name="type">
      {({ form }) => (
        <FormControl
          mb={8}
          isInvalid={form.errors.type && form.touched.type}
        >
          <FormLabel fontSize="16px" fontWeight="normal">Discount Type</FormLabel>
          <RadioGroup
            size="lg"
            mt={3}
            colorScheme="purple"
            value={form.values.type}
            onChange={form.handleChange('type')}
          >
            <HStack spacing={8}>
              <Radio fontSize="lg" value="percentage">Percentage</Radio>
              <Radio fontSize="lg" value="amount">Amount</Radio>
            </HStack>
          </RadioGroup>
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.type}
          </FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  </HStack>
);

/* Export
============================================================================= */
export default DiscountTitleAndType;
