import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CouponsScreen from './CouponsScreen';
import EditCouponScreen from './EditCouponScreen';
import CreateCouponScreen from './CreateCouponScreen';

/* =============================================================================
<CouponsScreens />
============================================================================= */
const CouponsScreens = () => (
  <Switch>
    <Route path="/coupons" exact>
      <CouponsScreen />
    </Route>
    <Route path="/coupons/create" exact>
      <CreateCouponScreen />
    </Route>
    <Route path="/coupons/:id" exact>
      <EditCouponScreen />
    </Route>
  </Switch>
);

/* Export
============================================================================= */
export default CouponsScreens;
