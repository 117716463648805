import React from 'react';
import { connect } from 'react-redux';
import { Heading, useToast } from '@chakra-ui/react';

import {Card} from '../../common';
import CouponForm from '../components/CouponForm';

import {createCoupon as createCouponAction} from '../redux/actions';

/* =============================================================================
<CreateCouponScreen />
============================================================================= */
const CreateCouponScreen = ({createCoupon}) => {
  const toast = useToast();

  const _handleSubmit = (form) => async (values) => {
    await createCoupon(values, (err) => {
      if (err) {
        toast({
          title: 'Error',
          status: 'error',
          duration: 3000,
          isClosable: true,
          description: err.message,
        });
      } else {
        form.reset();
        toast({
          title: 'Coupon created successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Card flex={1} overflow="auto">
      <Heading fontSize="4xl" fontWeight="semibold">Create Coupon</Heading>
      <CouponForm onSubmit={_handleSubmit} />
    </Card>
  );
};

const mapDispatchToProps = {
  createCoupon: createCouponAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(CreateCouponScreen);
