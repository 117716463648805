import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotificationScreen from './NotificationScreen';
import CreateNotificationScreen from './CreateNotificationScreen';

/* =============================================================================
<NotificationScreens />
============================================================================= */
const NotificationScreens = () => (
  <Switch>
    <Route path="/notifications" exact>
      <NotificationScreen />
    </Route>
    <Route path="/notifications/create" exact>
      <CreateNotificationScreen />
    </Route>
  </Switch>
);

/* Export
============================================================================= */
export default NotificationScreens;
