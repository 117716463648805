import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Box, Text, Divider, useToast } from '@chakra-ui/react';

import { Card } from '../../common';
import RestaurantForm from '../components/RestaurantForm';
import { uploadRestaurantPhoto } from '../../util/cloudinary';

import {
  getRestaurant as getRestaurantAction,
  updateRestaurant as updateRestaurantAction,
} from '../redux/actions';

/* =============================================================================
<EditRestaurantScreen />
============================================================================= */
const EditRestaurantScreen = ({ getRestaurant, updateRestaurant }) => {
  const toast = useToast();
  const id = useParams()?.id;
  const [restaurant, setRestaurant] = useState(null);
  const [initialValues, setInitialValues] = useState({});

  // Get restaurant
  useEffect(() => {
    if (id) {
      getRestaurant(id, (err, payload) => {
        if (payload) {
          setRestaurant(payload);
          setInitialValues({
            type: payload.type,
            name: payload.name,
            email: payload.email,
            about: payload.about,
            vatExclusive: payload.vatExclusive,
            address: {
              text: payload.address?.text,
              branch: payload.address?.branch,
              city: payload.address?.city,
              country: payload.address?.country,
              postcode: payload.address?.postcode,
            },
            location: payload.location,
            phone: {
              branch: payload.phone?.branch,
              delivery1: payload.phone?.delivery1,
              delivery2: payload.phone?.delivery2,
            },
            website: payload.website,
            owner: {
              email: payload.owner?.email,
              phone: payload.owner?.phone,
              firstName: payload.owner?.profile?.firstName,
              lastName: payload.owner?.profile?.lastName,
            },
            chain: payload.chain?.id,
            chainName: payload.chain?.name,
            category1: payload.categories && payload.categories[0]?.id,
            category2: payload.categories && payload.categories[1]?.id,
            category3: payload.categories && payload.categories[2]?.id,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const _handleSubmit = () => async (values) => {
    if (!restaurant) {
      return;
    }

    try {
      const _restaurant = {
        ...values,
        owner: {
          ...values.owner,
          email: values.owner.email.toLowerCase(),
          id: restaurant.owner?.id,
        },
        id: restaurant.id,
        categories: [values.category1],
      };
      if (values.photo) {
        const photoURL = await uploadRestaurantPhoto(values.photo);
        _restaurant.photo = photoURL;
      }
      if (values.category2) {
        _restaurant.categories.push(values.category2);
      }
      if (values.category3) {
        _restaurant.categories.push(values.category3);
      }
      await updateRestaurant(_restaurant, (err) => {
        if (err) {
          toast({
            title: 'Error occured.',
            description: err?.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Restaurant updated.',
            description: 'Restaurant updated successfully.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        }
      });
    } catch (e) {
      toast({
        title: 'Error occured.',
        description: e?.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Card layerStyle="street.card">
      <Text textStyle="street.title" mx={5} align="left">
        Restaurant Information
      </Text>
      <Divider mt={4} />
      <Box layerStyle="street.list">
        <RestaurantForm
          initialValues={initialValues}
          validationSchema={EditRestaurantSchema}
          onSubmit={_handleSubmit}
        />
      </Box>
    </Card>
  );
};

const EditRestaurantSchema = Yup.object().shape({
  owner: Yup.object().shape({
    firstName: Yup.string().required('Must not be empty!'),
    lastName: Yup.string().required('Must not be empty!'),
    email: Yup.string()
      .email('Must be a valid email')
      .required('Must not be empty!'),
    phone: Yup.string()
      .matches(
        /^\+[1-9]\d{10,14}$/,
        'Must be a valid phone number in E164 format!',
      )
      .required('Must not be empty!'),
    password: Yup.string().optional(),
  }),
  name: Yup.string().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.string().required('Must not be empty!'),
  }),
  email: Yup.string().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.string()
      .email('Must be a valid email')
      .required('Must not be empty!'),
  }),
  about: Yup.string().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.string().required('Must not be empty!'),
  }),
  vatExclusive: Yup.boolean().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.boolean().required('Must not be empty'),
  }),
  address: Yup.object()
    .when('type', {
      is: (v) => v === 'single' || v === 'chain',
      then: Yup.object().shape({
        text: Yup.string().required('Must not be empty!'),
        city: Yup.string().required('Must not be empty!'),
        country: Yup.string().required('Must not be empty!'),
        postcode: Yup.string().required('Must not be empty!'),
      }),
    })
    .when('type', {
      is: 'branch',
      then: Yup.object().shape({
        text: Yup.string().required('Must not be empty!'),
        branch: Yup.string().required('Must not be empty!'),
        city: Yup.string().required('Must not be empty!'),
        country: Yup.string().required('Must not be empty!'),
        postcode: Yup.string().required('Must not be empty!'),
      }),
    }),
  location: Yup.array()
    .of(Yup.number())
    .length(2, 'Please place a marker at the desired location!')
    .required('Must not be empty!'),
  photo: Yup.mixed().optional(),
  type: Yup.string().required('Must not be empty!'),
  chain: Yup.string().when('type', {
    is: 'branch',
    then: Yup.string().required('Must not be empty!'),
  }),
  website: Yup.string().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.string().url().optional(),
  }),
  phone: Yup.object().shape({
    delivery1: Yup.string()
      .matches(
        /^\+[1-9]\d{10,14}$/,
        'Must be a valid phone number in E164 format!',
      )
      .required('Must not be empty!'),
    delivery2: Yup.string()
      .matches(/^\+[1-9]\d{10,14}$/, {
        message: 'Must be a valid phone number in E164 format!',
        excludeEmptyString: true,
      })
      .optional(),
    branch: Yup.string()
      .matches(
        /^\+[1-9]\d{10,14}$/,
        'Must be a valid phone number in E164 format!',
      )
      .required('Must not be empty!'),
  }),
  category1: Yup.string().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.string().required('Must not be empty!'),
  }),
  category2: Yup.string().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.string().when('category1', {
      is: (v) => v,
      then: Yup.string()
        .notOneOf([Yup.ref('category1')], 'Please select a different category!')
        .optional(),
      otherwise: Yup.string().optional(),
    }),
  }),
  category3: Yup.string().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.string()
      .when('category1', {
        is: (v) => v,
        then: Yup.string()
          .notOneOf(
            [Yup.ref('category1')],
            'Please select a different category!',
          )
          .optional(),
        otherwise: Yup.string().optional(),
      })
      .when('category2', {
        is: (v) => v,
        then: Yup.string()
          .notOneOf(
            [Yup.ref('category2')],
            'Please select a different category!',
          )
          .optional(),
        otherwise: Yup.string().optional(),
      }),
  }),
});

const mapDispatchTopProps = {
  getRestaurant: getRestaurantAction,
  updateRestaurant: updateRestaurantAction,
};

/* Export
============================================================================= */
export default withRouter(
  connect(null, mapDispatchTopProps)(EditRestaurantScreen),
);
