/* eslint-disable react/no-array-index-key */
import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Divider,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';

import OrderDetailsDishItem from './OrderDetailsDishItem';

import { getOrder } from '../../../entities/redux/selectors';

/* =============================================================================
<OrderDetailsDishes />
============================================================================= */
const OrderDetailsDishes = ({ order }) => {
  const type = order?.type;
  const paymentMethod = order?.payment?.method;
  const vat = order?.payment?.vat || 0;
  const total = order?.payment?.amount || 0;
  const discount = Math.round(order?.payment?.discount || 0);
  const deliveryFee = order?.payment?.deliveryFee || 0;
  const couponDiscount = Math.round(order?.payment?.couponDiscount || 0);
  const qrCodeDiscount = Math.round(order?.payment?.qrCodeDiscount || 0);
  const posDiscount = order?.payment?.posDiscount || 0;
  const subtotal = total - vat + discount + posDiscount + couponDiscount + qrCodeDiscount - deliveryFee;
  const paidOnline = paymentMethod === 'card' ? total : (order?.payment?.wallet || 0);
  const totalAfterWallet = total - paidOnline;

  return (
    <Box>
      <Divider my={5} />
      <Text fontSize="sm">Order Info</Text>
      {order?.items.map((item, i) => (
        <OrderDetailsDishItem key={i} item={item} />
      ))}
      <VStack alignItems="flex-end">
        <HStack justify="flex-end" minW="200px" mt={2}>
          <Text flex={1} fontWeight="semibold">Sub total:</Text>
          <Text flex={1} fontWeight="semibold">{`Rs.${subtotal}`}</Text>
        </HStack>
        {Boolean(discount) && (
          <HStack justify="flex-end" minW="200px" mt={2}>
            <Text flex={1} fontWeight="semibold">Discount:</Text>
            <Text flex={1} fontWeight="semibold">{`Rs.${discount}`}</Text>
          </HStack>
        )}
        {Boolean(posDiscount) && (
          <HStack justify="flex-end" minW="200px" mt={2}>
            <Text flex={1} fontWeight="semibold">Pos Disc:</Text>
            <Text flex={1} fontWeight="semibold">{`Rs.${posDiscount}`}</Text>
          </HStack>
        )}
        {Boolean(couponDiscount) && (
          <HStack justify="flex-end" minW="200px" mt={2}>
            <Text flex={1} fontWeight="semibold">Promo Discount:</Text>
            <Text flex={1} fontWeight="semibold">{`Rs.${couponDiscount}`}</Text>
          </HStack>
        )}
        {Boolean(qrCodeDiscount) && (
          <HStack justify="flex-end" minW="200px" mt={2}>
            <Text flex={1} fontWeight="semibold">QR Code Discount:</Text>
            <Text flex={1} fontWeight="semibold">{`Rs.${qrCodeDiscount}`}</Text>
          </HStack>
        )}
        {Boolean(vat) && (
          <HStack justify="flex-end" minW="200px" mt={2}>
            <Text flex={1} fontWeight="semibold">GST:</Text>
            <Text flex={1} fontWeight="semibold">{`Rs.${vat}`}</Text>
          </HStack>
        )}
        {type === 'delivery' && (
          <HStack justify="flex-end" minW="200px" mt={2}>
            <Text flex={1} fontWeight="semibold">Delivery Fee:</Text>
            <Text flex={1} fontWeight="semibold">{`Rs.${deliveryFee}`}</Text>
          </HStack>
        )}
        {paidOnline > 0 && (
          <HStack justify="flex-end" minW="200px" mt={2}>
            <Text flex={1} fontWeight="semibold">Paid Online:</Text>
            <Text flex={1} fontWeight="semibold">{`Rs.${paidOnline}`}</Text>
          </HStack>
        )}
        <HStack justify="flex-end" minW="200px" mt={2}>
          <Text flex={1} fontWeight="semibold" fontSize="lg">TOTAL :</Text>
          <Text flex={1} fontWeight="semibold" fontSize="lg">{`Rs.${totalAfterWallet}`}</Text>
        </HStack>
      </VStack>
    </Box>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id, normalize: true }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.type === nextProps.order?.type
  && prevProps.order?.payment?.method === nextProps.order?.payment?.method
  && prevProps.order?.payment?.vat === nextProps.order?.payment?.vat
  && prevProps.order?.payment?.amount === nextProps.order?.payment?.amount
  && prevProps.order?.payment?.wallet === nextProps.order?.payment?.wallet
  && prevProps.order?.payment?.discount === nextProps.order?.payment?.discount
  && prevProps.order?.payment?.couponDiscount === nextProps.order?.payment?.couponDiscount
  && prevProps.order?.payment?.deliveryFee === nextProps.order?.payment?.deliveryFee
  && JSON.stringify(prevProps.order?.items) === JSON.stringify(nextProps.order?.items)
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderDetailsDishes, propsAreEqual));
