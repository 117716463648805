import React from 'react';
import { connect } from 'react-redux';
import {
  // Box,
  Link,
  Text,
  // Input,
  HStack,
  Avatar,
  Button,
  // InputGroup,
  // InputLeftElement,
} from '@chakra-ui/react';
import {
  Bell,
  // Search,
  LogOut,
  HelpCircle,
} from 'react-feather';

import { NavLink } from 'react-router-dom';
// import UserAvatar from '../assets/images/avatar.png';

import { getUser } from '../auth/redux/selectors';
import { logout as logoutAction } from '../auth/redux/actions';

/* =============================================================================
<Header />
============================================================================= */
const Header = ({ user, logout }) => {
  const avatar = user?.profile?.avatar;
  const fullName = user
    ? `${user.profile.firstName} ${user.profile.lastName}`
    : '';

  const _handleLogout = () => {
    logout();
  };

  return (
    // <HStack p={8} justify="space-between">
    <HStack p={8} justify="flex-end">
      {/* <Box>
        <InputGroup>
          <InputLeftElement
            children={<Search />}
          />
          <Input
            flex={1}
            bg="white"
            color="primary"
            fontSize="sm"
            borderRadius="full"
            placeholder="Search"
          />
        </InputGroup>
      </Box> */}
      <HStack spacing={6}>
        <Link as={NavLink} to="/notifications">
          <Bell />
        </Link>
        <Link as={NavLink} to="/help">
          <HelpCircle />
        </Link>
        <HStack as={NavLink} to="/profile" w="auto">
          <Avatar mx={3} src={avatar} />
          <Text fontSize="sm">{fullName}</Text>
        </HStack>
        <Button variant="unstyled" onClick={_handleLogout}>
          <LogOut />
        </Button>
      </HStack>
    </HStack>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const mapDispatchToProps = {
  logout: logoutAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchToProps)(Header);
