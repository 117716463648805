import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDVZxIRqD3kHCE7Dm8tegIq98dsb9Ug9n8',
  authDomain: 'food-street-1632919888124.firebaseapp.com',
  databaseURL: 'https://food-street-1632919888124-default-rtdb.firebaseio.com',
  projectId: 'food-street-1632919888124',
  storageBucket: 'food-street-1632919888124.appspot.com',
  messagingSenderId: '528345359591',
  appId: '1:528345359591:web:c64ff138b9791fd996ae42',
  measurementId: 'G-N8D8YTVYE7',
};

export const firebaseApp = initializeApp(firebaseConfig);
export const storage = getStorage(firebaseApp);
