import React from 'react';
import { Heading } from '@chakra-ui/react';

import Card from '../../common/Card';
import NotificationForm from '../components/NotificationForm';

/* =============================================================================
<CreateNotificationScreen />
============================================================================= */
const CreateNotificationScreen = () => (
  <Card flex={1} overflow="auto">
    <Heading fontSize="4xl" fontWeight="semibold">Send Notification</Heading>
    <NotificationForm />
  </Card>
);

/* Export
============================================================================= */
export default CreateNotificationScreen;
