import React, { useEffect, useState, useMemo } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Box, Input } from '@chakra-ui/react';

import { MapView, MapMarker, FormInput } from '../../../common';
import { GOOGLE_API_KEY } from '../../../config/secrets';

/* =============================================================================
<RestaurantFormLocation />
============================================================================= */
const RestaurantFormLocation = ({
  errors,
  watch,
  setValue,
}) => {
  const [zoom, setZoom] = useState(3); // initial zoom
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [coordinates, setCoordinates] = useState('');
  const location = watch('location');

  const value = useMemo(() => (location?.length ? {
    lat: location[1],
    lng: location[0],
  } : null), [location]);

  useEffect(() => {
    if (value) {
      const valueStr = value && `${value.lat}, ${value.lng}`;
      if (valueStr !== coordinates) {
        setCoordinates(valueStr);
      }
      if (center.lat === 0 && center.lng === 0) {
        setCenter(value);
      }
    }
    if (!value && coordinates) {
      setCoordinates('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const [lat, lng] = coordinates.split(',');
    if (lat && lng) {
      const valueStr = value && `${value.lat}, ${value.lng}`;
      if (valueStr !== coordinates) {
        setValue(NAME, [+lng, +lat]);
        setCenter({
          lat: +lat,
          lng: +lng,
        });
        setZoom(15);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates]);

  const _handleClick = (event) => {
    setValue(NAME, [event.latLng.lng(), event.latLng.lat()]);
    setCoordinates(`${event.latLng.lat()}, ${event.latLng.lng()}`);
  };

  const _handleIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter()?.toJSON());
  };

  return (
    <FormInput
      flex={1}
      name={NAME}
      label="Google Map Drop"
      error={errors[NAME]}
    >
      <Input
        mb={4}
        type="text"
        value={coordinates}
        placeholder="Enter coordinates"
        onChange={(e) => setCoordinates(e.target.value)}
      />
      <Box height="230px" borderRadius="md" overflow="hidden" mb={6}>
        <Wrapper apiKey={GOOGLE_API_KEY} render={render}>
          <MapView
            zoom={zoom}
            center={center}
            onClick={_handleClick}
            onIdle={_handleIdle}
          >
            {value && <MapMarker position={value} />}
          </MapView>
        </Wrapper>
      </Box>
    </FormInput>
  );
};

const render = (status) => <div>{status}</div>;

const NAME = 'location';

/* Export
============================================================================= */
export default RestaurantFormLocation;
