import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {Tr, Td, Button, useToast, Flex} from '@chakra-ui/react';

import { getCoupon } from '../../../entities/redux/selectors';
import { deleteCoupon as deleteCouponAction } from '../../redux/actions';

/* =============================================================================
<CouponsTableItem />
============================================================================= */
const CouponsTableItem = ({id, coupon, deleteCoupon}) => {
  const toast = useToast();
  const [deleting, setDeleting] = useState(false);

  const name = coupon?.name;
  const amount = coupon?.amount;
  const isFixed = coupon?.isFixed;
  const uses = coupon?.uses || '--';
  const maxUses = coupon?.maxUses || '--';
  const startsAt = coupon?.startsAt ? moment(coupon?.startsAt).format('MMMM Do YYYY, h:mm:ss a') : '--';
  const expiresAt = coupon?.expiresAt ? moment(coupon?.expiresAt).format('MMMM Do YYYY, h:mm:ss a') : '--';

  const _handleDeleteClick = async () => {
    setDeleting(true);
    await deleteCoupon(id, (err) => {
      if (err) {
        toast({
          title: 'Error',
          status: 'error',
          duration: 3000,
          isClosable: true,
          description: err.message,
        });
      } else {
        toast({
          title: 'Coupon deleted successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    });
    setDeleting(false);
  };

  return (
    <Tr>
      <Td>{name}</Td>
      <Td>{isFixed ? `Rs.${amount}` : `${amount}%`}</Td>
      <Td>{uses}</Td>
      <Td>{maxUses}</Td>
      <Td>{startsAt}</Td>
      <Td>{expiresAt}</Td>
      <Td>
        <Flex>
          <Button
            as={Link}
            to={`/coupons/${id}`}
            size="sm"
            colorScheme="green"
          >
            Edit
          </Button>
          <Button
            ml={2}
            size="sm"
            isLoading={deleting}
            colorScheme="red"
            onClick={_handleDeleteClick}
          >
            Delete
          </Button>
        </Flex>
      </Td>
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  coupon: getCoupon(state, { id }),
});

const mapDispatchToProps = {
  deleteCoupon: deleteCouponAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) =>
  prevProps.id === nextProps.id
  && prevProps.coupon?.name === nextProps.coupon?.name
  && prevProps.coupon?.amount === nextProps.coupon?.amount
  && prevProps.coupon?.isFixed === nextProps.coupon?.isFixed
  && prevProps.coupon?.uses === nextProps.coupon?.uses
  && prevProps.coupon?.maxUses === nextProps.coupon?.maxUses
  && prevProps.coupon?.startsAt === nextProps.coupon?.startsAt
  && prevProps.coupon?.expiresAt === nextProps.coupon?.expiresAt;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(CouponsTableItem, propsAreEqual));
