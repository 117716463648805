import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { List } from '@chakra-ui/react';

import StreetListFooter from '../../StreetListFooter';
import StreetSpecialBannerRestaurantsListItem from './StreetSpecialBannerRestaurantsListItem';
import { reorder } from '../../../../util/functions';

/* =============================================================================
<StreetSpecialBannerRestaurants />
============================================================================= */
const StreetSpecialBannerRestaurants = ({ restaurants, onUpdateRestaurants }) => {
  const [items, setItems] = useState([]);
  const [saving, setSaving] = useState(false);

  // Set restaurants
  useEffect(() => {
    setItems(restaurants);
  }, [restaurants]);

  const _updateRestaurants = async (reordered) => {
    setSaving(true);
    await onUpdateRestaurants(reordered);
    setSaving(false);
  };

  const _handleDragEnd = async (result) => {
    // Dropped outside the restaurants list
    if (result?.destination?.droppableId !== 'special-banner-restaurants') {
      return;
    }

    // Dropped at the same index
    if (result.source.index === result.destination.index) {
      return;
    }

    const reordered = reorder(
      items,
      result.source.index,
      result.destination.index,
    );
    setItems(reordered);
    _updateRestaurants(reordered);
  };

  const _handleDeleteClick = async (id) => {
    await onUpdateRestaurants(items.filter((item) => item !== id));
  };

  return (
    <>
      <DragDropContext onDragEnd={_handleDragEnd}>
        <Droppable droppableId="special-banner-restaurants">
          {(provided, snapshot) => (
            <List
              ref={provided.innerRef}
              {...provided.droppableProps}
              flex={1}
              mx={-5}
              mb={-4}
              px={5}
              py={4}
              overflowY="hidden"
              bg={snapshot.isDraggingOver ? 'blue.50' : 'inherit'}
            >
              {items.map((item, index) => (
                <StreetSpecialBannerRestaurantsListItem
                  id={item}
                  key={item}
                  index={index}
                  onDeleteClick={_handleDeleteClick}
                />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      <StreetListFooter saving={saving} />
    </>
  );
};

/* Export
============================================================================= */
export default StreetSpecialBannerRestaurants;
