import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { List } from '@chakra-ui/react';

import { Card } from '../../../common';
import DiscountDishesListItem from './DiscountDishesListItem';

import { getDiscount as selectDiscount } from '../../../entities/redux/selectors';
import { getDiscount as getDiscountAction } from '../../redux/actions';

/* =============================================================================
<DiscountDishesList />
============================================================================= */
const DiscountDishesList = ({ id, discount, getDiscount }) => {
  const dishes = discount?.dishes;

  // Get Deal
  useEffect(() => {
    if (id) {
      getDiscount(id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Card layerStyle="street.card" flex={4}>
      <List layerStyle="street.list">
        {dishes?.map((item) => (
          <DiscountDishesListItem
            id={item}
            key={item}
            discountId={id}
          />
        ))}
      </List>
    </Card>
  );
};

const mapStateToProps = (state, { id }) => ({
  discount: selectDiscount(state, { id, normalized: true }),
});

const mapDispatchToProps = {
  getDiscount: getDiscountAction,
};

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.id === nextProps.id
  && prevProps.discount?.dishes?.toString() === nextProps.discount?.dishes?.toString()
);

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(DiscountDishesList, propsAreEqual));
