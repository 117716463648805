import { actionGenerator } from '../../util/reduxHelpers';

export const USER_GET = actionGenerator('AUTH/USER_GET');
export const LOGIN = actionGenerator('AUTH/LOGIN');
export const PASSWORD_RESET_CODE_SEND = actionGenerator(
  'AUTH/PASSWORD_RESET_CODE_SEND',
);
export const PASSWORD_RESET_CODE_VERIFICATION = actionGenerator(
  'AUTH/PASSWORD_RESET_CODE_VERIFICATION',
);
export const PASSWORD_RESET = actionGenerator('AUTH/PASSWORD_RESET');
export const LOGOUT = actionGenerator('AUTH/LOGOUT');
export const ERROR_SET = 'ERROR_SET';
