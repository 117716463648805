import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  List,
  Text,
  Divider,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

import { Card } from '../../../common';
import DiscountsListItem from './DiscountsListItem';
import DiscountCreateModal from '../DiscountModals/DiscountCreateModal/index';

import { getDiscounts as selectDiscounts } from '../../redux/selectors';
import { getDiscounts as getDiscountsAction } from '../../redux/actions';

/* =============================================================================
<DiscountsList />
============================================================================= */
const DiscountsList = ({
  selected,
  discounts,
  onItemSelect,
  getDiscounts,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  // Get discounts
  useEffect(() => {
    getDiscounts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card flex={1} layerStyle="street.card" alignItems="center">
      <Text textStyle="street.title">Discounts</Text>
      <Divider mt={4} />
      <List layerStyle="street.list">
        {discounts?.map((item) => (
          <DiscountsListItem
            id={item}
            key={item}
            selected={selected === item}
            onClick={onItemSelect}
          />
        ))}
      </List>
      <Button
        mb={5}
        size="sm"
        colorScheme="purple"
        onClick={onOpen}
      >
        Create
      </Button>
      <DiscountCreateModal isOpen={isOpen} onClose={onClose} />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  discounts: selectDiscounts(state),
});

const mapDispatchTopProps = {
  getDiscounts: getDiscountsAction,
};

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.selected === nextProps.selected
  && prevProps.onItemSelect === nextProps.onItemSelect
  && prevProps.discounts?.toString() === nextProps.discounts?.toString()
);

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchTopProps,
)(React.memo(DiscountsList, propsAreEqual));
