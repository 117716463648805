import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { normalize } from 'normalizr';
import { yupResolver } from '@hookform/resolvers/yup';
import { AsyncSelect } from 'chakra-react-select';
import { Button, Input, Stack } from '@chakra-ui/react';

import { FormInput, FormSelect } from '../../../common';
import CouponFormInitialValues from './CouponFormInitialValues';
import request from '../../../util/request';
import { restaurant as restaurantSchema } from '../../../entities/api/schema';

import { addEntities as addEntitiesAction } from '../../../entities/redux/actions';

/* =============================================================================
<CouponForm />
============================================================================= */
const CouponForm = ({ id, onSubmit, addEntities }) => {
  const methods = useForm({ resolver: yupResolver(CouponSchema) });
  const {
    reset,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  const restaurantName = watch('restaurantName');

  const _handleLoadRestaurants = async (value) => {
    try {
      const payload = await request({
        url: `/restaurants/find?q=${value}`,
        method: 'GET',
      });

      const { entities } = normalize(payload.data, [restaurantSchema]);
      addEntities(entities);

      return payload.data.map((_restaurant) => ({
        label: _restaurant.name,
        value: _restaurant.id,
      }));
    } catch (e) {
      return [];
    }
  };

  const _handleRestaurantsChange = ({ value }) => {
    setValue('restaurant', value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit({ reset }))}>
      <FormInput
        mb={6}
        name="type"
        label="Type"
        error={errors.type}
        display="none"
        inputProps={{defaultValue: 'cart'}}
        register={register}
      />
      <Stack mt={4} direction={['column', 'column', 'row']} spacing={4} align="start">
        <FormInput
          mb={6}
          name="name"
          label="Name"
          error={errors.name}
          register={register}
        />
        <FormInput
          mb={6}
          name="about"
          label="Description"
          error={errors.about}
          register={register}
        />
      </Stack>
      <Stack direction={['column', 'column', 'row']} spacing={4} align="start">
        <FormSelect
          mb={6}
          name="isFixed"
          label="Discount Type"
          error={errors.isFixed}
          options={DISCOUNT_TYPES}
          register={register}
        />
        <FormInput
          mb={6}
          name="amount"
          label="Discount Amount"
          error={errors.amount}
          register={register}
        />
      </Stack>
      <Stack direction={['column', 'column', 'row']} spacing={4} align="start">
        <FormInput mb={6} name="restaurant" label="Restaurant" error={errors.restaurant}>
          {id ? (
            <Input value={restaurantName} disabled />
          ) : (
            <AsyncSelect
              id="restaurants"
              cacheOptions
              loadOptions={_handleLoadRestaurants}
              onChange={_handleRestaurantsChange}
            />
          )}
        </FormInput>
        <FormInput
          mb={6}
          name="minAmount"
          label="Minimum Order Amount"
          error={errors.minAmount}
          register={register}
        />
      </Stack>
      <Stack direction={['column', 'column', 'row']} spacing={4} align="start">
        <FormInput
          mb={6}
          name="maxUses"
          label="Max Uses"
          error={errors.maxUses}
          register={register}
        />
        <FormInput
          mb={6}
          name="maxUsesUser"
          label="Max Uses Per User"
          error={errors.maxUsesUser}
          register={register}
        />
      </Stack>
      <Stack direction={['column', 'column', 'row']} spacing={4} align="start">
        <FormInput
          mb={6}
          name="startsAt"
          label="Starts At"
          error={errors.startsAt}
          inputProps={{type: 'date'}}
          register={register}
        />
        <FormInput
          mb={6}
          name="expiresAt"
          label="Expires At"
          error={errors.expiresAt}
          inputProps={{type: 'date'}}
          register={register}
        />
      </Stack>
      <Button
        type="submit"
        float="right"
        colorScheme="purple"
        isLoading={isSubmitting}
      >
        Save
      </Button>
      <CouponFormInitialValues id={id} setValue={setValue} />
    </form>
  );
};

const DISCOUNT_TYPES = [
  {
    label: 'Fixed',
    value: true,
  },
  {
    label: 'Percentage',
    value: false,
  }
];

const CouponSchema = Yup.object().shape({
  type: Yup.string()
    .required('Must not be empty'),
  name: Yup.string()
    .required('Must not be empty'),
  about: Yup.string()
    .optional(),
  amount: Yup.number()
    .required('Must not be empty'),
  minAmount: Yup.number()
    .nullable(true)
    .transform((_, val) => val ? Number(val) : null),
  isFixed: Yup.boolean()
    .required('Must not be empty'),
  maxUses: Yup.number()
    .nullable(true)
    .transform((_, val) => val ? Number(val) : null),
  maxUsesUser: Yup.number()
    .nullable(true)
    .transform((_, val) => val ? Number(val) : null),
  startsAt: Yup.date()
    .nullable(true)
    .transform((_, val) => val ? new Date(val) : null),
  expiresAt: Yup.date()
    .nullable(true)
    .transform((_, val) => val ? new Date(val) : null),
  restaurant: Yup.string()
    .nullable(true),
});

const mapDispatchToProps = {
  addEntities: addEntitiesAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(CouponForm);
