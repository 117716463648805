import React from 'react';
import { Box, Table, Thead, Tbody, Tr } from '@chakra-ui/react';

import RestaurantsTableItem from './RestaurantsTableItem';
import RestaurantsTableHeadItem from './RestaurantsTableHeadItem';

/* =============================================================================
<RestaurantsTable />
============================================================================= */
const RestaurantsTable = ({ data }) => (
  <Box flex={1} overflow="auto">
    <Table variant="simple" colorScheme="gray" size="lg">
      <Thead>
        <Tr>{columns.map(renderColumn)}</Tr>
      </Thead>
      <Tbody>{data.map(renderItem)}</Tbody>
    </Table>
  </Box>
);

const renderColumn = (item, i) => (
  <RestaurantsTableHeadItem key={`${i}`} title={item} />
);
const renderItem = (item) => <RestaurantsTableItem key={item} id={item} />;

const columns = [
  'NAME',
  'Category',
  'Status',
  'Live',
  'Delivery',
  'Dine-in',
  'Takeaway',
  '',
];

/* Export
============================================================================= */
export default RestaurantsTable;
