import * as constants from './constants';

export const INITIAL_STATE = {
  type: 'all',
  days: 1,
  query: '',
  orders: [],
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // TYPE_SET
    case constants.TYPE_SET:
      return {
        ...state,
        type: payload,
      };

    // QUERY_SET
    case constants.QUERY_SET:
      return {
        ...state,
        query: payload,
      };

    // DAYS_FILTER_SET
    case constants.DAYS_FILTER_SET:
      return {
        ...state,
        days: payload,
      };

    // ORDER_ADD
    case constants.ORDER_ADD:
      return {
        ...state,
        orders: [...new Set([payload, ...state.orders])],
      };

    // ORDERS_GET
    case constants.ORDERS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.ORDERS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.ORDERS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        orders: payload,
      };
    case constants.ORDERS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // ORDER_GET
    case constants.ORDER_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.ORDER_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.ORDER_GET.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.ORDER_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
