import React from 'react';
import { Switch, Route } from 'react-router-dom';

import RestaurantsScreen from './RestaurantsScreen';
import AddRestaurantScreen from './AddRestaurantScreen';
import EditRestaurantScreen from './EditRestaurantScreen';

/* =============================================================================
<RestaurantsScreens />
============================================================================= */
const RestaurantsScreens = () => (
  <Switch>
    <Route path="/restaurants" exact>
      <RestaurantsScreen />
    </Route>
    <Route path="/restaurants/new" exact>
      <AddRestaurantScreen />
    </Route>
    <Route path="/restaurants/:id" exact>
      <EditRestaurantScreen />
    </Route>
  </Switch>
);

/* Export
============================================================================= */
export default RestaurantsScreens;
