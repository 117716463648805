import React from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import {
  Modal,
  useToast,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import DiscountTitleAndType from '../DiscountTitleAndType';
import DiscountValueAndDate from '../DiscountValueAndDate';
import DiscountSubmitBtn from '../DiscountSubmitBtn';

import { createDiscount as createDiscountAction } from '../../../redux/actions';

/* =============================================================================
<DiscountCreateModal />
============================================================================= */
const DiscountCreateModal = ({ isOpen, onClose, createDiscount }) => {
  const toast = useToast();

  const _handleSubmit = async (values) => {
    await createDiscount(values, (err) => {
      if (err) {
        toast({
          title: 'Error',
          duration: 3000,
          status: 'error',
          isClosable: true,
          description: err.message,
        });
      } else {
        toast({
          status: 'success',
          duration: 3000,
          isClosable: true,
          title: 'Discount Added Successfully',
        });
        onClose();
      }
    });
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="20px">
        <ModalHeader fontSize="xl" textAlign="center">Create Discount</ModalHeader>
        <ModalBody p={8}>
          <Formik
            initialValues={initialValues}
            validationSchema={DiscountCreateSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <DiscountTitleAndType />
                <DiscountValueAndDate />
                <DiscountSubmitBtn title="Create" loading={isSubmitting} />
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const initialValues = {
  name: '',
  type: '',
  value: '',
  startAt: '',
  endAt: '',
};

const DiscountCreateSchema = Yup.object().shape({
  name: Yup.string()
    .required('Must not be empty'),
  type: Yup.string()
    .required('Must choose one'),
  value: Yup.number()
    .required('Must not be empty'),
  startAt: Yup.string()
    .required('Must not be empty'),
  endAt: Yup.string()
    .required('Must not be empty'),
});

const mapDispatchToProps = {
  createDiscount: createDiscountAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(DiscountCreateModal);
