import moment from "moment";

export const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;

export const calculateDistanceInTime = () => {
  const calculatedTime = 0;
  return calculatedTime;
};

export const calculateDistanceInMiles = () => {
  const calculatedMiles = 0;
  return calculatedMiles;
};

export const isRestaurantOpen = () => {
  const isOpen = false;
  return isOpen;
};

export const calculateRating = (ratings) => {
  const result = {
    total: 0,
    average: 0,
  };
  if (ratings) {
    result.total = Object.values(ratings).reduce((a, b) => a + b, 0);
    result.average = Object.entries(ratings)
      .map((rating) => rating[1] / rating[0])
      .reduce((a, b) => a + b, 0);
  }
  return result;
};

export const calculateRestaurantStatus = (hours) => {
  let result = 'closed';
  if (Array.isArray(hours) && hours.length === 7) {
    const date = new Date();
    const day = hours[date.getDay()];
    if (!day.closed) {
      const hour = (date.getHours() * 60) + date.getMinutes();
      const openHour = day.hours.find((h) => hour >= h.open && hour <= h.close);
      if (openHour) {
        result = 'open';
      }
    }
  }
  return result;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const calculateDiscount = (originalPrice, discountType, discount) => {
  const result = {
    originalPrice,
    discountPrice: 0,
  };

  if (discountType === 'percentage') {
    const percentageInDecimal = discount / 100;
    const saving = percentageInDecimal * originalPrice;
    result.discountPrice = originalPrice - saving;
  }

  if (discountType === 'amount') {
    result.discountPrice = originalPrice - discount;
  }

  return result;
};

/**
 * Calculate diff between 2 dates
 */
export const calculateTimeDiff = (start, end) => {
  let str = '';
  const m1 = moment(start);
  const m2 = moment(end);
  const duration = moment.duration(m2.diff(m1));
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (hours > 0) {
    str += `${hours} ${hours > 1 ? 'hours' : 'hour'} `;
  }
  if (minutes > 0) {
    str += `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} `;
  }
  if (seconds > 0) {
    str += `${seconds} ${seconds > 1 ? 'seconds' : 'second'}`;
  }

  return str;
};
