import React from 'react';
import { connect } from 'react-redux';
import { Heading, useToast } from '@chakra-ui/react';

import {Card} from '../../common';
import QRCodeForm from '../components/QRCodeForm';

import {createQRCode as createQRCodeAction} from '../redux/actions';

/* =============================================================================
<CreateQRCodeScreen />
============================================================================= */
const CreateQRCodeScreen = ({createQRCode}) => {
  const toast = useToast();

  const _handleSubmit = (form) => async (values) => {
    await createQRCode({
      ...values,
      restaurant: values.branch || values.restaurant,
    }, (err) => {
      if (err) {
        toast({
          title: 'Error',
          status: 'error',
          duration: 3000,
          isClosable: true,
          description: err.message,
        });
      } else {
        form.reset();
        toast({
          title: 'QRCode created successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Card flex={1} overflow="auto">
      <Heading fontSize="4xl" fontWeight="semibold">Create QRCode</Heading>
      <QRCodeForm onSubmit={_handleSubmit} />
    </Card>
  );
};

const mapDispatchToProps = {
  createQRCode: createQRCodeAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(CreateQRCodeScreen);
