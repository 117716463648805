import { createSelector } from 'reselect';

/**
 * Get type
 */
export const getType = (state) => state.Posts.type;

/**
 * Get error
 */
export const getError = (state) => state.Posts.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.Posts.loading;

/**
 * Get posts entities
 */
export const getPostEntities = (state) => state.Entities.posts;

/**
 * Get posts
 */
export const getPosts = (state) => state.Posts.posts;

/**
 * Get next cursor
 */
export const getNextCursor = (state) => state.Posts.nextCursor;

/**
  * Get post by type
  */
export const getPostByType = createSelector(
  [getPosts, getType, getPostEntities],
  (allIds, type, byIds) => {
    switch (type) {
      case 'all':
        return allIds;
      case 'published':
        return allIds.filter((id) => byIds[id]?.published);
      case 'Unpublished':
        return allIds.filter((id) => !byIds[id]?.published);
      default:
        break;
    }
    return [];
  },
);
