import React from 'react';
import ReactCodeInput from 'react-code-input';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import { Link as ReactLink, useHistory, useLocation } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import {
  Box,
  Text,
  Link,
  Center,
  Button,
  Heading,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';

import { ReactComponent as MailIcon } from '../../assets/icons/edit-email-icon.svg';

import { getLoading } from '../redux/selectors';
import {
  sendPasswordResetCode as sendPasswordResetCodeAction,
  verifyPasswordResetCode as verifyPasswordResetCodeAction,
} from '../redux/actions';

/* =============================================================================
<CodeVerificationScreen />
============================================================================= */
const CodeVerificationScreen = ({
  sendPasswordResetCode,
  verifyPasswordResetCode,
}) => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const email = query.get('email');

  const _handleResendPress = async () => {
    await sendPasswordResetCode(email);
  };

  const _handleSubmit = async (values) => {
    await verifyPasswordResetCode(email, values.code, (err, payload) => {
      if (!err) {
        history.push(`/auth/reset_password?token=${payload.token}`);
      }
    });
  };

  return (
    <Formik
      initialValues={{ code: '' }}
      validationSchema={CodeVerificationSchema}
      onSubmit={_handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box align="center">
            <MailIcon />
            <Heading mt={6} mb={3} fontSize="3xl">
              Check your email
            </Heading>
            <Text mb={8}>
              We sent a verification link to
              <br />
              <Text fontWeight="bold">
                {email}
              </Text>
            </Text>
            <Field name="code">
              {({ form }) => (
                <FormControl isInvalid={form.errors.code && form.touched.code}>
                  <ReactCodeInput
                    fields={4}
                    name="code"
                    type="number"
                    inputStyle={codeInputStyle}
                    onChange={form.handleChange('code')}
                  />
                  <FormErrorMessage>{form.errors.code}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button
              my={8}
              size="xl"
              type="submit"
              colorScheme="blue"
              isLoading={isSubmitting}
            >
              Verify email
            </Button>
            <Text>
              Didnt receive the email?
              {' '}
              <Button
                variant="link"
                onClick={_handleResendPress}
              >
                Click to resend
              </Button>
            </Text>
            <Center mt={6}>
              <Link
                as={ReactLink}
                to="/auth/login"
                fontSize="sm"
                display="flex"
                alignItems="center"
              >
                <ArrowLeft size={14} style={{ marginRight: 8 }} />
                Back to login
              </Link>
            </Center>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const codeInputStyle = {
  width: '80px',
  height: '80px',
  margin: '0.375rem',
  color: '#3082ce',
  fontSize: '3rem',
  textAlign: 'center',
  borderRadius: '0.5rem',
  border: '1px solid #3082ce',
};

const CodeVerificationSchema = Yup.object().shape({
  code: Yup.string()
    .min(4, 'Must be at least 4 digits!')
    .max(4, 'Must not exceed 4 digits!')
    .required('Must not be empty!'),
});

const mapStateToProps = (state) => ({
  loading: getLoading(state),
});

const mapDispatchToProps = {
  sendPasswordResetCode: sendPasswordResetCodeAction,
  verifyPasswordResetCode: verifyPasswordResetCodeAction,
};

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CodeVerificationScreen);
