import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { getUser } from '../auth/redux/selectors';

const AuthenticatedRoute = ({
  user,
  authenticated,
  allowedRoles,
  children,
  ...rest
}) => (
  <Route
    {...rest}
    render={({ location }) =>
      authenticated ? (
        allowedRoles?.includes(user.role.app) ? (
          children
        ) : null
      ) : (
        <Redirect
          to={{
            pathname: '/auth/login',
            state: { from: location },
          }}
        />
      )
    }
  />
);

const mapStateToProps = (state) => ({
  user: getUser(state),
  authenticated: !!getUser(state),
});

export default connect(mapStateToProps, null)(AuthenticatedRoute);
