import React from 'react';
import { connect } from 'react-redux';
import { Text, Tag, Flex, Box, HStack } from '@chakra-ui/react';

import { getOrder } from '../../../entities/redux/selectors';

/* =============================================================================
<LiveOrdersCardId />
============================================================================= */
const LiveOrdersCardId = ({ order }) => {
  const orderTag = order?.tag;
  const orderType = ORDER_TYPES[order?.type];
  const restaurantName =
    order?.restaurant?.name || order?.restaurant?.chain?.name;

  return (
    <>
      <Flex mb={2} align="center" justify="space-between">
        <HStack>
          <Tag variant="subtle" colorScheme="red">
            {orderType}
          </Tag>
          {order?.callOrder && <Tag colorScheme="blue">Call</Tag>}
        </HStack>
        <Box mr={-5}>
          {Boolean(restaurantName) && (
            <Tag
              bg="brand.800"
              variant="subtle"
              color="white"
              borderRightRadius={0}
            >
              {restaurantName}
            </Tag>
          )}
        </Box>
      </Flex>
      <Text fontSize="sm" color="brand.700" mb={2}>{`#${orderTag}`}</Text>
    </>
  );
};

const ORDER_TYPES = {
  gift: 'Gift',
  dine_in: 'Dine In',
  delivery: 'Delivery',
  take_away: 'Takeaway',
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) =>
  prevProps.order?.tag === nextProps.order?.tag &&
  prevProps.order?.type === nextProps.order?.type &&
  prevProps.order?.restaurant?.type === nextProps.order?.restaurant?.type &&
  prevProps.order?.restaurant?.name === nextProps.order?.restaurant?.name &&
  prevProps.order?.restaurant?.chain?.name ===
    nextProps.order?.restaurant?.chain?.name;

/* Export
============================================================================= */
export default connect(mapStateToProps)(
  React.memo(LiveOrdersCardId, propsAreEqual),
);
