import React, { useState } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import {
  Modal,
  HStack,
  Button,
  Center,
  useToast,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import DealName from '../DealName';
import DealType from '../DealType';
import DealAspectRatio from '../DealAspectRatio';
import DealView from '../DealView';
import DealEditModalInitialize from './DealEditModalInitialize';

import { updateDeal as updateDealAction } from '../../../redux/actions';

/* =============================================================================
<DealEditModal />
============================================================================= */
const DealEditModal = ({
  id,
  isOpen,
  onClose,
  updateDeal,
}) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const _handleSubmit = async (values) => {
    setLoading(true);
    await updateDeal({ id, ...values }, (err) => {
      if (err) {
        toast({
          title: 'Error',
          duration: 3000,
          status: 'error',
          isClosable: true,
          description: err.message,
        });
      } else {
        toast({
          status: 'success',
          duration: 3000,
          isClosable: true,
          title: 'Deal Edited Successfully',
        });
        onClose();
      }
    });
    setLoading(false);
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="20px">
        <ModalHeader fontSize="xl" textAlign="center">Edit Deals And Discount</ModalHeader>
        <ModalBody p={8}>
          <Formik
            initialValues={initialValues}
            validationSchema={DealEditSchema}
            onSubmit={_handleSubmit}
          >
            {() => (
              <Form>
                <HStack spacing={8} alignItems="flex-start">
                  <DealName />
                  <DealView />
                </HStack>
                <HStack mt={4} spacing={8} alignItems="flex-start">
                  <DealType />
                  <DealAspectRatio />
                </HStack>
                <DealEditModalInitialize id={id} />
                <Center>
                  <Button
                    type="submit"
                    size="lg"
                    colorScheme="purple"
                    isLoading={loading}
                  >
                    Edit
                  </Button>
                </Center>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const initialValues = {
  name: '',
  type: '',
  view: 'hs',
  aspectRatio: '',
};

const DealEditSchema = Yup.object().shape({
  name: Yup.string()
    .required('Must not be empty'),
  type: Yup.string()
    .required('Must choose one'),
  view: Yup.string()
    .required('Must not be empty'),
  aspectRatio: Yup.number()
    .required('Must not be empty'),
});

const mapDispatchToProps = {
  updateDeal: updateDealAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(DealEditModal);
