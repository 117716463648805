import React, { useState } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import {
  Modal,
  Button,
  HStack,
  Center,
  useToast,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Box,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import DealItemCreateName from '../DealItemCreateName';
import DealItemCreatePrice from '../DealItemCreatePrice';
import DealItemCreateTime from '../DealItemCreateTime';
import DealItemCreateAbout from '../DealItemCreateAbout';
import DealItemCreateRestaurant from '../DealItemCreateRestaurant';
import DealItemCreatePhotoPreview from '../DealItemCreatePhotoPreview';
import { uploadDealBanner } from '../../../../util/cloudinary';

import { getDeal } from '../../../../entities/redux/selectors';
import { createDealItem as createDealItemAction } from '../../../redux/actions';

/* =============================================================================
<DealCartItemCreateModal />
============================================================================= */
const DealCartItemCreateModal = ({
  id,
  deal,
  isOpen,
  onClose,
  createDealItem,
}) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const name = deal?.name;

  const _handleSubmit = async (values) => {
    setLoading(true);
    const payload = { ...values };
    if (values.photo) {
      const photoURL = await uploadDealBanner(values.photo);
      payload.photo = photoURL;
    }

    await createDealItem(payload, id, (err) => {
      if (err) {
        toast({
          title: 'Error',
          duration: 3000,
          status: 'error',
          isClosable: true,
          description: err.message,
        });
      } else {
        toast({
          status: 'success',
          duration: 3000,
          isClosable: true,
          title: 'Deal Item Added Successfully',
        });
        onClose();
      }
    });
    setLoading(false);
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="20px">
        <ModalHeader fontSize="xl" textAlign="center">{name}</ModalHeader>
        <ModalBody p={8}>
          <Formik
            initialValues={initialValues}
            validationSchema={DealItemCreateSchema}
            onSubmit={_handleSubmit}
          >
            {() => (
              <Form>
                <HStack mb={2} align="flex-start">
                  <Box flex={1}>
                    <DealItemCreateRestaurant />
                    <DealItemCreateName />
                  </Box>
                  <DealItemCreatePhotoPreview />
                </HStack>
                <HStack>
                  <DealItemCreatePrice />
                  <DealItemCreateTime />
                </HStack>
                <DealItemCreateAbout />
                <Center mt={8}>
                  <Button
                    size="lg"
                    type="submit"
                    colorScheme="purple"
                    isLoading={loading}
                  >
                    ADD DEAL
                  </Button>
                </Center>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const initialValues = {
  name: '',
  price: '',
  about: '',
  startAt: '',
  endAt: '',
  photo: '',
  restaurant: '',
};

const DealItemCreateSchema = Yup.object().shape({
  name: Yup.string()
    .required('Must not be empty'),
  about: Yup.string()
    .required('Must not be empty'),
  photo: Yup.string()
    .required('Must not be empty'),
  price: Yup.number()
    .required('Must not be empty'),
  restaurant: Yup.string()
    .required('Must not be empty'),
});

const mapStateToProps = (state, { id }) => ({
  deal: getDeal(state, { id }),
});

const mapDispatchToProps = {
  createDealItem: createDealItemAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchToProps)(DealCartItemCreateModal);
