import { createSelector } from 'reselect';
import { getCategory as getCategoryEntity } from '../../entities/redux/selectors';
import { getCategories as getCategoriesIds } from '../../street/redux/selectors';

/**
 * Get error
 */
export const getError = (state) => state.Restaurants.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.Restaurants.loading;

/**
 * Get restaurants
 */
export const getRestaurants = (state) => state.Restaurants.restaurants;

/**
 * Get dishes by restaurant
 */
export const getDishesByRestaurant = (state) => state.Restaurants.dishesByRestaurant;

/**
 * Get restaurants Entity
 */
export const getRestaurantsEntity = (state) => state.Entities.restaurants;

/**
 * Get dishes Entity
 */
export const getDishesEntity = (state) => state.Entities.dishes;

/**
 * Get next cursor
 */
export const getNextCursor = (state) => state.Restaurants.nextCursor;

/**
 * Get id
 */
export const getId = (state, { id }) => id;

/**
 * Get categories
 */
export const getCategories = (state) => getCategoriesIds(state)
  .map((id) => getCategoryEntity(state, { id }));

/**
 * Get restaurants by id
 */
export const getRestaurantsById = createSelector(
  [getRestaurants, getRestaurantsEntity],
  (allIds, byIds) => allIds.map((id) => byIds[id]),
);

/**
 * Get dishes by restaurant id
 */
export const getDishesByRestaurantId = createSelector(
  [getDishesByRestaurant, getDishesEntity, getId],
  (allIds, byIds, id) => allIds[id]?.map((dishId) => byIds[dishId]),
);
