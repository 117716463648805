import React from 'react';
import { Th } from '@chakra-ui/react';

/* =============================================================================
<QRCodesTableHeadItem />
============================================================================= */
const QRCodesTableHeadItem = ({ title }) => (
  <Th bg="gray.100">
    {title}
  </Th>
);

/* Export
============================================================================= */
export default QRCodesTableHeadItem;
