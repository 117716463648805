import React, { useEffect } from 'react';
import { FastField } from 'formik';
import {
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react';
import { connect } from 'react-redux';

import { getRestaurantDishes as getRestaurantDishesAction } from '../../../restaurants/redux/actions';
import { getDishesByRestaurantId } from '../../../restaurants/redux/selectors';

/* =============================================================================
<DealItemCreateDish />
============================================================================= */
const DealItemCreateDish = ({
  dishes,
  restaurant,
  getRestaurantDishes,
}) => {
  // Get Restaurant Dishes
  useEffect(() => {
    getRestaurantDishes(restaurant);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurant]);

  if (!dishes) {
    return null;
  }

  const dishesOptions = dishes?.map((dish) => ({
    value: dish?.id,
    name: dish?.name,
  }));

  return (
    <FastField name="dish">
      {({ field, form }) => (
        <FormControl mb={8} isInvalid={form.errors.dish && form.touched.dish}>
          <FormLabel fontSize="16px" fontWeight="normal">Dish</FormLabel>
          <Select placeholder="Select Dishes" {...field}>
            {dishesOptions?.map((dish) => (
              <option
                key={dish.value}
                value={dish.value}
              >
                {dish.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.dish}
          </FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  );
};

const mapStateToProps = (state, { restaurant }) => ({
  dishes: getDishesByRestaurantId(state, { id: restaurant }),
});

const mapDispatchToProps = {
  getRestaurantDishes: getRestaurantDishesAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.restaurant === nextProps.restaurant
  && prevProps.dishes?.toString() === nextProps.dishes?.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(DealItemCreateDish, propsAreEqual));
