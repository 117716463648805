import React from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack } from '@chakra-ui/react';

import { FormInput, FormSelect } from '../../../common';
import QRCodeFormSVG from './QRCodeFormSVG';
import QRCodeFormRestaurant from './QRCodeFormRestaurant';
import QRCodeFormInitialValues from './QRCodeFormInitialValues';
import QRCODE_TYPES from '../../../static/qrcode_types.json';

/* =============================================================================
<QRCodeForm />
============================================================================= */
const QRCodeForm = ({ id, onSubmit }) => {
  const methods = useForm({ resolver: yupResolver(QRCodeSchema) });
  const {
    reset,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  return (
    <form onSubmit={handleSubmit(onSubmit({ reset }))}>
      <Stack mt={4} direction={['column', 'column', 'row']} spacing={4} align="start">
        <FormSelect
          mb={6}
          name="type"
          label="Type"
          options={QRCODE_TYPES}
          error={errors.type}
          selectProps={{defaultValue: 'dine_in'}}
          register={register}
        />
        <FormInput
          mb={6}
          name="name"
          label="Name"
          error={errors.name}
          register={register}
        />
      </Stack>
      <Stack direction={['column', 'column', 'row']} spacing={4} align="start">
        <FormSelect
          mb={6}
          name="isFixed"
          label="Discount Type"
          error={errors.isFixed}
          options={DISCOUNT_TYPES}
          register={register}
        />
        <FormInput
          mb={6}
          name="amount"
          label="Discount Amount"
          error={errors.amount}
          register={register}
        />
      </Stack>
      <QRCodeFormRestaurant
        id={id}
        errors={errors}
        watch={watch}
        register={register}
        setValue={setValue}
      />
      <QRCodeFormSVG id={id} />
      <Button
        type="submit"
        float="right"
        colorScheme="purple"
        isLoading={isSubmitting}
      >
        Save
      </Button>
      <QRCodeFormInitialValues id={id} setValue={setValue} />
    </form>
  );
};

const DISCOUNT_TYPES = [
  {
    label: 'Fixed',
    value: true,
  },
  {
    label: 'Percentage',
    value: false,
  }
];

const QRCodeSchema = Yup.object().shape({
  type: Yup.string()
    .required('Must not be empty'),
  name: Yup.string()
    .nullable(true),
  amount: Yup.number()
    .nullable(true)
    .transform((_, val) => val ? Number(val) : null),
  isFixed: Yup.boolean()
    .required('Must not be empty'),
  restaurant: Yup.string()
    .nullable(true),
  branch: Yup.string()
    .nullable(true),
});

/* Export
============================================================================= */
export default QRCodeForm;
