import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { List, Text, Divider } from '@chakra-ui/react';

import { Card } from '../../../common';
import StreetListFooter from '../StreetListFooter';
import StreetCategoriesListItem from './StreetCategoriesListItem';
import { reorder } from '../../../util/functions';

import { getCategories as selectCategories } from '../../redux/selectors';
import {
  getCategories as getCategoriesAction,
  updateCategory as updateCategoryAction,
  updateCategories as updateCategoriesAction,
} from '../../redux/actions';

/* =============================================================================
<StreetCategoriesList />
============================================================================= */
const StreetCategoriesList = ({
  categories,
  selected,
  onItemClick,
  getCategories,
  updateCategory,
  updateCategories,
}) => {
  const [items, setItems] = useState([]);
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);

  // Get categories
  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set categories
  useEffect(() => {
    setItems(categories);
  }, [categories]);

  const _updateCategory = (update, reordered) => {
    setSaving(true);
    updateCategory(update, (err) => {
      if (!err) {
        updateCategories(reordered);
      }
      setSaved(!err);
      setSaving(false);
    });
  };

  const _handleDragEnd = async (result) => {
    // Dropped outside the categories list
    if (result?.destination?.droppableId !== 'categories') {
      return;
    }

    // Dropped at the same index
    if (result.source.index === result.destination.index) {
      return;
    }

    const id = items[result.source.index];
    const reordered = reorder(
      items,
      result.source.index,
      result.destination.index,
    );
    setItems(reordered);
    _updateCategory(
      {
        id,
        rank: result.destination.index + 1,
      },
      reordered,
    );
  };

  return (
    <Card layerStyle="street.card">
      <Text textStyle="street.title">Categories</Text>
      <Divider mt={4} />
      <DragDropContext onDragEnd={_handleDragEnd}>
        <Droppable droppableId="categories">
          {(provided, snapshot) => (
            <List
              ref={provided.innerRef}
              {...provided.droppableProps}
              layerStyle="street.list"
              bg={snapshot.isDraggingOver ? 'blue.50' : 'inherit'}
            >
              {items.map((item, index) => (
                <StreetCategoriesListItem
                  id={item}
                  key={item}
                  index={index}
                  selected={selected === item}
                  onClick={onItemClick}
                />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      <StreetListFooter saving={saving} saved={saved} />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  categories: selectCategories(state),
});

const mapDispatchTopProps = {
  getCategories: getCategoriesAction,
  updateCategory: updateCategoryAction,
  updateCategories: updateCategoriesAction,
};

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchTopProps,
)(StreetCategoriesList);
