/**
 * Get error
 */
export const getError = (state) => state.Orders.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.Orders.loading;

/**
 * Get request id
 */
export const getRequestId = (state) => state.Orders.requestId;

/**
 * Get orders entities
 */
export const getOrderEntities = (state) => state.Entities.orders;

/**
 * Get orders
 */
export const getOrders = (state) => state.Orders.orders;

/**
 * Get next cursor
 */
export const getNextCursor = (state) => state.Orders.nextCursor;
