import React from 'react';
import { FastField } from 'formik';
import {
  Input,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<DealItemCreateName />
============================================================================= */
const DealItemCreateName = () => (
  <FastField name="name">
    {({ field, form }) => (
      <FormControl mb={8} isInvalid={form.errors.name && form.touched.name}>
        <FormLabel fontSize="16px" fontWeight="normal">Name</FormLabel>
        <Input id="name" {...field} />
        <FormErrorMessage>
          <FormErrorIcon />
          {form.errors.name}
        </FormErrorMessage>
      </FormControl>
    )}
  </FastField>
);

/* Export
============================================================================= */
export default DealItemCreateName;
