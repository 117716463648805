import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tr, Td, Switch, Circle, useToast } from '@chakra-ui/react';

import { ReactComponent as MenuIcon } from '../../../assets/icons/edit-menu-icon.svg';
import { calculateRestaurantStatus } from '../../../util/functions';

import { getRestaurant } from '../../../entities/redux/selectors';
import { updateRestaurant as updateRestaurantAction } from '../../redux/actions';

/* =============================================================================
<RestaurantsTableItem />
============================================================================= */
const RestaurantsTableItem = ({ id, restaurant, updateRestaurant }) => {
  const toast = useToast();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [operations, setOperations] = useState(false);

  const isBranch = restaurant?.type === 'branch';
  const title = isBranch
    ? `${restaurant?.chain?.name} (${restaurant?.address?.branch})`
    : restaurant?.name;
  const categories = isBranch
    ? restaurant?.chain?.categories
    : restaurant?.categories;
  const category = categories?.length ? categories[0]?.name : 'none';
  const status = calculateRestaurantStatus(restaurant?.hours);

  // Set disabled
  useEffect(() => {
    setDisabled(!!restaurant?.disabled);
  }, [restaurant?.disabled]);

  // Set disabled
  useEffect(() => {
    setOperations(restaurant?.operations);
  }, [restaurant?.operations]);

  const _handleActiveChange = async () => {
    setDisabled((prevState) => !prevState);
    updateRestaurant(
      {
        id,
        disabled: !disabled,
      },
      (err) => {
        if (err) {
          setDisabled((prevState) => !prevState);
          toast({
            title: 'Error occured.',
            description: err?.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      },
    );
  };

  const _handleOperationsChange = async (operationsUpdate) => {
    setOperations((prevState) => ({ ...prevState, ...operationsUpdate }));
    updateRestaurant(
      {
        id,
        operations: operationsUpdate,
      },
      (err) => {
        if (err) {
          setOperations(restaurant?.operations);
          toast({
            title: 'Error occured.',
            description: err?.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      },
    );
  };

  const _handleMenuPress = () => {
    history.push(`/restaurants/${id}`);
  };

  return (
    <Tr>
      <Td>{title}</Td>
      <Td>{category}</Td>
      <Td textTransform="capitalize">{status}</Td>
      <Td>
        <Switch
          colorScheme="purple"
          isChecked={!disabled}
          onChange={_handleActiveChange}
        />
      </Td>
      <Td>
        <Switch
          colorScheme="purple"
          isChecked={operations.delivery}
          onChange={() =>
            _handleOperationsChange({ delivery: !operations.delivery })
          }
        />
      </Td>
      <Td>
        <Switch
          colorScheme="purple"
          isChecked={operations.dine_in}
          onChange={() =>
            _handleOperationsChange({ dine_in: !operations.dine_in })
          }
        />
      </Td>
      <Td>
        <Switch
          colorScheme="purple"
          isChecked={operations.take_away}
          onChange={() =>
            _handleOperationsChange({ take_away: !operations.take_away })
          }
        />
      </Td>
      <Td>
        <Circle
          size="36px"
          bgColor="white"
          borderRadius="full"
          boxShadow="xl"
          onClick={_handleMenuPress}
        >
          <MenuIcon />
        </Circle>
      </Td>
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  restaurant: getRestaurant(state, { id }),
});

const mapDispatchToProps = {
  updateRestaurant: updateRestaurantAction,
};

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RestaurantsTableItem);
