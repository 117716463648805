import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { Edit2, MoreVertical, Trash2 } from 'react-feather';
import {
  Text,
  Spinner,
  ListItem,
  useToast,
  useDisclosure,
  HStack,
} from '@chakra-ui/react';

import { ConfirmationModal } from '../../../common';
import DealEditModal from '../DealModals/DealEditModal';

import { getDeal } from '../../../entities/redux/selectors';
import { deleteDeal as deleteDealAction } from '../../redux/actions';

/* =============================================================================
<DealsListItem />
============================================================================= */
const DealsListItem = ({
  id,
  deal,
  index,
  onClick,
  selected,
  deleteDeal,
}) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [deleting, setDeleting] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const name = deal?.name;
  const color = selected ? '#fff' : '#707070';

  const _toggleConfirmationModal = () => {
    setConfirmationModal((prevState) => !prevState);
  };

  const _handleClick = (e) => {
    onClick(e, id);
  };

  const _handleConfirmationModalSubmit = () => {
    setDeleting(true);
    _toggleConfirmationModal();
    deleteDeal(id, (err) => {
      if (err) {
        toast({
          title: 'Error occured.',
          description: err?.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Deal deleted.',
          description: 'Deal deleted successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
      setDeleting(false);
    });
  };

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={provided.draggableProps.style}
          height={height}
          display="flex"
          alignItems="center"
          pr={3}
          pl={1}
          mb={4}
          borderRadius="lg"
          boxShadow="md"
          bg={selected ? 'purple.500' : 'gray.50'}
          onClick={_handleClick}
        >
          <div {...provided.dragHandleProps}>
            <MoreVertical color={color} size={14} />
          </div>
          <Text mx={4} flex={1} color={color} noOfLines={1}>{name}</Text>
          <HStack spacing={4}>
            {!deleting && (
            <Trash2 size={14} color={color} onClick={_toggleConfirmationModal} />
            )}
            {deleting && (
            <Spinner size="sm" color="red.500" />
            )}
            {!deleting && (
            <Edit2 size={14} color={color} onClick={onOpen} />
            )}
          </HStack>
          <DealEditModal id={id} isOpen={isOpen} onClose={onClose} />
          <ConfirmationModal
            isOpen={confirmationModal}
            onClose={_toggleConfirmationModal}
            onSubmit={_handleConfirmationModalSubmit}
          />
        </ListItem>
      )}
    </Draggable>
  );
};

const height = '48px';

const mapStateToProps = (state, { id }) => ({
  deal: getDeal(state, { id }),
});

const mapDispatchTopProps = {
  deleteDeal: deleteDealAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchTopProps)(DealsListItem);
