import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Flex,
  Text,
  Select,
  Divider,
  Center,
  Button,
  useToast,
} from '@chakra-ui/react';

import Card from '../../common/Card';

import { getDealsWithEntities } from '../../deals/redux/selectors';
import {
  getDeals as getDealsAction,
  updateOnStreetDeal as updateOnStreetDealAction,
} from '../../deals/redux/actions';

/* =============================================================================
<StreetSelectDeals />
============================================================================= */
const StreetSelectDeals = ({ deals, getDeals, updateOnStreetDeal }) => {
  const toast = useToast();
  const [value, setValue] = useState('');
  const [saving, setSaving] = useState(false);

  const dealOnStreet = deals?.find((deal) => deal.onStreet)?.id;
  const options = deals
    ?.filter((deal) => deal.view === 'hb')
    ?.map((deal) => ({
      label: deal?.name,
      value: deal?.id,
    }));

  // Get deals
  useEffect(() => {
    getDeals();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set deal on street value
  useEffect(() => {
    if (dealOnStreet) {
      setValue(dealOnStreet);
    } else {
      setValue('none');
    }
  }, [dealOnStreet]);

  const _handleSave = async () => {
    if (!value) {
      return;
    }

    setSaving(true);

    await updateOnStreetDeal(value, (err) => {
      if (err) {
        toast({
          title: 'Error',
          duration: 3000,
          status: 'error',
          isClosable: true,
          description: err.message,
        });
      } else {
        toast({
          status: 'success',
          duration: 3000,
          isClosable: true,
          title: 'Deal Updated Successfully',
        });
      }
    });

    setSaving(false);
  };

  return (
    <Card layerStyle="street.card" w="100%">
      <Text textStyle="street.title">
        Select Deals
      </Text>
      <Divider
        w="auto"
        mt={5}
      />
      <Flex layerStyle="street.list" direction="column">
        <Select
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          <option value="none">None</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <Center>
          <Button
            mt={4}
            colorScheme="purple"
            isLoading={saving}
            onClick={_handleSave}
          >
            Save
          </Button>
        </Center>
      </Flex>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  deals: getDealsWithEntities(state),
});

const mapDispatchToProps = {
  getDeals: getDealsAction,
  updateOnStreetDeal: updateOnStreetDealAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchToProps)(StreetSelectDeals);
