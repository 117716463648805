import React from 'react';
import { FastField } from 'formik';
import {
  Select,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<DealType />
============================================================================= */
const DealType = () => (
  <FastField name="type">
    {({ field, form }) => (
      <FormControl mb={8} isInvalid={form.errors.type && form.touched.type}>
        <FormLabel fontSize="16px" fontWeight="normal">Type</FormLabel>
        <Select id="type" placeholder="Select Type" {...field}>
          {types.map((type) => <option key={type.value} value={type.value}>{type.name}</option>)}
        </Select>
        <FormErrorMessage>
          <FormErrorIcon />
          {form.errors.type}
        </FormErrorMessage>
      </FormControl>
    )}
  </FastField>
);

const types = [
  {
    name: 'Cart',
    value: 'cart',
  },
  {
    name: 'View',
    value: 'view',
  },
  {
    name: 'Call',
    value: 'call',
  },
];

/* Export
============================================================================= */
export default DealType;
