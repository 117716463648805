import React, { useState } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useToast, Accordion } from '@chakra-ui/react';

import NotificationFormAccordionItem from './NotificationFormAccordionItem';
import CreateNotification from './CreateNotification/index';
import NotificationTiming from './NotificationTiming/index';
import NotificationDelivery from './NotificationDelivery/index';

import { createNotification as createNotificationAction } from '../../redux/actions';

/* =============================================================================
<NotificationForm />
============================================================================= */
const NotificationForm = ({ createNotification }) => {
  const toast = useToast();
  const history = useHistory();
  const [accordionIndex, setAccordionIndex] = useState(0);

  const _handleAccordionClick = (index) => {
    setAccordionIndex(index);
  };
  // eslint-disable-next-line new-cap

  const accordionItems = [
    {
      id: 1,
      title: 'Create Notification',
      panel: <CreateNotification onNextClick={() => _handleAccordionClick(1)} />,
    },
    {
      id: 2,
      title: 'When Should it be delivered',
      panel: <NotificationTiming onNextClick={() => _handleAccordionClick(2)} />,
    },
    {
      id: 3,
      title: 'Who Should it be delivered it to ?',
      panel: <NotificationDelivery />,
    },
  ];

  const _handleSubmit = async (values) => {
    const notification = {
      ...values,
      scheduledAt: values.delivery === 'schedule'
        ? new Date(values.scheduledAt) : '',
    };
    if (values.screen === 'restaurant_menu') {
      notification.data = {
        payload: notification.restaurant,
      };
    }
    await createNotification(notification, (err) => {
      if (err) {
        toast({
          title: 'Error',
          duration: 3000,
          status: 'error',
          isClosable: true,
          description: err.message,
        });
      } else {
        toast({
          status: 'success',
          duration: 3000,
          isClosable: true,
          title: 'Notification Pushed Successfully',
        });
        history.push('/notifications');
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CreateNotificationSchema}
      onSubmit={_handleSubmit}
    >
      {() => (
        <Form>
          <Accordion index={accordionIndex} onChange={_handleAccordionClick} allowToggle p={5}>
            {accordionItems.map((item) => (
              <NotificationFormAccordionItem title={item.title} panel={item.panel} />
            ))}
          </Accordion>
        </Form>
      )}
    </Formik>
  );
};

const initialValues = {
  title: '',
  body: '',
  screen: 'launch',
  delivery: '',
  restaurant: '',
  scheduledAt: '',
};

const CreateNotificationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Must not be empty'),
  body: Yup.string()
    .required('Must not be empty'),
  screen: Yup.string()
    .required('Must choose one'),
  delivery: Yup.string()
    .required('Must not be empty'),
  scheduledAt: Yup.string()
    .optional(),
  restaurant: Yup.string().when('screen', {
    is: (v) => v === 'restaurant_menu',
    then: Yup.string().required('Must not be empty!'),
  }),
});

const mapDispatchToProps = {
  createNotification: createNotificationAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(NotificationForm);
