import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { FastField, Form, Formik } from 'formik';
import {
  Modal,
  Input,
  Radio,
  Button,
  HStack,
  useToast,
  ModalBody,
  FormLabel,
  RadioGroup,
  FormControl,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

import { updateWallet as updateWalletAction } from '../redux/actions';

/* =============================================================================
<WalletEditModal />
============================================================================= */
const WalletEditModal = ({ wallet, isOpen, onClose, updateWallet }) => {
  const toast = useToast();

  const _handleSubmit = async (values) => {
    await updateWallet({
      id: wallet,
      ...values,
    }, (err) => {
      if (err) {
        toast({
          title: 'Error',
          duration: 3000,
          status: 'error',
          isClosable: true,
          description: err.message,
        });
      } else {
        toast({
          status: 'success',
          duration: 3000,
          isClosable: true,
          title: 'Wallet Updated Successfully',
        });
        onClose();
      }
    });
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="20px">
        <ModalHeader fontSize="xl" textAlign="center">Edit Wallet</ModalHeader>
        <ModalBody p={8}>
          <Formik
            initialValues={initialValues}
            validationSchema={DiscountCreateSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <FastField name="type">
                  {({ form }) => (
                    <FormControl mb={8} isInvalid={form.errors.type && form.touched.type}>
                      <FormLabel fontSize="16px" fontWeight="normal">Type</FormLabel>
                      <RadioGroup
                        id="type"
                        colorScheme="purple"
                        value={form.values.type}
                        onChange={form.handleChange('type')}
                        >
                        <HStack spacing='24px'>
                          <Radio value='credit'>Credit</Radio>
                          <Radio value='debit'>Debit</Radio>
                        </HStack>
                      </RadioGroup>
                      <FormErrorMessage>
                        <FormErrorIcon />
                        {form.errors.type}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </FastField>
                <FastField name="amount">
                  {({ field, form }) => (
                    <FormControl mb={8} isInvalid={form.errors.amount && form.touched.amount}>
                      <FormLabel fontSize="16px" fontWeight="normal">Amount</FormLabel>
                      <Input id="amount" placeholder="Enter amount" {...field} />
                      <FormErrorMessage>
                        <FormErrorIcon />
                        {form.errors.amount}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </FastField>
                <FastField name="description">
                  {({ field, form }) => (
                    <FormControl mb={8} isInvalid={form.errors.description && form.touched.description}>
                      <FormLabel fontSize="16px" fontWeight="normal">Description</FormLabel>
                      <Input id="description" placeholder="Enter Description" {...field} />
                      <FormErrorMessage>
                        <FormErrorIcon />
                        {form.errors.description}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </FastField>
                <HStack justifyContent="flex-end">
                  <Button
                    size="lg"
                    variant="outline"
                    colorScheme="purple"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="lg"
                    type="submit"
                    isLoading={isSubmitting}
                    colorScheme="purple"
                  >
                    Save
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const initialValues = {
  type: '',
  amount: '',
  description: '',
};

const DiscountCreateSchema = Yup.object().shape({
  type: Yup.string()
    .oneOf(['debit', 'credit'])
    .required('Must not be empty'),
  amount: Yup.number()
    .required('Must not be empty'),
  description: Yup.string()
    .optional(''),
});

const mapDispatchToProps = {
  updateWallet: updateWalletAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(WalletEditModal);
