import React, { useEffect, useState } from 'react';
import {
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { pdf } from '@react-pdf/renderer';
import request from '../../util/request';
import InvoicePreview from './InvoicePreview';

export function saveFile(name, type, data) {
  if (data !== null && navigator.msSaveBlob)
    return navigator.msSaveBlob(new Blob([data], { type }), name);
  const a = document.createElement('a');
  a.style = 'display: none';
  const url = window.URL.createObjectURL(new Blob([data], { type }));
  a.setAttribute('href', url);
  a.setAttribute('download', name);
  document.body.append(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
  return null;
}

/**
 *
 * @param {string} restaurantId
 */
const loadLastInvoice = async (restaurantId) => {
  const result = await request({
    url: '/invoices',
    params: {
      customerId: restaurantId,
      limit: 1,
    },
  });

  return result;
};

/**
 *
 * @param {Object} invoice
 */
const createInvoice = async (invoice) => {
  const result = await request({
    url: '/invoices',
    method: 'POST',
    data: invoice,
  });

  return result;
};

const schema = yup.object({
  customerLegalName: yup.string().required(),
  customerName: yup.string().required(),
  customerAddress: yup.string().required(),
  customerVatId: yup.string().required(),
  customerBankAccName: yup.string().required(),
  customerBankName: yup.string().required(),
  customerBankAccId: yup.string().required(),
});

const GenerateInvoiceModal = ({
  restaurant,
  completedReport,
  commissionPerc,
  invoiceFrom,
  invoiceTo,
  isOpen,
  onClose,
}) => {
  const toast = useToast();
  const [lastInvoice, setLastInvoice] = useState(null);
  const [loadingLastInvoice, setLoadingLastInvoice] = useState(true);

  useEffect(() => {
    if ((isOpen, restaurant)) {
      setLastInvoice(null);
      setLoadingLastInvoice(true);
      loadLastInvoice(restaurant._id)
        .then((invoices) => {
          if (invoices[0]) {
            setLastInvoice(invoices[0]);
          }
        })
        .finally(() => setLoadingLastInvoice(false));
    }
  }, [isOpen, restaurant]);

  const onSubmit = async (values) => {
    try {
      const { invoiceId } = await createInvoice(values);
      const blob = await pdf(
        <InvoicePreview {...{ ...values, invoiceId }} />,
      ).toBlob();
      saveFile(
        `Invoice_${invoiceId}_${values.customerName.replaceAll(' ', '')}_${
          values.invoicePeriodFrom
        }_${values.invoicePeriodTo}.pdf`,
        'application/octet-binary',
        blob,
      );
      onClose();
      toast({
        status: 'success',
        title: 'Invoice generated successfully',
      });
    } catch (error) {
      toast({
        status: 'error',
        title: 'An error occurred',
        description: 'An invoice for this period already exists',
      });
    }
  };

  return (
    <Modal scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="fit-content">
        <ModalHeader>
          <Text>Generate Invoice</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody py={2}>
          {loadingLastInvoice ? (
            <Center h="100px">
              <Spinner />
            </Center>
          ) : (
            <InvoiceForm
              lastInvoice={lastInvoice}
              restaurant={restaurant}
              commissionPerc={commissionPerc}
              invoiceFrom={invoiceFrom}
              invoiceTo={invoiceTo}
              completedReport={completedReport}
              onSubmit={onSubmit}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const InvoiceForm = ({
  restaurant,
  completedReport,
  commissionPerc,
  invoiceFrom,
  invoiceTo,
  lastInvoice,
  onSubmit,
}) => {
  let invoiceNet =
    completedReport?.totalCommission + (lastInvoice?.serviceFee || 200);
  let invoiceTax = Math.round(invoiceNet * 0.13);
  let invoiceGross = invoiceNet + invoiceTax;
  let onlineBankFee = Math.round(
    ((lastInvoice?.onlineBankFeePerc || 2.5) * completedReport.onlineAmount) /
      100,
  );

  const initialValues = {
    customerId: restaurant?._id,
    customerLegalName: lastInvoice?.customerLegalName || '',
    customerName: lastInvoice?.customerName || restaurant?.name || '',
    customerAddress: lastInvoice?.customerAddress || '',
    customerVatId: lastInvoice?.customerVatId || '',
    customerBankAccName: lastInvoice?.customerBankAccName || '',
    customerBankAccId: lastInvoice?.customerBankAccId || '',
    customerBankName: lastInvoice?.customerBankName || '',
    invoiceTaxPerc: lastInvoice?.invoiceTaxPerc || 13,
    serviceFee: Number(lastInvoice?.serviceFee) || 200,
    onlineBankFeePerc: lastInvoice?.onlineBankFeePerc || 2.5,
    onlineBase: completedReport.onlineAmount,
    onlineQty: completedReport.onlineQty,
    onlineTotal: completedReport.onlineDues,
    onlineBankFee,
    ordersTotalQty: completedReport.count,
    commissionPerc: commissionPerc || lastInvoice?.commissionPerc || 0,
    commissionBase: Math.round(completedReport?.totalRevenue) || 0,
    commission: completedReport?.totalCommission,
    invoicePeriodFrom: invoiceFrom,
    invoicePeriodTo: invoiceTo,
    invoiceNet,
    invoiceTax,
    invoiceGross,
    invoiceBalance: completedReport.totalBalance,
    bykeaCodBase: completedReport.amountPayableByBykea,
    fsDiscount:
      completedReport.totalCouponDiscount + completedReport.totalQrCodeDiscount,
    fsDiscountQty: 0,
    penaltyQty: 0,
    penaltyBase: 0,
    penaltyTotal: 0,
  };
  const [formValues, setFormValues] = useState(initialValues);

  onlineBankFee = Math.round(
    (formValues.onlineBase * formValues.onlineBankFeePerc) / 100,
  );
  invoiceNet =
    formValues.commission +
    formValues.serviceFee +
    formValues.penaltyTotal +
    onlineBankFee;
  invoiceTax = Math.round((invoiceNet * formValues.invoiceTaxPerc) / 100);
  invoiceGross = invoiceNet + invoiceTax;
  const invoiceBalance =
    invoiceGross -
    formValues.fsDiscount -
    formValues.bykeaCodBase -
    formValues.onlineTotal;

  return (
    <Formik
      initialValues={{
        ...initialValues,
        invoiceNet,
        invoiceTax,
        invoiceGross,
        invoiceBalance,
        onlineBankFee,
        penaltyTotal: formValues.penaltyTotal,
        serviceFee: formValues.serviceFee,
      }}
      onSubmit={onSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      {({ values, errors, handleSubmit, isSubmitting }) => {
        setFormValues(values);
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <VStack alignItems="flex-start">
              <Field name="customerId" as={Input} display="none" />
              <Field name="ordersTotalQty" as={Input} display="none" />
              <Field name="customerLegalName">
                {({ field }) => (
                  <FormControl
                    name={field.name}
                    isInvalid={!!errors[field.name]}
                    isRequired
                  >
                    <FormLabel htmlFor={field.name}>
                      Customer Legal Name:
                    </FormLabel>
                    <Input
                      id={field.name}
                      placeholder="Customer Legal Name E.g Rukhsar Trading Co."
                      {...field}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="customerName">
                {({ field }) => (
                  <FormControl
                    name={field.name}
                    isInvalid={!!errors[field.name]}
                    isRequired
                  >
                    <FormLabel htmlFor={field.name}>Customer Name:</FormLabel>
                    <Input
                      id={field.name}
                      placeholder="Name Eg: California Pizza"
                      {...field}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="customerAddress">
                {({ field }) => (
                  <FormControl
                    name={field.name}
                    isInvalid={!!errors[field.name]}
                    isRequired
                  >
                    <FormLabel htmlFor={field.name}>
                      Customer Address:
                    </FormLabel>
                    <Input
                      id={field.name}
                      placeholder="Legal Address"
                      {...field}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="customerVatId">
                {({ field }) => (
                  <FormControl
                    name={field.name}
                    isInvalid={!!errors[field.name]}
                    isRequired
                  >
                    <FormLabel htmlFor={field.name}>Vat Id or CNIC:</FormLabel>
                    <Input
                      id={field.name}
                      placeholder="Vat Id or CNIC"
                      {...field}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="customerBankAccName">
                {({ field }) => (
                  <FormControl
                    name={field.name}
                    isInvalid={!!errors[field.name]}
                    isRequired
                  >
                    <FormLabel htmlFor={field.name}>
                      Beneficiary Name:
                    </FormLabel>
                    <Input
                      id={field.name}
                      placeholder="Beneficiary Name"
                      {...field}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="customerBankAccId">
                {({ field }) => (
                  <FormControl
                    name={field.name}
                    isInvalid={!!errors[field.name]}
                    isRequired
                  >
                    <FormLabel htmlFor={field.name}>
                      Beneficiary Account #:
                    </FormLabel>
                    <Input
                      id={field.name}
                      placeholder="Beneficiary Acc. #"
                      {...field}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="customerBankName">
                {({ field }) => (
                  <FormControl
                    name={field.name}
                    isInvalid={!!errors[field.name]}
                    isRequired
                  >
                    <FormLabel htmlFor={field.name}>Bank Name:</FormLabel>
                    <Input id={field.name} placeholder="Bank Name" {...field} />
                  </FormControl>
                )}
              </Field>
              <FormLabel htmlFor="invoicePeriodFrom">Invoice From:</FormLabel>
              <Field name="invoicePeriodFrom" as={Input} disabled />
              <FormLabel htmlFor="invoicePeriodTo">Invoice To:</FormLabel>
              <Field name="invoicePeriodTo" as={Input} disabled />
              <FormLabel htmlFor="invoiceNet">Net Invoice Total:</FormLabel>
              <Field name="invoiceNet" as={Input} disabled />
              <FormLabel htmlFor="invoiceTaxPerc">GST %:</FormLabel>
              <Field
                name="invoiceTaxPerc"
                as={Input}
                type="number"
                min={0}
                max={20}
              />
              <FormLabel htmlFor="invoiceTax">GST Amount:</FormLabel>
              <Field name="invoiceTax" as={Input} disabled />
              <FormLabel htmlFor="invoiceGross">Gross Invoice Total:</FormLabel>
              <Field name="invoiceGross" as={Input} disabled />
              <FormLabel htmlFor="invoiceBalance">Final Balance:</FormLabel>
              <Field name="invoiceBalance" as={Input} disabled />
              <FormLabel htmlFor="serviceFee">Service Fee:</FormLabel>
              <Field name="serviceFee" as={Input} type="number" />
              <FormLabel htmlFor="penaltyQty">
                Quantity of orders penalized:
              </FormLabel>
              <Field
                name="penaltyQty"
                as={Input}
                placeholder="Penalty Qty"
                type="number"
              />
              <FormLabel htmlFor="penaltyBase">
                Penalized Order Amount:
              </FormLabel>
              <Field
                name="penaltyBase"
                as={Input}
                placeholder="Penalty Base"
                type="number"
              />
              <FormLabel htmlFor="penaltyTotal">Penalty Amount:</FormLabel>
              <Field
                name="penaltyTotal"
                as={Input}
                placeholder="Penalty Total"
                type="number"
              />
              <FormLabel htmlFor="onlineQty">Online orders quantity:</FormLabel>
              <Field
                name="onlineQty"
                as={Input}
                placeholder="Online Qty"
                type="number"
                disabled
              />
              <FormLabel htmlFor="onlineBase">Online orders amount:</FormLabel>
              <Field
                name="onlineBase"
                as={Input}
                placeholder="Online Base"
                type="number"
                disabled
              />
              <FormLabel htmlFor="onlineBankFeePerc">
                Online Bank Fee %:
              </FormLabel>
              <Field
                name="onlineBankFeePerc"
                as={Input}
                placeholder="Online Bank Fee %"
                type="number"
              />
              <FormLabel htmlFor="onlineBankFee">
                Online Bank Fee Total:
              </FormLabel>
              <Field
                name="onlineBankFee"
                as={Input}
                placeholder="Online Bank Fee Total"
                disabled
              />
              <FormLabel htmlFor="commissionPerc">Commission %:</FormLabel>
              <Field
                name="commissionPerc"
                as={Input}
                placeholder="Commission %"
                disabled
              />
              <FormLabel htmlFor="commissionBase">Commission Base:</FormLabel>
              <Field
                name="commissionBase"
                as={Input}
                placeholder="Commission Base"
                disabled
              />
              <FormLabel htmlFor="commission">Commission Amount:</FormLabel>
              <Field
                name="commission"
                as={Input}
                placeholder="Commission"
                disabled
              />
              <FormLabel htmlFor="fsDiscountQty">
                Quantity of orders with FS discount:
              </FormLabel>
              <Field
                name="fsDiscountQty"
                as={Input}
                placeholder="# of orders with Food Street Discount"
                disabled
              />
              <FormLabel htmlFor="fsDiscountQty">
                Food Street discount:
              </FormLabel>
              <Field
                name="fsDiscount"
                as={Input}
                placeholder="Food Street Discount"
                disabled
              />
              <FormLabel htmlFor="bykeaCodBase">
                COD collected by Bykea (excl. delivery):
              </FormLabel>
              <Field
                name="bykeaCodBase"
                as={Input}
                placeholder="Bykea COD Base"
                disabled
              />
            </VStack>
            <HStack
              py={4}
              position="sticky"
              bottom={0}
              bgColor="white"
              justify="flex-end"
            >
              <Button
                type="submit"
                variant="solid"
                colorScheme="purple"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Generate
              </Button>
            </HStack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default GenerateInvoiceModal;
