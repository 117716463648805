import { actionGenerator } from '../../util/reduxHelpers';

export const COUPONS_GET = actionGenerator(
  'COUPON/COUPONS_GET',
);

export const COUPONS_REFRESH = actionGenerator(
  'COUPON/COUPONS_REFRESH',
);

export const COUPON_GET = actionGenerator(
  'COUPON/COUPON_GET',
);

export const COUPON_CREATE = actionGenerator(
  'COUPON/COUPON_CREATE',
);

export const COUPON_UPDATE = actionGenerator(
  'COUPON/COUPON_UPDATE',
);

export const COUPON_DELETE = actionGenerator(
  'COUPON/COUPON_DELETE',
);
