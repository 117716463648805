export default {
  brand: {
    50: '#9B2C2C',
    100: '#9B2C2C',
    200: '#9B2C2C',
    300: '#9B2C2C',
    400: '#9B2C2C',
    500: '#9B2C2C',
    600: '#9B2C2C',
    700: '#9B2C2C',
    800: '#9B2C2C',
    900: '#9B2C2C',
  },
};
