import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import {
  category as categorySchema,
  restaurant as restaurantSchema,
} from '../../entities/api/schema';

/**
 * CATEGORIES_GET
 */
export const getCategories = () => async (dispatch) => {
  try {
    dispatch({ type: constants.CATEGORIES_GET.REQUEST });

    const payload = await request({
      url: '/categories',
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [categorySchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.CATEGORIES_GET.SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: constants.CATEGORIES_GET.FAIL,
      error,
    });
  } finally {
    dispatch({ type: constants.CATEGORIES_GET.COMPLETE });
  }
};

/**
 * CATEGORY_CREATE
 */
export const createCategory = (data, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.CATEGORY_CREATE.REQUEST });

    const payload = await request({
      url: '/categories',
      method: 'POST',
      data,
    });
    const { entities, result } = normalize(payload, categorySchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.CATEGORY_CREATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.CATEGORY_CREATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.CATEGORY_CREATE.COMPLETE });
  }
};

/**
 * CATEGORY_UPDATE
 */
export const updateCategory = (category, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.CATEGORY_UPDATE.REQUEST });

    const payload = await request({
      url: `/categories/${category.id}`,
      method: 'PUT',
      data: category,
    });
    const { entities, result } = normalize(payload, categorySchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.CATEGORY_UPDATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.CATEGORY_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.CATEGORY_UPDATE.COMPLETE });
  }
};

/**
 * CATEGORY_DELETE
 */
export const deleteCategory = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.CATEGORY_DELETE.REQUEST });

    await request({
      url: `/categories/${id}`,
      method: 'DELETE',
    });

    dispatch({
      type: constants.CATEGORY_DELETE.SUCCESS,
      payload: id,
    });
    if (typeof cb === 'function') {
      cb(null, id);
    }
  } catch (error) {
    dispatch({
      type: constants.CATEGORY_DELETE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.CATEGORY_DELETE.COMPLETE });
  }
};

/**
 * CATEGORIES_UPDATE
 */
export const updateCategories = (payload) => ({
  type: constants.CATEGORIES_UPDATE,
  payload,
});

/**
 * RESTAURANTS_GET
 */
export const getRestaurants = (category) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANTS_GET.REQUEST });

    const payload = await request({
      url: `/categories/${category}/restaurants`,
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [restaurantSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.RESTAURANTS_GET.SUCCESS,
      payload: {
        category,
        restaurants: result,
      },
    });
  } catch (error) {
    dispatch({
      type: constants.RESTAURANTS_GET.FAIL,
      error,
    });
  } finally {
    dispatch({ type: constants.RESTAURANTS_GET.COMPLETE });
  }
};

/**
 * RESTAURANTS_ADD
 */
export const addRestaurants = (category, restaurants, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANTS_ADD.REQUEST });

    const payload = await request({
      url: `/categories/${category}/restaurants`,
      method: 'POST',
      data: restaurants,
    });
    const { entities } = normalize(payload, [restaurantSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.RESTAURANTS_ADD.SUCCESS,
      payload: {
        category,
        restaurants,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.RESTAURANTS_ADD.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RESTAURANTS_ADD.COMPLETE });
  }
};

/**
 * RESTAURANT_UPDATE
 */
export const updateRestaurant = (category, restaurant, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANT_UPDATE.REQUEST });

    const payload = await request({
      url: `/categories/${category}/restaurants/${restaurant.id}`,
      method: 'PUT',
      data: restaurant,
    });
    const { entities, result } = normalize(payload, restaurantSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.RESTAURANT_UPDATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.RESTAURANT_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RESTAURANT_UPDATE.COMPLETE });
  }
};

/**
 * RESTAURANT_REMOVE
 */
export const removeRestaurant = (category, restaurant, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANT_REMOVE.REQUEST });

    await request({
      url: `/categories/${category}/restaurants/${restaurant}`,
      method: 'DELETE',
    });

    dispatch({
      type: constants.RESTAURANT_REMOVE.SUCCESS,
      payload: {
        category,
        restaurant,
      },
    });
    if (typeof cb === 'function') {
      cb(null, {
        category,
        restaurant,
      });
    }
  } catch (error) {
    dispatch({
      type: constants.RESTAURANT_REMOVE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RESTAURANT_REMOVE.COMPLETE });
  }
};

/**
 * RESTAURANTS_UPDATE
 */
export const updateRestaurants = (payload) => ({
  type: constants.RESTAURANTS_UPDATE,
  payload,
});

/**
 * SPECIAL_CATEGORY_GET
 */
export const getSpecialCategory = () => async (dispatch) => {
  try {
    dispatch({ type: constants.SPECIAL_CATEGORY_GET.REQUEST });

    const payload = await request({
      url: '/categories/special',
      method: 'GET',
    });
    const { entities, result } = normalize(payload, categorySchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.SPECIAL_CATEGORY_GET.SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: constants.SPECIAL_CATEGORY_GET.FAIL,
      error,
    });
  } finally {
    dispatch({ type: constants.SPECIAL_CATEGORY_GET.COMPLETE });
  }
};
