import * as constants from './constants';

export const INITIAL_STATE = {
  qrCodes: [],
  nextCursor: '',
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // QRCODES_GET
    case constants.QRCODES_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.QRCODES_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.QRCODES_GET.SUCCESS:
      return {
        ...state,
        error: null,
        qrCodes: [...new Set([...state.qrCodes, ...payload.data])],
        nextCursor: payload.nextCursor,
      };
    case constants.QRCODES_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // QRCODES_REFRESH
    case constants.QRCODES_REFRESH.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.QRCODES_REFRESH.FAIL:
      return {
        ...state,
        error,
      };
    case constants.QRCODES_REFRESH.SUCCESS:
      return {
        ...state,
        error: null,
        qrCodes: payload.data,
        nextCursor: payload.nextCursor,
      };
    case constants.QRCODES_REFRESH.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // QRCODE_GET
    case constants.QRCODE_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.QRCODE_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.QRCODE_GET.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.QRCODE_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // QRCODE_CREATE
    case constants.QRCODE_CREATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.QRCODE_CREATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.QRCODE_CREATE.SUCCESS:
      return {
        ...state,
        error: null,
        qrCodes: [payload, ...state.qrCodes],
      };
    case constants.QRCODE_CREATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // QRCODE_UPDATE
    case constants.QRCODE_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.QRCODE_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.QRCODE_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.QRCODE_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // QRCODE_DELETE
    case constants.QRCODE_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.QRCODE_DELETE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.QRCODE_DELETE.SUCCESS:
      return {
        ...state,
        error: null,
        qrCodes: state.qrCodes.filter((id) => id !== payload),
      };
    case constants.QRCODE_DELETE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
