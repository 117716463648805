import React from 'react';
import { FastField } from 'formik';
import {
  Input,
  HStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<DealItemCreateTime />
============================================================================= */
const DealItemCreateTime = () => (
  <HStack flex={1} alignItems="flex-start">
    <FastField name="startAt">
      {({ field, form }) => (
        <FormControl mb={8} isInvalid={form.errors.startAt && form.touched.startAt}>
          <FormLabel fontSize="16px" fontWeight="normal">Start At</FormLabel>
          <Input type="date" id="startAt" {...field} />
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.startAt}
          </FormErrorMessage>
        </FormControl>
      )}
    </FastField>
    <FastField name="endAt">
      {({ field, form }) => (
        <FormControl mb={8} isInvalid={form.errors.endAt && form.touched.endAt}>
          <FormLabel fontSize="16px" fontWeight="normal">End At</FormLabel>
          <Input type="date" id="endAt" {...field} />
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.endAt}
          </FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  </HStack>
);

/* Export
============================================================================= */
export default DealItemCreateTime;
