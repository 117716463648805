import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Tab,
  Text,
  HStack,
  Tabs,
  Heading,
  TabList,
} from '@chakra-ui/react';

import { getType } from '../redux/selectors';
import { setType as setTypeAction } from '../redux/actions';

/* =============================================================================
<PostsHeader />
============================================================================= */
const PostsHeader = ({ type, setType }) => {
  const [tabIndex, setTabIndex] = useState(0);

  // Set current active tab
  useEffect(() => {
    switch (type) {
      case 'all':
        setTabIndex(0);
        break;
      case 'published':
        setTabIndex(1);
        break;
      case 'Unpublished':
        setTabIndex(2);
        break;
      default:
        break;
    }
  }, [type]);

  const _handleTabChange = (value) => {
    setTabIndex(value);
    switch (value) {
      case 0:
        setType('all');
        break;
      case 1:
        setType('published');
        break;
      case 2:
        setType('Unpublished');
        break;
      default:
        break;
    }
  };

  return (
    <HStack justifyContent="space-between" p={6}>
      <Heading fontSize="4xl" fontWeight="normal">Timeline Posts</Heading>
      <Tabs size="lg" colorScheme="blue" index={tabIndex} onChange={_handleTabChange}>
        <TabList border="none">
          <Tab>
            <Text>All</Text>
          </Tab>
          <Tab>
            <Text>Published</Text>
          </Tab>
          <Tab>
            <Text>Unpublished</Text>
          </Tab>
        </TabList>
      </Tabs>
    </HStack>
  );
};

const mapStateToProps = (state) => ({
  type: getType(state),
});

const mapDispatchToProps = {
  setType: setTypeAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchToProps)(PostsHeader);
