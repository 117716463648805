import moment from 'moment';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';

import { getDiscount } from '../../../../entities/redux/selectors';

/* =============================================================================
<DiscountEditModalInitialize />
============================================================================= */
const DiscountEditModalInitialize = ({ discount }) => {
  const { resetForm } = useFormikContext();

  const id = discount?.id;
  const name = discount?.name;
  const type = discount?.type;
  const value = discount?.value;
  const startAt = moment(discount?.startAt).format('YYYY-MM-DD');
  const endAt = moment(discount?.endAt).format('YYYY-MM-DD');

  // Set default values
  useEffect(() => {
    resetForm({
      values: {
        name,
        value,
        type,
        startAt,
        endAt,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    name,
    value,
    type,
    startAt,
    endAt,
  ]);

  return null;
};

const mapStateToProps = (state, { id }) => ({
  discount: getDiscount(state, { id, normalize: true }),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(DiscountEditModalInitialize);
