import React from 'react';
import { FastField } from 'formik';
import {
  Input,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<DealItemCreateRank/>
============================================================================= */
const DealItemCreateRank = () => (
  <FastField name="rank">
    {({ field, form }) => (
      <FormControl mb={8} isInvalid={form.errors.rank && form.touched.rank}>
        <FormLabel fontSize="16px" fontWeight="normal">Rank</FormLabel>
        <Input type="number" id="rank" placeholder="ex. 1, 2" {...field} />
        <FormErrorMessage>
          <FormErrorIcon />
          {form.errors.rank}
        </FormErrorMessage>
      </FormControl>
    )}
  </FastField>
);

/* Export
============================================================================= */
export default DealItemCreateRank;
