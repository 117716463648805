import React from 'react';
import { Button, HStack } from '@chakra-ui/react';

/* =============================================================================
<DiscountCreateModal />
============================================================================= */
const DiscountCreateModal = ({ title, loading }) => (
  <HStack my={5} justify="center">
    <Button
      size="lg"
      type="submit"
      isLoading={loading}
      colorScheme="purple"
    >
      {title}
    </Button>
  </HStack>
);

/* Export
============================================================================= */
export default DiscountCreateModal;
