import { actionGenerator } from '../../util/reduxHelpers';

export const TYPE_SET = 'POSTS/TYPE_SET';

export const POSTS_GET = actionGenerator(
  'POSTS/POSTS_GET',
);
export const POSTS_REFRESH = actionGenerator(
  'POSTS/POSTS_REFRESH',
);
export const POST_GET = actionGenerator(
  'POSTS/POST_GET',
);
export const POST_UPDATE = actionGenerator(
  'POSTS/POST_UPDATE',
);
