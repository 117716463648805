import React from 'react';
import { connect } from 'react-redux';
import {
  Text,
  HStack,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

import DealViewItemCreateModal from '../DealItemCreateModal/DealViewItemCreateModal';
import DealCartItemCreateModal from '../DealItemCreateModal/DealCartItemCreateModal';
import DealCallItemCreateModal from '../DealItemCreateModal/DealCallItemCreateModal';

import { getDeal } from '../../../entities/redux/selectors';

/* =============================================================================
<DealItemsListHeader />
============================================================================= */
const DealItemsListHeader = ({ id, deal }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const name = deal?.name;
  const type = deal?.type;
  const view = deal?.view === 'hs' ? 'Horizontal Small'
    : deal?.view === 'hb' ? 'Horizontal Big'
      : deal?.view === 'v' ? 'Vertical' : null;

  return (
    <HStack px={6} justify="space-between">
      <Text textStyle="street.title">{`${name} (${view})`}</Text>
      <Button colorScheme="purple" onClick={onOpen}>Add items</Button>
      {type === 'cart' ? (
        <DealCartItemCreateModal isOpen={isOpen} onClose={onClose} id={id} />
      ) : type === 'view' ? (
        <DealViewItemCreateModal isOpen={isOpen} onClose={onClose} id={id} />
      ) : type === 'call' ? (
        <DealCallItemCreateModal isOpen={isOpen} onClose={onClose} id={id} />
      ) : null}
    </HStack>
  );
};

const mapStateToProps = (state, { id }) => ({
  deal: getDeal(state, { id }),
});
// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.id === nextProps.id
&& prevProps.deal?.name === nextProps.deal?.name
&& prevProps.deal?.type === nextProps.deal?.type
&& prevProps.deal?.view === nextProps.deal?.view;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(DealItemsListHeader, propsAreEqual));
