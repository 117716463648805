import React from 'react';
import { Th } from '@chakra-ui/react';

/* =============================================================================
<RestaurantsTableHeadItem />
============================================================================= */
const RestaurantsTableHeadItem = ({ title }) => <Th bg="gray.100">{title}</Th>;

/* Export
============================================================================= */
export default RestaurantsTableHeadItem;
