import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Box } from '@chakra-ui/react';

import MapView from '../MapView';
import MapMarker from '../MapMarker';
import MapMarkerRider from '../../assets/icons/map-marker-rider.svg';
import { GOOGLE_API_KEY } from '../../config/secrets';

import { getOrder } from '../../entities/redux/selectors';

/* =============================================================================
<OrderDetailsTracking />
============================================================================= */
const OrderDetailsTracking = ({ order }) => {
  const [bounds, setBounds] = useState(null);

  const deliveryLocation = order?.address?.location;
  const riderLocation = order?.tracking?.location;
  const restaurantLocation = order?.restaurant?.location;
  const locationStr = JSON.stringify([
    deliveryLocation,
    riderLocation,
    restaurantLocation,
  ]);

  // Center map around coordinates
  useEffect(() => {
    setBounds([
      deliveryLocation,
      riderLocation,
      restaurantLocation,
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationStr]);

  if (order?.type === 'take_away') {
    return null;
  }

  return (
    <Box height="318px" mt={6}>
      <Wrapper apiKey={GOOGLE_API_KEY} render={render}>
        <MapView bounds={bounds}>
          {deliveryLocation?.length > 0 && (
            <MapMarker
              position={{ lat: deliveryLocation[1], lng: deliveryLocation[0] }}
            />
          )}
          {riderLocation?.length > 0 && (
            <MapMarker
              position={{ lat: riderLocation[1], lng: riderLocation[0] }}
              icon={MapMarkerRider}
            />
          )}
          {restaurantLocation?.length > 0 && (
            <MapMarker
              position={{ lat: restaurantLocation[1], lng: restaurantLocation[0] }}
              icon="./logo32.png"
            />
          )}
        </MapView>
      </Wrapper>
    </Box>
  );
};

const render = (status) => <div>{status}</div>;

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.type === nextProps.order?.type
  && prevProps.order?.status === nextProps.order?.status
  && prevProps.order?.customer?.profile?.avatar === nextProps.order?.customer?.profile?.avatar
  && prevProps.order?.address?.location?.toString()
    === nextProps.order?.address?.location?.toString()
    && prevProps.order?.tracking?.location?.toString()
      === nextProps.order?.tracking?.location?.toString()
    && prevProps.order?.restaurant?.location?.toString()
      === nextProps.order?.restaurant?.location?.toString()
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderDetailsTracking, propsAreEqual));
