import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { Flex } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'querystring';

import Card from '../../common/Card';
import RestaurantsTable from '../components/RestaurantsTable';
import RestaurantsHeader from '../components/RestaurantsHeader';
import RestaurantsFooter from '../components/RestaurantsFooter';
import usePagination from '../../hooks/use-pagination';

import {
  getNextCursor,
  getRestaurants as selectRestaurants,
} from '../redux/selectors';
import {
  getRestaurants as getRestaurantsAction,
  refreshRestaurants as refreshRestaurantsAction,
} from '../redux/actions';

const useQuery = () => new URLSearchParams(useLocation().search);

/* =============================================================================
<RestaurantScreen />
============================================================================= */
const RestaurantScreen = ({
  restaurants,
  nextCursor,
  getRestaurants,
  refreshRestaurants,
}) => {
  const history = useHistory();
  const params = useQuery();
  const search = params.get('search');
  const [searchQuery, setSearchQuery] = useState(search || '');
  const { next, prev, jumpTo, items, canNext, canPrev } =
    usePagination(restaurants);

  // Get restaurants
  useEffect(() => {
    if (!search || !search.trim()) {
      refreshRestaurants(null, () => {
        jumpTo(1);
      });
      return;
    }
    refreshRestaurants({ name: { $regex: search, $options: 'i' } }, () => {
      jumpTo(1);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const _handleNext = () => {
    if (canNext) {
      next();
      return;
    }

    if (nextCursor) {
      getRestaurants(nextCursor, (err) => {
        if (!err) {
          next(restaurants);
        }
      });
    }
  };

  const _handlePrev = () => {
    if (canPrev) {
      prev();
    }
  };

  const _handleRefresh = () => {
    refreshRestaurants(null, () => {
      jumpTo(1);
    });
  };

  const findRestaurants = useDebouncedCallback((query) => {
    history.push({
      search: qs.encode({
        ...(!!query && { search: query }),
      }),
    });
  }, 350);

  const onUpdateSearchQuery = (query) => {
    setSearchQuery(query);
    findRestaurants(query);
  };

  return (
    <Card as={Flex} flex={1} overflow="hidden" direction="column" p={0}>
      <RestaurantsHeader
        searchQuery={searchQuery}
        onUpdateSearchQuery={onUpdateSearchQuery}
        onRefresh={_handleRefresh}
      />
      <RestaurantsTable data={items} />
      <RestaurantsFooter
        canNext={canNext || !!nextCursor}
        canPrev={canPrev}
        onNext={_handleNext}
        onPrev={_handlePrev}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  restaurants: selectRestaurants(state),
  nextCursor: getNextCursor(state),
});

const mapDispatchToProps = {
  getRestaurants: getRestaurantsAction,
  refreshRestaurants: refreshRestaurantsAction,
};

const propsAreEqual = (prevProps, nextProps) =>
  prevProps.nextCursor === nextProps.nextCursor &&
  prevProps.restaurants.toString() === nextProps.restaurants.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(RestaurantScreen, propsAreEqual));
