import { useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import WalletEditModal from '../components/WalletEditModal';

import WalletHeader from '../components/WalletHeader';
import WalletTransactions from '../components/WalletTransactions';

/* =============================================================================
<WalletScreen />
============================================================================= */
const WalletScreen = () => {
  const {isOpen, onClose, onOpen} = useDisclosure();
  const [wallet, setWallet] = useState('');

  const _handleWalletChange = (value) => {
    setWallet(value);
  };

  return (
    <>
      <WalletHeader onWalletChange={_handleWalletChange} />
      {wallet && (
        <WalletTransactions wallet={wallet} onEditClick={onOpen} />
      )}
      <WalletEditModal isOpen={isOpen} wallet={wallet} onClose={onClose} />
    </>
  );
};

/* Export
============================================================================= */
export default WalletScreen;
