import { actionGenerator } from '../../util/reduxHelpers';

export const NOTIFICATIONS_GET = actionGenerator(
  'NOTIFICATION/NOTIFICATIONS_GET',
);
export const NOTIFICATIONS_REFRESH = actionGenerator(
  'NOTIFICATION/NOTIFICATIONS_REFRESH',
);

export const NOTIFICATION_CREATE = actionGenerator(
  'NOTIFICATION/NOTIFICATION_CREATE',
);

export const NOTIFICATION_DELETE = actionGenerator(
  'NOTIFICATION/NOTIFICATION_DELETE',
);
