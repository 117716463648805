import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  List,
  Divider,
  useToast,
} from '@chakra-ui/react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { Card } from '../../../common';
import DealItemsListHeader from './DealItemsListHeader';
import DealItemsListItem from './DealItemsListItem';
import { reorder } from '../../../util/functions';

import { getDeal as selectDeal } from '../../../entities/redux/selectors';
import { getDeal as getDealAction, updateDeal as updateDealAction } from '../../redux/actions';

/* =============================================================================
<DealItemsList />
============================================================================= */
const DealItemsList = ({
  id,
  deal,
  getDeal,
  updateDeal,
}) => {
  const toast = useToast();
  const items = deal?.items;
  const [_items, setItems] = useState([]);

  // Get Deal
  useEffect(() => {
    if (id) {
      getDeal(id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Set deals
  useEffect(() => {
    setItems(items);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const _handleDragEnd = async (result) => {
    // Dropped outside the categories list
    if (result?.destination?.droppableId !== 'deals') {
      return;
    }
    // Dropped at the same index
    if (result.source.index === result.destination.index) {
      return;
    }

    const reordered = reorder(
      _items,
      result.source.index,
      result.destination.index,
    );
    setItems(reordered);
    updateDeal({
      id,
      items: reordered,
    }, (err) => {
      if (err) {
        toast({
          title: 'Error',
          description: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Card layerStyle="street.card" flex={4}>
      {id && (
        <>
          <DealItemsListHeader id={id} />
          <Divider mt={4} />
          <DragDropContext onDragEnd={_handleDragEnd}>
            <Droppable droppableId="deals">
              {(provided, snapshot) => (
                <List
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  layerStyle="street.list"
                  bg={snapshot.isDraggingOver ? 'blue.50' : 'inherit'}
                >
                  {_items?.map((item, index) => (
                    <DealItemsListItem
                      key={item}
                      id={item}
                      dealId={id}
                      index={index}
                    />
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </>

      )}
    </Card>
  );
};

const mapStateToProps = (state, { id }) => ({
  deal: selectDeal(state, { id, normalized: true }),
});

const mapDispatchToProps = {
  getDeal: getDealAction,
  updateDeal: updateDealAction,
};

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.id === nextProps.id
  && prevProps.deal?.items?.toString() === nextProps.deal?.items?.toString()
);
/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(DealItemsList, propsAreEqual));
