import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Tr, Td, Button, useToast, Flex} from '@chakra-ui/react';

import QRCODE_TYPES from '../../../static/qrcode_types.json';

import { getQRCode } from '../../../entities/redux/selectors';
import { deleteQRCode as deleteQRCodeAction } from '../../redux/actions';

/* =============================================================================
<QRCodesTableItem />
============================================================================= */
const QRCodesTableItem = ({id, qrCode, deleteQRCode}) => {
  const toast = useToast();
  const [deleting, setDeleting] = useState(false);

  const type = (qrCode?.type && QRCODE_TYPES.find(t => t.value === qrCode.type)?.label) || '--';
  const name = qrCode?.name || '--';
  const amount = qrCode?.amount;
  const isFixed = qrCode?.isFixed;
  const discount = (amount && (isFixed ? `Rs.${amount}` : `${amount}%`)) || '--';
  const restaurantType = qrCode?.restaurant?.type;
  const restaurantName = (restaurantType === 'branch' ? `${qrCode?.restaurant?.chain?.name} - ${qrCode?.restaurant?.address?.branch}` : qrCode?.restaurant?.name) || '--';

  const _handleDeleteClick = async () => {
    setDeleting(true);
    await deleteQRCode(id, (err) => {
      if (err) {
        toast({
          title: 'Error',
          status: 'error',
          duration: 3000,
          isClosable: true,
          description: err.message,
        });
      } else {
        toast({
          title: 'QRCode deleted successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    });
    setDeleting(false);
  };

  return (
    <Tr>
      <Td>{type}</Td>
      <Td>{name}</Td>
      <Td>{discount}</Td>
      <Td>{restaurantName}</Td>
      <Td>
        <Flex>
          <Button
            as={Link}
            to={`/qrCodes/${id}`}
            size="sm"
            colorScheme="green"
          >
            Edit
          </Button>
          <Button
            ml={2}
            size="sm"
            isLoading={deleting}
            colorScheme="red"
            onClick={_handleDeleteClick}
          >
            Delete
          </Button>
        </Flex>
      </Td>
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  qrCode: getQRCode(state, { id }),
});

const mapDispatchToProps = {
  deleteQRCode: deleteQRCodeAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) =>
  prevProps.id === nextProps.id
  && prevProps.qrCode?.type === nextProps.qrCode?.type
  && prevProps.qrCode?.name === nextProps.qrCode?.name
  && prevProps.qrCode?.amount === nextProps.qrCode?.amount
  && prevProps.qrCode?.isFixed === nextProps.qrCode?.isFixed
  && prevProps.qrCode?.restaurant?.name === nextProps.qrCode?.restaurant?.name
  && prevProps.qrCode?.restaurant?.chain?.name === nextProps.qrCode?.restaurant?.chain?.name;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(QRCodesTableItem, propsAreEqual));
