import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import { Link as ReactLink } from 'react-router-dom';
import { Eye } from 'react-feather';
import {
  Flex,
  Link,
  Input,
  HStack,
  Button,
  Heading,
  FormLabel,
  InputGroup,
  FormControl,
  FormErrorMessage,
  InputRightElement,
} from '@chakra-ui/react';

import { ReactComponent as AppNameIcon } from '../../assets/icons/app-name-icon.svg';
import { ReactComponent as AppLogoIcon } from '../../assets/icons/app-logo-icon.svg';

import { login as loginAction } from '../redux/actions';

/* =============================================================================
<LoginScreen />
============================================================================= */
const LoginScreen = ({ login }) => {
  const [passwordToggle, setPasswordToggle] = useState(false);

  const _handlePasswordToggle = () => {
    setPasswordToggle(!passwordToggle);
  };

  const _handleSubmit = async (values) => {
    await login(values.email, values.password);
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={LoginSchema}
      onSubmit={_handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <HStack>
            <AppLogoIcon />
            <AppNameIcon />
          </HStack>
          <Heading
            my={8}
            fontSize="3xl"
            fontWeight="bold"
          >
            Sign In
          </Heading>
          <Field name="email">
            {({ field, form }) => (
              <FormControl isInvalid={form.errors.email && form.touched.email}>
                <FormLabel htmlFor="email">Email*</FormLabel>
                <Input
                  {...field}
                  id="email"
                  placeholder="Your Email"
                />
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="password">
            {({ field, form }) => (
              <FormControl
                mt="1.25rem"
                isInvalid={form.errors.password && form.touched.password}
              >
                <FormLabel htmlFor="password">Password*</FormLabel>
                <InputGroup>
                  <Input
                    id="password"
                    type={passwordToggle ? 'text' : 'password'}
                    placeholder="Your Password"
                    {...field}
                  />
                  <InputRightElement>
                    <Button variant="unstyled" onClick={_handlePasswordToggle}>
                      <Eye size="0.9rem" />
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Flex mt={4} justify="end">
            <Link
              as={ReactLink}
              to="/auth/forgot_password"
              fontSize="sm"
              color="blue.600"
              fontWeight="semibold"
            >
              Forgot your password?
            </Link>
          </Flex>
          <Button
            mt={6}
            type="submit"
            isFullWidth
            colorScheme="blue"
            isLoading={isSubmitting}
          >
            Sign In
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email!')
    .required('Must not be empty!'),
  password: Yup.string().required('Must not be empty!'),
});

const mapDispatchToProps = {
  login: loginAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(LoginScreen);
