import React from 'react';
import {
  Input,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<FormInput />
============================================================================= */
const FormInput = ({
  name,
  label,
  error,
  children,
  register,
  inputProps,
  ...rest
}) => (
  <FormControl isInvalid={error} {...rest}>
    {Boolean(label) && (
      <FormLabel>{label}</FormLabel>
    )}
    {children || (
      <Input {...register(name)} {...inputProps} />
    )}
    <FormErrorMessage>
      <FormErrorIcon />
      {error?.message}
    </FormErrorMessage>
  </FormControl>
);

/* Export
============================================================================= */
export default FormInput;
