import React from 'react';
import { get } from 'lodash';
import { Key } from 'react-feather';
import passwordGenerator from 'generate-password';
import {
  Stack,
  Input,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';

import { FormInput } from '../../../common';

/* =============================================================================
<RestaurantFormOwner />
============================================================================= */
const RestaurantFormOwner = ({ errors, register, setValue }) => {
  const _handleAutoGeneratePassword = () => {
    setValue(
      'owner.password',
      passwordGenerator.generate({ length: 10, numbers: true }),
    );
  };

  return (
    <>
      <Stack direction={['column', 'column', 'row']} spacing={4}>
        <FormInput
          mb={6}
          name="owner.firstName"
          label="Owner First Name"
          error={get(errors, 'owner.firstName')}
          register={register}
        />
        <FormInput
          mb={6}
          name="owner.lastName"
          label="Owner Last Name"
          error={get(errors, 'owner.lastName')}
          register={register}
        />
      </Stack>
      <Stack direction={['column', 'column', 'row']} spacing={4} align="start">
        <FormInput
          mb={6}
          name="owner.email"
          label="Owner Email"
          error={get(errors, 'owner.email')}
          register={register}
        />
        <FormInput
          mb={6}
          name="owner.phone"
          label="Owner Phone"
          error={get(errors, 'owner.phone')}
          register={register}
        />
        <FormInput
          mb={6}
          label="Owner Password"
          error={get(errors, 'owner.password')}
        >
          <InputGroup>
            <Input {...register('owner.password')} />
            <InputRightAddon
              children={<Key size={16} />}
              _hover={{ cursor: 'pointer' }}
              onClick={_handleAutoGeneratePassword}
            />
          </InputGroup>
        </FormInput>
      </Stack>
    </>
  );
};

/* Export
============================================================================= */
export default RestaurantFormOwner;
