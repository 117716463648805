import moment from 'moment';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { getCoupon } from '../../../entities/redux/selectors';

/* =============================================================================
<CouponForm />
============================================================================= */
const CouponForm = ({ coupon, setValue }) => {
  const type = coupon?.type;
  const name = coupon?.name;
  const about = coupon?.about;
  const amount = coupon?.amount;
  const minAmount = coupon?.minAmount;
  const isFixed = coupon?.isFixed;
  const maxUses = coupon?.maxUses;
  const maxUsesUser = coupon?.maxUsesUser;
  const startsAt = coupon?.startsAt && moment(coupon.startsAt).format('YYYY-MM-DD');
  const expiresAt = coupon?.expiresAt && moment(coupon.expiresAt).format('YYYY-MM-DD');
  const restaurant = coupon?.restaurant?.id;
  const restaurantName = coupon?.restaurant?.name;

  useEffect(() => {
    if (type) {
      setValue('type', type);
    }
    if (name) {
      setValue('name', name);
    }
    if (about) {
      setValue('about', about);
    }
    if (amount) {
      setValue('amount', amount);
    }
    if (minAmount) {
      setValue('minAmount', minAmount);
    }
    if (typeof isFixed === 'boolean') {
      setValue('isFixed', isFixed);
    }
    if (maxUses) {
      setValue('maxUses', maxUses);
    }
    if (maxUsesUser) {
      setValue('maxUsesUser', maxUsesUser);
    }
    if (startsAt) {
      setValue('startsAt', startsAt);
    }
    if (expiresAt) {
      setValue('expiresAt', expiresAt);
    }
    if (restaurant) {
      setValue('restaurant', restaurant);
    }
    if (restaurantName) {
      setValue('restaurantName', restaurantName);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    type,
    name,
    about,
    amount,
    minAmount,
    isFixed,
    maxUses,
    maxUsesUser,
    startsAt,
    expiresAt,
    restaurant,
    restaurantName,
  ]);

  return null;
};

const mapStateToProps = (state, {id}) => ({
  coupon: getCoupon(state, {id}),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(CouponForm);
