import React, { useCallback, useState } from 'react';
import { HStack } from '@chakra-ui/react';

import DiscountsList from '../components/DiscountList/index';
import DiscountDishesList from '../components/DiscountDishesList/index';

/* =============================================================================
<DiscountScreen />
============================================================================= */
const DiscountScreen = () => {
  const [selectedDiscount, setSelectedDiscount] = useState(null);

  const _handleItemClick = useCallback((e, id) => {
    setSelectedDiscount(id);
  }, []);

  return (
    <HStack flex={1} align="stretch" overflowY="hidden" spacing={5}>
      <DiscountsList selected={selectedDiscount} onItemSelect={_handleItemClick} />
      <DiscountDishesList id={selectedDiscount} />
    </HStack>
  );
};

/* Export
============================================================================= */
export default DiscountScreen;
