import React from 'react';
import {
  Radio,
  RadioGroup,
  FormControl,
  VStack,
  HStack,
  Button,
} from '@chakra-ui/react';
import { connect } from 'react-redux';

import { getLoading } from '../../../redux/selectors';

/* =============================================================================
<NotificationDelivery />
============================================================================= */
const NotificationDelivery = ({ loading }) => (
  <HStack>
    {/* <FastField name="to"> */}
    {/* {({ form }) => ( */}
    <FormControl>
      <RadioGroup value="All Users" mt={3} size="lg" colorScheme="purple">
        <VStack align="flex-start">
          <Radio value="All Users">All Users in Food Street</Radio>
          <Radio value="Specific Region">Pick Users from specific region</Radio>
        </VStack>
      </RadioGroup>
      {/* <FormErrorMessage>
      <FormErrorIcon />
      {form.errors.screen}
    </FormErrorMessage> */}
    </FormControl>
    {/* )} */}
    {/* </FastField> */}
    <Button
      size="lg"
      type="submit"
      colorScheme="green"
      isLoading={loading}
    >
      Finish
    </Button>
  </HStack>
);

const mapStateToProps = (state) => ({
  loading: getLoading(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(NotificationDelivery);
