import * as constants from './constants';

export const INITIAL_STATE = {
  categories: [],
  restaurants: {},
  specialCategory: null,
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // CATEGORIES_GET
    case constants.CATEGORIES_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.CATEGORIES_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.CATEGORIES_GET.SUCCESS:
      return {
        ...state,
        error: null,
        categories: payload,
      };
    case constants.CATEGORIES_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // CATEGORY_CREATE
    case constants.CATEGORY_CREATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.CATEGORY_CREATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.CATEGORY_CREATE.SUCCESS:
      return {
        ...state,
        error: null,
        categories: [...state.categories, payload],
      };
    case constants.CATEGORY_CREATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // CATEGORY_UPDATE
    case constants.CATEGORY_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.CATEGORY_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.CATEGORY_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.CATEGORY_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

      // CATEGORY_DELETE
    case constants.CATEGORY_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.CATEGORY_DELETE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.CATEGORY_DELETE.SUCCESS:
      return {
        ...state,
        error: null,
        categories: state.categories.filter((category) => category !== payload),
      };
    case constants.CATEGORY_DELETE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // CATEGORIES_UPDATE
    case constants.CATEGORIES_UPDATE:
      return {
        ...state,
        categories: payload,
      };

    // RESTAURANTS_GET
    case constants.RESTAURANTS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANTS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANTS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        restaurants: {
          ...state.restaurants,
          [payload.category]: payload.restaurants,
        },
      };
    case constants.RESTAURANTS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // RESTAURANTS_ADD
    case constants.RESTAURANTS_ADD.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANTS_ADD.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANTS_ADD.SUCCESS:
      return {
        ...state,
        error: null,
        restaurants: {
          ...state.restaurants,
          [payload.category]: [...new Set([
            ...state.restaurants[payload.category]?.restaurants,
            ...payload.restaurants,
          ])],
        },
      };
    case constants.RESTAURANTS_ADD.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // RESTAURANT_UPDATE
    case constants.RESTAURANT_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANT_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANT_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.RESTAURANT_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

      // RESTAURANT_REMOVE
    case constants.RESTAURANT_REMOVE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANT_REMOVE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANT_REMOVE.SUCCESS:
      return {
        ...state,
        error: null,
        restaurants: {
          ...state.restaurants,
          [payload.category]: state.restaurants[payload.category]
            .filter((restaurant) => restaurant !== payload.restaurant),
        },
      };
    case constants.RESTAURANT_REMOVE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // RESTAURANTS_UPDATE
    case constants.RESTAURANTS_UPDATE:
      return {
        ...state,
        restaurants: {
          ...state.restaurants,
          [payload.category]: payload.restaurants,
        },
      };

    // SPECIAL_CATEGORY_GET
    case constants.SPECIAL_CATEGORY_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.SPECIAL_CATEGORY_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.SPECIAL_CATEGORY_GET.SUCCESS:
      return {
        ...state,
        error: null,
        specialCategory: payload,
      };
    case constants.SPECIAL_CATEGORY_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
