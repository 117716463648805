import React from 'react';
import { connect } from 'react-redux';
import {
  Text,
  Button,
  HStack,
  Heading,
  Box,
} from '@chakra-ui/react';

import { getWallet } from '../../../entities/redux/selectors';


/* =============================================================================
<WalletTransactionsHeader />
============================================================================= */
const WalletTransactionsHeader = ({id, wallet, onEditClick}) => {
  const phone = wallet?.phone;
  const amount = wallet?.amount?.toFixed(2);

  const _handleEditClick = () => {
    onEditClick(id);
  };

  return (
    <HStack justifyContent="space-between" p={6}>
      <Box>
        <Heading fontSize="4xl" fontWeight="normal">Transactions</Heading>
        <Text>{phone}</Text>
      </Box>
      <HStack>
        <Text>{`Rs.${amount}`}</Text>
        <Button
          variant="outline"
          colorScheme="purple"
          onClick={_handleEditClick}
        >
          Edit
        </Button>
      </HStack>
    </HStack>
  );
};

const mapStateToProps = (state, {id}) => ({
  wallet: getWallet(state, {id, normalized: true,}),
});

const propsAreEqual = (prevProps, nextProps) => prevProps.id === nextProps.id
  && prevProps.wallet?.phone === nextProps.wallet?.phone
  && prevProps.wallet?.amount === nextProps.wallet?.amount;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(WalletTransactionsHeader, propsAreEqual));
