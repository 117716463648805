import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerFooter,
} from '@chakra-ui/react';

import OrderDetailsHeader from './OrderDetailsHeader';
import OrderDetailsNotes from './OrderDetailsNotes';
import OrderDetailsTiming from './OrderDetailsTiming';
import OrderDetailsStatus from './OrderDetailsStatus';
import OrderDetailsDishes from './OrderDetailsDishes/index';
import OrderDetailsPartner from './OrderDetailsPartner';
import OrderDetailsTracking from './OrderDetailsTracking';
import OrderDetailsUserInfo from './OrderDetailsUserInfo';
import OrderDetailsRiderInfo from './OrderDetailsRiderInfo';

/* =============================================================================
<OrderDetailsModal />
============================================================================= */
const OrderDetailsModal = ({
  id,
  onClose,
  isOpen,
  footer,
}) => (
  <Drawer size="lg" isOpen={isOpen} placement="right" onClose={onClose}>
    <DrawerOverlay />
    <DrawerContent>
      <OrderDetailsHeader onClose={onClose} />
      <DrawerBody px={[3, 3, 5]} py={[3, 3, 5]}>
        <OrderDetailsPartner id={id} />
        <OrderDetailsTiming id={id} />
        <OrderDetailsStatus id={id} />
        <OrderDetailsUserInfo id={id} />
        <OrderDetailsDishes id={id} />
        <OrderDetailsNotes id={id} />
        <OrderDetailsRiderInfo id={id} />
        <OrderDetailsTracking id={id} />
      </DrawerBody>
      <DrawerFooter>
        {footer}
      </DrawerFooter>
    </DrawerContent>
  </Drawer>
);

/* Export
============================================================================= */
export default OrderDetailsModal;
