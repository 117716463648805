import { combineReducers } from 'redux';

import AuthReducer from '../auth/redux/reducer';
import DealsReducer from '../deals/redux/reducer';
import PostsReducer from '../posts/redux/reducer';
import OrdersReducer from '../orders/redux/reducer';
import StreetReducer from '../street/redux/reducer';
import CouponsReducer from '../coupons/redux/reducer';
import QRCodesReducer from '../qrcodes/redux/reducer';
import WalletsReducer from '../wallets/redux/reducer';
import EntitiesReducer from '../entities/redux/reducer';
import DiscountsReducer from '../discounts/redux/reducer';
import LiveOrdersReducer from '../liveOrders/redux/reducer';
import RestaurantsReducer from '../restaurants/redux/reducer';
import NotificationReducer from '../notification/redux/reducer';

/* ============================================================================
  Combine ALl Reducers
============================================================================= */
const rootReducer = combineReducers({
  Auth: AuthReducer,
  Deals: DealsReducer,
  Posts: PostsReducer,
  Orders: OrdersReducer,
  Street: StreetReducer,
  Coupons: CouponsReducer,
  QRCodes: QRCodesReducer,
  Wallets: WalletsReducer,
  Entities: EntitiesReducer,
  Discounts: DiscountsReducer,
  LiveOrders: LiveOrdersReducer,
  Restaurants: RestaurantsReducer,
  Notification: NotificationReducer,
});

export default rootReducer;
