import React from 'react';
import {
  HStack,
  Divider,
} from '@chakra-ui/react';

import Card from '../../../common/Card';
import LiveOrdersCardId from './LiveOrdersCardId';
import LiveOrdersCardUser from './LiveOrdersCardUser';
import LiveOrdersCardNotes from './LiveOrdersCardNotes';
import LiveOrdersCardRider from './LiveOrdersCardRider';
import LiveOrderCardPartner from './LiveOrderCardPartner';
import LiveOrdersCardDishes from './LiveOrdersCardDishes';
import LiveOrdersCardAddress from './LiveOrdersCardAddress';
import LiveOrdersCardCompletedInfo from './LiveOrdersCardCompletedInfo';

/* =============================================================================
<LiveOrdersCard />
============================================================================= */
const LiveOrdersCard = ({
  id,
  footer,
  onDetailsClick,
}) => (
  <Card p={5} mb={4}>
    <LiveOrderCardPartner id={id} />
    <LiveOrdersCardId id={id} />
    <LiveOrdersCardUser id={id} onDetailsClick={onDetailsClick} />
    <LiveOrdersCardAddress id={id} />
    <LiveOrdersCardDishes id={id} />
    <LiveOrdersCardNotes id={id} />
    <Divider my={3} />
    <LiveOrdersCardRider id={id} />
    <LiveOrdersCardCompletedInfo id={id} />
    <HStack justify="flex-end">
      {footer}
    </HStack>
  </Card>
);

/* Export
============================================================================= */
export default LiveOrdersCard;
