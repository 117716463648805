import React from 'react';
import { FastField } from 'formik';
import {
  Radio,
  VStack,
  FormLabel,
  RadioGroup,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<DealView />
============================================================================= */
const DealView = () => (
  <FastField name="view">
    {({ form }) => (
      <FormControl isInvalid={form.errors.view && form.touched.view}>
        <FormLabel fontSize="16px" fontWeight="normal">View</FormLabel>
        <RadioGroup
          colorScheme="purple"
          value={form.values.view}
          onChange={form.handleChange('view')}
        >
          <VStack alignItems="flex-start">
            <Radio value="hs">Horizontal Small</Radio>
            <Radio value="hb">Horizontal Big</Radio>
            <Radio value="v">Vertical</Radio>
          </VStack>
        </RadioGroup>
        <FormErrorMessage>
          <FormErrorIcon />
          {form.errors.view}
        </FormErrorMessage>
      </FormControl>
    )}
  </FastField>
);

/* Export
============================================================================= */
export default DealView;
