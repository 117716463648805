import * as constants from './constants';

export const INITIAL_STATE = {
  notifications: [],
  nextCursor: '',
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // NOTIFICATIONS_GET
    case constants.NOTIFICATIONS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.NOTIFICATIONS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.NOTIFICATIONS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        notifications: [...new Set([...state.notifications, ...payload.data])],
        nextCursor: payload.nextCursor,
      };
    case constants.NOTIFICATIONS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // NOTIFICATIONS_REFRESH
    case constants.NOTIFICATIONS_REFRESH.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.NOTIFICATIONS_REFRESH.FAIL:
      return {
        ...state,
        error,
      };
    case constants.NOTIFICATIONS_REFRESH.SUCCESS:
      return {
        ...state,
        error: null,
        notifications: payload.data,
        nextCursor: payload.nextCursor,
      };
    case constants.NOTIFICATIONS_REFRESH.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // NOTIFICATION_CREATE
    case constants.NOTIFICATION_CREATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.NOTIFICATION_CREATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.NOTIFICATION_CREATE.SUCCESS:
      return {
        ...state,
        error: null,
        notifications: [payload, ...state.notifications],
      };
    case constants.NOTIFICATION_CREATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

      // NOTIFICATION_DELETE
    case constants.NOTIFICATION_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.NOTIFICATION_DELETE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.NOTIFICATION_DELETE.SUCCESS:
      return {
        ...state,
        error: null,
        notifications: state.notifications.filter((id) => id !== payload),
      };
    case constants.NOTIFICATION_DELETE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
