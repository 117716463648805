import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Box, Text, Divider, useToast } from '@chakra-ui/react';

import { Card } from '../../common';
import RestaurantForm from '../components/RestaurantForm';
import { uploadRestaurantPhoto } from '../../util/cloudinary';

import { createRestaurant as createRestaurantAction } from '../redux/actions';

/* =============================================================================
<AddRestaurantScreen />
============================================================================= */
const AddRestaurantScreen = ({ createRestaurant }) => {
  const toast = useToast();
  const history = useHistory();

  const _handleSubmit = (form) => async (values) => {
    try {
      const restaurant = {
        ...values,
        owner: { ...values.owner, email: values.owner.email.toLowerCase() },
        categories: [values.category1],
      };
      if (values.photo) {
        const photoURL = await uploadRestaurantPhoto(values.photo);
        restaurant.photo = photoURL;
      }
      if (values.category2) {
        restaurant.categories.push(values.category2);
      }
      if (values.category3) {
        restaurant.categories.push(values.category3);
      }
      await createRestaurant(restaurant, (err) => {
        if (err) {
          toast({
            title: 'Error occured.',
            description: err?.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        } else {
          form.reset();
          toast({
            title: 'Restaurant created.',
            description: 'Restaurant created successfully.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          history.goBack();
        }
      });
    } catch (e) {
      toast({
        title: 'Error occured.',
        description: e?.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Card layerStyle="street.card">
      <Text textStyle="street.title" mx={5} align="left">
        Restaurant Information
      </Text>
      <Divider mt={4} />
      <Box layerStyle="street.list">
        <RestaurantForm
          isNew
          validationSchema={AddRestaurantSchema}
          onSubmit={_handleSubmit}
        />
      </Box>
    </Card>
  );
};

const AddRestaurantSchema = Yup.object().shape({
  owner: Yup.object().shape({
    firstName: Yup.string().required('Must not be empty!'),
    lastName: Yup.string().required('Must not be empty!'),
    email: Yup.string()
      .email('Must be a valid email')
      .required('Must not be empty!'),
    phone: Yup.string()
      .matches(
        /^\+[1-9]\d{10,14}$/,
        'Must be a valid phone number in E164 format!',
      )
      .required('Must not be empty!'),
    password: Yup.string()
      .min(8, 'Must be at least 8 characters and contain one digit!')
      .max(25, 'Must not exceed 25 characters!')
      .required('Must not be empty!'),
  }),
  name: Yup.string().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.string().required('Must not be empty!'),
  }),
  email: Yup.string().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.string()
      .email('Must be a valid email')
      .required('Must not be empty!'),
  }),
  about: Yup.string().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.string().required('Must not be empty!'),
  }),
  vatExclusive: Yup.boolean().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.boolean().required('Must not be empty'),
  }),
  address: Yup.object()
    .when('type', {
      is: (v) => v === 'single' || v === 'chain',
      then: Yup.object().shape({
        text: Yup.string().required('Must not be empty!'),
        city: Yup.string().required('Must not be empty!'),
        country: Yup.string().required('Must not be empty!'),
        postcode: Yup.string().required('Must not be empty!'),
      }),
    })
    .when('type', {
      is: 'branch',
      then: Yup.object().shape({
        text: Yup.string().required('Must not be empty!'),
        branch: Yup.string().required('Must not be empty!'),
        city: Yup.string().required('Must not be empty!'),
        country: Yup.string().required('Must not be empty!'),
        postcode: Yup.string().required('Must not be empty!'),
      }),
    }),
  location: Yup.array()
    .of(Yup.number())
    .length(2, 'Please place a marker at the desired location!')
    .required('Must not be empty!'),
  photo: Yup.mixed().optional(),
  type: Yup.string().required('Must not be empty!'),
  chain: Yup.string().when('type', {
    is: 'branch',
    then: Yup.string().required('Must not be empty!'),
  }),
  website: Yup.string().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.string().url().optional(),
  }),
  phone: Yup.object().shape({
    delivery1: Yup.string()
      .matches(
        /^\+[1-9]\d{10,14}$/,
        'Must be a valid phone number in E164 format!',
      )
      .required('Must not be empty!'),
    delivery2: Yup.string()
      .matches(/^\+[1-9]\d{10,14}$/, {
        message: 'Must be a valid phone number in E164 format!',
        excludeEmptyString: true,
      })
      .optional(),
    branch: Yup.string()
      .matches(
        /^\+[1-9]\d{10,14}$/,
        'Must be a valid phone number in E164 format!',
      )
      .required('Must not be empty!'),
  }),
  category1: Yup.string().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.string().required('Must not be empty!'),
  }),
  category2: Yup.string().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.string().when('category1', {
      is: (v) => v,
      then: Yup.string()
        .notOneOf([Yup.ref('category1')], 'Please select a different category!')
        .optional(),
      otherwise: Yup.string().optional(),
    }),
  }),
  category3: Yup.string().when('type', {
    is: (v) => v !== 'branch',
    then: Yup.string()
      .when('category1', {
        is: (v) => v,
        then: Yup.string()
          .notOneOf(
            [Yup.ref('category1')],
            'Please select a different category!',
          )
          .optional(),
        otherwise: Yup.string().optional(),
      })
      .when('category2', {
        is: (v) => v,
        then: Yup.string()
          .notOneOf(
            [Yup.ref('category2')],
            'Please select a different category!',
          )
          .optional(),
        otherwise: Yup.string().optional(),
      }),
  }),
});

const mapDispatchTopProps = {
  createRestaurant: createRestaurantAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchTopProps)(AddRestaurantScreen);
