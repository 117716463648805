import React, { useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { connect } from 'react-redux';

import Card from '../../common/Card';
import NotificationTable from '../components/NotificationTable';
import NotificationHeader from '../components/NotificationHeader';
import NotificationFooter from '../components/NotificationFooter';
import usePagination from '../../hooks/use-pagination';

import { getNextCursor, getNotifications as selectNotifications } from '../redux/selectors';
import {
  getNotifications as getNotificationsAction,
  refreshNotifications as refreshNotificationsAction,
} from '../redux/actions';

/* =============================================================================
<NotificationScreen />
============================================================================= */
const NotificationScreen = ({
  nextCursor,
  notifications,
  getNotifications,
  refreshNotifications,
}) => {
  const {
    next,
    prev,
    jumpTo,
    items,
    canNext,
    canPrev,
  } = usePagination(notifications);

  // Get notifications
  useEffect(() => {
    refreshNotifications();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleNext = () => {
    if (canNext) {
      next();
      return;
    }

    if (nextCursor) {
      getNotifications(nextCursor, (err) => {
        if (!err) {
          next(notifications);
        }
      });
    }
  };

  const _handlePrev = () => {
    if (canPrev) {
      prev();
    }
  };

  const _handleRefresh = () => {
    refreshNotifications(() => {
      jumpTo(1);
    });
  };

  return (
    <Card as={Flex} flex={1} overflow="hidden" direction="column" p={0}>
      <NotificationHeader onRefresh={_handleRefresh} />
      <NotificationTable data={items} />
      <NotificationFooter
        canNext={canNext || !!nextCursor}
        canPrev={canPrev}
        onNext={_handleNext}
        onPrev={_handlePrev}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  notifications: selectNotifications(state),
  nextCursor: getNextCursor(state),
});

const mapDispatchToProps = {
  getNotifications: getNotificationsAction,
  refreshNotifications: refreshNotificationsAction,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.nextCursor === nextProps.nextCursor
  && prevProps.notifications.toString() === nextProps.notifications.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(NotificationScreen, propsAreEqual));
