import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import {
  Box,
  Text,
  Input,
  Button,
  Center,
  Divider,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';

import Card from '../../common/Card';
import { uploadCategoryPhoto } from '../../util/cloudinary';

import { createCategory as createCategoryAction } from '../redux/actions';

/* =============================================================================
<StreetCreateCategories />
============================================================================= */
const StreetCreateCategories = ({ createCategory }) => {
  const toast = useToast();
  const fileInput = useRef();

  const _handlePhotoChange = (form) => (event) => {
    form.setFieldValue('photo', event.target.files[0]);
  };

  const _handleSubmit = async (values, { resetForm }) => {
    const category = {
      name: values.category,
    };
    if (values.photo) {
      const photoURL = await uploadCategoryPhoto(values.photo);
      category.photo = photoURL;
    }
    await createCategory(category, (err) => {
      if (err) {
        toast({
          title: 'Error occured.',
          description: err?.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } else {
        resetForm();
        fileInput.current.value = '';
        toast({
          title: 'Category created.',
          description: 'Category created successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Card layerStyle="street.card" w="100%" flex={1.5}>
      <Text textStyle="street.title">
        Create Categories
      </Text>
      <Divider mt={4} />
      <Box layerStyle="street.list">
        <Formik
          initialValues={{ category: '' }}
          validationSchema={AddCategorySchema}
          onSubmit={_handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field name="category">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.category && form.touched.category} mb={4}>
                    <FormLabel htmlFor="category">Enter Category</FormLabel>
                    <Input
                      {...field}
                      id="category"
                    />
                    <FormErrorMessage>
                      <FormErrorIcon />
                      {form.errors.category}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="photo">
                {({ form }) => (
                  <FormControl isInvalid={form.errors.photo && form.touched.photo} mb={6}>
                    <FormLabel>Upload Photo</FormLabel>
                    <Input
                      ref={fileInput}
                      id="photo"
                      type="file"
                      accept="image/*"
                      onChange={_handlePhotoChange(form)}
                    />
                    <FormErrorMessage>
                      <FormErrorIcon />
                      {form.errors.photo}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Center my={4}>
                <Button
                  type="submit"
                  colorScheme="purple"
                  isLoading={isSubmitting}
                >
                  Create
                </Button>
              </Center>
            </Form>
          )}
        </Formik>
      </Box>
    </Card>
  );
};

const AddCategorySchema = Yup.object().shape({
  category: Yup.string()
    .required('Must not be empty!'),
  photo: Yup.mixed()
    .optional(),
});

const mapDispatchTopProps = {
  createCategory: createCategoryAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchTopProps)(StreetCreateCategories);
