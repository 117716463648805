import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Heading, useToast } from '@chakra-ui/react';

import {Card} from '../../common';
import CouponForm from '../components/CouponForm';

import {
  getCoupon as getCouponAction,
  updateCoupon as updateCouponAction,
} from '../redux/actions';

/* =============================================================================
<EditCouponScreen />
============================================================================= */
const EditCouponScreen = ({getCoupon, updateCoupon}) => {
  const toast = useToast();
  const {id} = useParams();

  // Get coupon
  useEffect(() => {
    if (id) {
      getCoupon(id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const _handleSubmit = () => async (values) => {
    await updateCoupon({...values, id}, (err) => {
      if (err) {
        toast({
          title: 'Error',
          status: 'error',
          duration: 3000,
          isClosable: true,
          description: err.message,
        });
      } else {
        toast({
          title: 'Coupon updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
  <Card flex={1} overflow="auto">
    <Heading fontSize="4xl" fontWeight="semibold">Edit Coupon</Heading>
    <CouponForm id={id} onSubmit={_handleSubmit} />
  </Card>
);
  };

const mapDispatchToProps = {
  getCoupon: getCouponAction,
  updateCoupon: updateCouponAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(EditCouponScreen);
