import React from 'react';
import { Home } from 'react-feather';
import { NavLink, useLocation } from 'react-router-dom';
import { Heading, HStack, Text, Link } from '@chakra-ui/react';

/* =============================================================================
<NavHeader />
============================================================================= */
const NavHeader = () => {
  const location = useLocation();
  const paths = location.pathname.split('/').slice(1);

  return (
    <HStack px={8} pb={4}>
      <Heading textTransform="capitalize" mr={5}>
        {paths[0].replace('_', ' ')}
      </Heading>
      <Home size="1.5rem" />
      {paths.map((path, i) => {
        if (i === 0) {
          return (
            <Link key={path} as={NavLink} to={`/${paths[0]}`}>
              <Text px={4}>Home</Text>
            </Link>
          );
        }
        return (
          <Link
            key={path}
            as={NavLink}
            display="flex"
            to={`/${paths.slice(0, i + 1).join('/')}`}
          >
            <Text>-</Text>
            <Text px={3} textTransform="capitalize">
              {path}
            </Text>
          </Link>
        );
      })}
    </HStack>
  );
};

/* Export
============================================================================= */
export default NavHeader;
