import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  Flex,
  List,
  Text,
  Divider,
  Switch,
  FormControl,
  FormLabel,
  useToast,
} from '@chakra-ui/react';

import { Card } from '../../../common';
import StreetListFooter from '../StreetListFooter';
import StreetRestaurantsListItem from './StreetRestaurantsListItem';
import { reorder } from '../../../util/functions';

import { getRestaurantsByCategory } from '../../redux/selectors';
import {
  getRestaurants as getRestaurantsAction,
  updateCategory as updateCategoryAction,
  updateRestaurant as updateRestaurantAction,
  updateRestaurants as updateRestaurantsAction,
} from '../../redux/actions';
import { getCategory } from '../../../entities/redux/selectors';

/* =============================================================================
<StreetRestaurantsList />
============================================================================= */
const StreetRestaurantsList = ({
  categoryId,
  category,
  restaurants,
  getRestaurants,
  updateCategory,
  updateRestaurant,
  updateRestaurants,
}) => {
  const toast = useToast();
  const [items, setItems] = useState([]);
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const [streetVisible, setStreetVisible] = useState(false);

  // Get restaurants
  useEffect(() => {
    if (categoryId) {
      getRestaurants(categoryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  useEffect(() => {
    if (categoryId && category) {
      setStreetVisible(category.streetVisible);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  // Set restaurants
  useEffect(() => {
    setItems(restaurants);
  }, [restaurants]);

  const _updateRestaurant = (update, reordered) => {
    setSaving(true);
    updateRestaurant(categoryId, update, (err) => {
      if (!err) {
        updateRestaurants({
          category: categoryId,
          restaurants: reordered,
        });
      }
      setSaved(!err);
      setSaving(false);
    });
  };

  const _handleDragEnd = async (result) => {
    // Dropped outside the restaurants list
    if (result?.destination?.droppableId !== 'restaurants') {
      return;
    }

    // Dropped at the same index
    if (result.source.index === result.destination.index) {
      return;
    }

    const id = items[result.source.index];
    const reordered = reorder(
      items,
      result.source.index,
      result.destination.index,
    );
    setItems(reordered);
    _updateRestaurant(
      {
        id,
        rank: result.destination.index + 1,
      },
      reordered,
    );
  };

  const onChangeStreetVisibility = (isChecked) => {
    setStreetVisible(isChecked);
    updateCategory({ id: categoryId, streetVisible: isChecked }, (err) => {
      if (err) {
        toast({
          title: 'An error occurred',
          status: 'error',
        });
      } else {
        toast({
          title: 'Changes saved',
          status: 'success',
        });
      }
    });
  };

  return (
    <Card layerStyle="street.card" flex={2}>
      <Flex px={5} justify="space-between">
        <Text flex={1} textAlign="left" textStyle="street.title">
          Restaurants{category && `: ${category.name}`}
        </Text>
        {category && (
          <FormControl width="unset" display="flex" alignItems="center">
            <FormLabel htmlFor="streetVisible" mb="0">
              Visible on street?
            </FormLabel>
            <Switch
              colorScheme="purple"
              id="streetVisible"
              isChecked={streetVisible}
              onChange={(e) => onChangeStreetVisibility(e.target.checked)}
            />
          </FormControl>
        )}
      </Flex>
      <Divider mt={4} />
      <DragDropContext onDragEnd={_handleDragEnd}>
        <Droppable droppableId="restaurants">
          {(provided, snapshot) => (
            <List
              ref={provided.innerRef}
              {...provided.droppableProps}
              layerStyle="street.list"
              bg={snapshot.isDraggingOver ? 'blue.50' : 'inherit'}
            >
              {items.map((item, index) => (
                <StreetRestaurantsListItem
                  id={item}
                  key={item}
                  index={index}
                  category={categoryId}
                />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      <StreetListFooter saving={saving} saved={saved} />
    </Card>
  );
};

const mapStateToProps = (state, { categoryId }) => ({
  restaurants: getRestaurantsByCategory(state, { category: categoryId }),
  category: getCategory(state, { id: categoryId }),
});

const mapDispatchTopProps = {
  getRestaurants: getRestaurantsAction,
  updateRestaurant: updateRestaurantAction,
  updateRestaurants: updateRestaurantsAction,
  updateCategory: updateCategoryAction,
};

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchTopProps,
)(StreetRestaurantsList);
