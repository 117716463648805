export default {
  baseStyle: {
    _focus: 'none',
  },
  sizes: {
    sm: {
      py: '20px',
      width: '100px',
    },
    xl: {
      py: '10px',
      width: '100%',
    },
  },
};
