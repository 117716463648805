import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
} from '@chakra-ui/react';

import PostsTableItem from './PostsTableItem';
import PostsTableHeadItem from './PostsTableHeadItem';

/* =============================================================================
<PostsTable />
============================================================================= */
const PostsTable = ({ data }) => (
  <Box flex={1} overflow="auto">
    <Table variant="simple" colorScheme="gray" size="lg">
      <Thead>
        <Tr>
          {columns.map(renderColumn)}
        </Tr>
      </Thead>
      <Tbody>
        {data.map(renderItem)}
      </Tbody>
    </Table>
  </Box>
);

const renderColumn = (item, i) => <PostsTableHeadItem key={`${i}`} title={item} />;
const renderItem = (item) => <PostsTableItem key={item} id={item} />;

const columns = [
  'User',
  'Post',
  'Time',
  'Tagged Restaurant',
  'Tagged Dish',
  'Published',
  'Details',
];

/* Export
============================================================================= */
export default PostsTable;
