import React, { useState } from 'react';
import { VStack, HStack } from '@chakra-ui/react';

import StreetCategoriesList from '../components/StreetCategoriesList';
import StreetRestaurantsList from '../components/StreetRestaurantsList';
import StreetCreateCategories from '../components/StreetCreateCategories';
import StreetSelectDeals from '../components/StreetSelectDeals';
import StreetSpecialBanner from '../components/StreetSpecialBanner';

/* =============================================================================
<StreetScreen />
============================================================================= */
const StreetScreen = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const _handleItemClick = (e, id) => {
    setSelectedCategory(id);
  };

  return (
    <HStack flex={1} align="stretch" overflowY="hidden" spacing={5}>
      <StreetCategoriesList
        selected={selectedCategory}
        onItemClick={_handleItemClick}
      />
      <StreetRestaurantsList categoryId={selectedCategory} />
      <VStack flex={1} direction="column" spacing={5}>
        <StreetCreateCategories />
        <StreetSelectDeals />
      </VStack>
      <StreetSpecialBanner />
    </HStack>
  );
};

/* Export
============================================================================= */
export default StreetScreen;
