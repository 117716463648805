import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Box,
  Text,
  Divider,
  HStack,
} from '@chakra-ui/react';

import { getPost } from '../../../entities/redux/selectors';

/* =============================================================================
<PostDetailsTimingAndVotes />
============================================================================= */
const PostDetailsTimingAndVotes = ({ post }) => {
  const votes = post?.votes;
  const dateAndTime = moment(post?.createdAt).format('MMMM Do YYYY, h:mm:ss A');

  return (
    <Box>
      <Text fontSize="lg" fontWeight="semibold">
        {dateAndTime}
      </Text>
      <Divider my={5} />
      <HStack justify="space-between">
        <Text fontSize="lg" fontWeight="semibold">Post</Text>
        <HStack spacing={16}>
          <Text fontSize="lg" fontWeight="semibold">{`Likes: ${votes.up} `}</Text>
          <Text fontSize="lg" fontWeight="semibold">{`Dislikes: ${votes.down} `}</Text>
        </HStack>
      </HStack>
      <Divider my={5} />
    </Box>
  );
};

const mapStateToProps = (state, { id }) => ({
  post: getPost(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.post?.createdAt === nextProps.post?.createdAt
  && prevProps.post?.votes?.up === nextProps.post?.votes?.up
  && prevProps.post?.votes?.down === nextProps.post?.votes?.down
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(PostDetailsTimingAndVotes, propsAreEqual));
