import React from 'react';
import { FastField } from 'formik';
import {
  Input,
  HStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<DiscountValueAndDate />
============================================================================= */
const DiscountValueAndDate = () => (
  <HStack spacing={5} align="flex-start">
    <FastField name="value">
      {({ field, form }) => (
        <FormControl mb={8} isInvalid={form.errors.value && form.touched.value}>
          <FormLabel fontSize="16px" fontWeight="normal">Value</FormLabel>
          <Input type="number" id="value" placeholder="Ex: 10" {...field} />
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.value}
          </FormErrorMessage>
        </FormControl>
      )}
    </FastField>
    <FastField name="startAt">
      {({ field, form }) => (
        <FormControl
          mb={8}
          isInvalid={form.errors.startAt && form.touched.startAt}
        >
          <FormLabel fontSize="16px" fontWeight="normal">Start Date</FormLabel>
          <Input id="startAt" type="date" {...field} />
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.startAt}
          </FormErrorMessage>
        </FormControl>
      )}
    </FastField>
    <FastField name="endAt">
      {({ field, form }) => (
        <FormControl
          mb={8}
          isInvalid={form.errors.endAt && form.touched.endAt}
        >
          <FormLabel fontSize="16px" fontWeight="normal">End Date</FormLabel>
          <Input id="endAt" type="date" {...field} />
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.endAt}
          </FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  </HStack>
);

/* Export
============================================================================= */
export default DiscountValueAndDate;
