import React from 'react';
import { FastField } from 'formik';
import {
  Input,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<CreateNotificationTitle />
============================================================================= */
const CreateNotificationTitle = () => (
  <FastField name="title">
    {({ field, form }) => (
      <FormControl isInvalid={form.errors.title && form.touched.title}>
        <FormLabel fontSize="16px" fontWeight="normal">Title</FormLabel>
        <Input w="500px" id="title" {...field} />
        <FormErrorMessage>
          <FormErrorIcon />
          {form.errors.title}
        </FormErrorMessage>
      </FormControl>
    )}
  </FastField>
);

/* Export
============================================================================= */
export default CreateNotificationTitle;
