import React from 'react';
import { connect } from 'react-redux';
import { Box, Tag, Flex, Text } from '@chakra-ui/react';

import { getOrder } from '../../../entities/redux/selectors';

/* =============================================================================
<LiveOrderCardPartner />
============================================================================= */
const LiveOrderCardPartner = ({ order }) => {
  const orderPartner = order?.partner;
  
  if (orderPartner) {
    const partnerId = order[orderPartner][IDS[orderPartner]];
    const partnerStatus = order[orderPartner]?.status;

    return (
      <Box mb={3}>
        <Flex align="center" justify="space-between">
          <Tag
            bg="green.500"
            variant="subtle"
            color="white"
            textTransform="capitalize"
          >
            {orderPartner}
          </Tag>
          {partnerStatus && (
            <Tag
              mr={-5}
              variant="subtle"
              colorScheme="green"
              textTransform="capitalize"
              borderRightRadius={0}
            >
              {STATUSES[orderPartner][partnerStatus] || 'Unknown'}
            </Tag>
          )}
        </Flex>
        {partnerId && (
          <Text fontSize="xs" color="green.700" mt={1}>{`#${partnerId}`}</Text>
        )}
      </Box>
    );
  }

  return null;
};

const STATUSES = {
  bykea: {
    created: 'Requested',
    accepted: 'Accepted',
    arrived: 'Arrived',
    started: 'Dispatched',
    opened: 'Requested',
    expired: 'Expired',
    finished: 'Finished',
    cancelled: 'Cancelled',
  },
};

const IDS = {
  bykea: 'bookingNo',
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.partner === nextProps.order?.partner
  && prevProps.order?.bykea?.status === nextProps.order?.bykea?.status
  && prevProps.order?.bykea?.bookingNo === nextProps.order?.bykea?.bookingNo
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(LiveOrderCardPartner, propsAreEqual));
