import * as constants from './constants';

export const INITIAL_STATE = {
  deals: [],
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // DEALS_GET
    case constants.DEALS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DEALS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DEALS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        deals: payload,
      };
    case constants.DEALS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // DEAL_GET
    case constants.DEAL_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DEAL_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DEAL_GET.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.DEAL_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // DEAL_CREATE
    case constants.DEAL_CREATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DEAL_CREATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DEAL_CREATE.SUCCESS:
      return {
        ...state,
        error: null,
        deals: [...state.deals, payload],
      };
    case constants.DEAL_CREATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // DEAL_UPDATE
    case constants.DEAL_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DEAL_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DEAL_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.DEAL_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // DEAL_DELETE
    case constants.DEAL_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DEAL_DELETE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DEAL_DELETE.SUCCESS:
      return {
        ...state,
        error: null,
        deals: state.deals.filter((deal) => deal !== payload),
      };
    case constants.DEAL_DELETE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // DEALS_UPDATE
    case constants.DEALS_UPDATE:
      return {
        ...state,
        deals: payload,
      };

    // DEAL_ITEM_CREATE
    case constants.DEAL_ITEM_CREATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DEAL_ITEM_CREATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DEAL_ITEM_CREATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.DEAL_ITEM_CREATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // DEAL_ITEM_DELETE
    case constants.DEAL_ITEM_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DEAL_ITEM_DELETE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DEAL_ITEM_DELETE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.DEAL_ITEM_DELETE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // DEAL_ON_STREET_UPDATE
    case constants.DEAL_ON_STREET_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DEAL_ON_STREET_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DEAL_ON_STREET_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.DEAL_ON_STREET_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
