import React from 'react';
import {
  Text,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
} from '@chakra-ui/react';

/* =============================================================================
<NotificationFormAccordionItem />
============================================================================= */
const NotificationFormAccordionItem = ({ title, panel }) => (
  <AccordionItem mb={5} border="none">
    <AccordionButton>
      <AccordionIcon w="30px" h="30px" />
      <Text ml={5} fontSize="lg" fontWeight="semibold">{title}</Text>
    </AccordionButton>
    <AccordionPanel p={8}>{panel}</AccordionPanel>
  </AccordionItem>
);

/* Export
============================================================================= */
export default NotificationFormAccordionItem;
