import React from 'react';
import {
  Box,
  Text,
  Avatar,
  HStack,
  Divider,
} from '@chakra-ui/react';
import { connect } from 'react-redux';
import { MapPin, Phone } from 'react-feather';

import { getOrder } from '../../entities/redux/selectors';

/* =============================================================================
<OrderDetailsUserInfo />
============================================================================= */
const OrderDetailsUserInfo = ({ order }) => {
  const type = order?.type;
  const isCustomer = Boolean(order?.customer);
  const username = isCustomer
    ? `${order.customer.profile.firstName} ${order.customer.profile.lastName}`
    : order?.meta?.name || '--';
  const avatar = order?.customer?.profile?.avatar;
  const phone = isCustomer ? order?.customer?.phone : order?.meta?.phone || '--';
  const address = type === 'delivery' && isCustomer ? order?.address?.text : '';
  const table = order?.table?.name;
  const comment = order?.payment?.comment;
  const waiter = order?.waiter?.profile?.firstName;

  return (
    <Box>
      <Divider my={5} />
      <Text my={2} fontSize="xs" display={['block', 'block', 'none']}>Customer Info</Text>
      <HStack justify={['space-between', 'space-between', 'flex-start']}>
        <HStack>
          <Avatar src={avatar} />
          <Box>
            <Text fontSize={['xs', 'xs', 'sm']}>{username}</Text>
            <HStack>
              <Phone size="15px" />
              <Text fontSize={['xs', 'xs', 'sm']}>{phone}</Text>
            </HStack>
          </Box>
        </HStack>
        {Boolean(address) && (
          <Box pl={5}>
            <Text fontSize={['xs', 'xs', 'sm']}>Address</Text>
            <HStack>
              <MapPin size="15px" />
              <Text fontSize={['xs', 'xs', 'sm']}>{address}</Text>
            </HStack>
          </Box>
        )}
      </HStack>
      {table && <Text mt={2}>Table: {table}</Text>}
      {waiter && <Text>Waiter: {waiter}</Text>}
      {comment && <Text>Comment: {comment}</Text>}
    </Box>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.address?.text === nextProps.order?.address?.text
    && prevProps.order?.customer?.profile?.firstName
    === nextProps.order?.customer?.profile?.firstName
    && prevProps.order?.customer?.profile?.lastName === nextProps.order?.customer?.profile?.lastName
    && prevProps.order?.customer?.profile?.avatar === nextProps.order?.customer?.profile?.avatar
    && prevProps.order?.customer?.phone === nextProps.order?.customer?.phone
    && prevProps.order?.meta?.name === nextProps.order?.meta?.name
    && prevProps.order?.meta?.phone === nextProps.order?.meta?.phone
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderDetailsUserInfo, propsAreEqual));
