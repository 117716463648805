import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Flex,
  Text,
  Divider,
  useToast,
} from '@chakra-ui/react';

import Card from '../../../common/Card';
import StreetSpecialBannerRestaurants from './StreetSpecialBannerRestaurants';
import StreetSpecialBannerAddRestaurant from './StreetSpecialBannerAddRestaurant';

import { getSpecialCategory as selectSpecialCategory } from '../../redux/selectors';
import {
  getSpecialCategory as getSpecialCategoryAction,
  updateCategory as updateCategoryAction,
} from '../../redux/actions';

/* =============================================================================
<StreetSpecialBanner />
============================================================================= */
const StreetSpecialBanner = ({ category, getSpecialCategory, updateCategory }) => {
  const toast = useToast();
  const [banner, setBanner] = useState(BANNERS[0].value);
  const restaurants = category && category[banner] ? category[banner] : [];

  // Get special category
  useEffect(() => {
    getSpecialCategory();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleAddRestaurant = async (value) => {
    if (category) {
      await updateCategory({
        id: category.id,
        [banner]: [...new Set([...category[banner], value])],
      }, (err) => {
        if (err) {
          toast({
            title: 'Error occured.',
            description: err?.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Restaurant added.',
            description: 'Restaurant added successfully.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        }
      });
    }
  };

  const _handleUpdateRestaurants = async (values) => {
    if (category) {
      await updateCategory({
        id: category.id,
        [banner]: values,
      }, (err) => {
        if (err) {
          toast({
            title: 'Error occured.',
            description: err?.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Special banner updated.',
            description: 'Special banner updated successfully.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        }
      });
    }
  };

  return (
    <Card layerStyle="street.card">
      <Text textStyle="street.title">
        Special Banner
      </Text>
      <Divider mt={4} />
      <Flex layerStyle="street.list" direction="column">
        <StreetSpecialBannerAddRestaurant
          bannerOptions={BANNERS}
          banner={banner}
          onBannerChange={setBanner}
          onAddRestaurant={_handleAddRestaurant}
        />
        <Divider w="auto" mx={-5} />
        <StreetSpecialBannerRestaurants
          restaurants={restaurants}
          onUpdateRestaurants={_handleUpdateRestaurants}
        />
      </Flex>
    </Card>
  );
};

const BANNERS = [
  {
    label: 'Featured Restaurant',
    value: 'featured',
  },
  {
    label: 'Newest Restaurant',
    value: 'newest',
  },
  {
    label: 'Trending Restaurant',
    value: 'trending',
  },
];

const mapStateToProps = (state) => ({
  category: selectSpecialCategory(state, { normalized: true }),
});

const mapDispatchTopProps = {
  getSpecialCategory: getSpecialCategoryAction,
  updateCategory: updateCategoryAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchTopProps)(StreetSpecialBanner);
