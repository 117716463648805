import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Tr,
  Td,
  Text,
  Switch,
  useToast,
  useDisclosure,
  Button,
} from '@chakra-ui/react';

import PostDetailModal from '../PostDetailsModal/index';

import { getPost } from '../../../entities/redux/selectors';
import { updatePost as updatePostAction } from '../../redux/actions';

/* =============================================================================
<PostsTableItem />
============================================================================= */
const PostsTableItem = ({ id, post, updatePost }) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [published, setPublished] = useState(false);

  const comment = post?.text;
  const restaurant = post?.restaurant;
  const dishes = post?.dishes;
  const time = moment(post?.createdAd).format('LT');
  const date = moment(post?.createdAd).format('L');
  const userName = `${post?.author?.profile?.firstName} ${post?.author?.profile?.lastName}`;

  // Set published
  useEffect(() => {
    setPublished(post?.published);
  }, [post?.published]);

  const _handleActiveChange = async () => {
    setPublished((prevState) => !prevState);
    updatePost({
      id,
      published: !published,
      restaurant: restaurant?.id,
    }, (err) => {
      if (err) {
        setPublished((prevState) => !prevState);
        toast({
          title: 'Error',
          description: err?.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Tr>
      <Td>{userName}</Td>
      <Td><Text noOfLines={2}>{comment}</Text></Td>
      <Td>
        {time}
        <br />
        {date}
      </Td>
      <Td>{restaurant?.name}</Td>
      <Td>{dishes?.map((dish) => dish.name)?.join(', ')}</Td>
      <Td>
        <Switch colorScheme="purple" isChecked={published} onChange={_handleActiveChange} />
      </Td>
      <Td>
        <Button variant="outline" onClick={onOpen}>Details</Button>
      </Td>
      <PostDetailModal id={id} isOpen={isOpen} onClose={onClose} />
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  post: getPost(state, { id }),
});

const mapDispatchToProps = {
  updatePost: updatePostAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.id === nextProps.id
  && prevProps.post?.published === nextProps.post?.published
  && prevProps.post?.votes?.up === nextProps.post?.votes?.up
  && prevProps.post?.votes?.down === nextProps.post?.votes?.down;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(PostsTableItem, propsAreEqual));
