import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { FastField, Form, Formik } from 'formik';
import {
  Modal,
  Input,
  Button,
  HStack,
  useToast,
  ModalBody,
  FormLabel,
  FormControl,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

import { createWallet as createWalletAction } from '../../redux/actions';

/* =============================================================================
<WalletHeaderAddWalletModal />
============================================================================= */
const WalletHeaderAddWalletModal = ({ isOpen, onClose, createWallet }) => {
  const toast = useToast();

  const _handleSubmit = async (values) => {
    await createWallet(values, (err) => {
      if (err) {
        toast({
          title: 'Error',
          duration: 3000,
          status: 'error',
          isClosable: true,
          description: err.message,
        });
      } else {
        toast({
          status: 'success',
          duration: 3000,
          isClosable: true,
          title: 'Wallet Created Successfully',
        });
        onClose();
      }
    });
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="20px">
        <ModalHeader fontSize="xl" textAlign="center">Create Wallet</ModalHeader>
        <ModalBody p={8}>
          <Formik
            initialValues={initialValues}
            validationSchema={DiscountCreateSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <FastField name="phone">
                  {({ field, form }) => (
                    <FormControl mb={8} isInvalid={form.errors.phone && form.touched.phone}>
                      <FormLabel fontSize="16px" fontWeight="normal">Phone Number</FormLabel>
                      <Input id="phone" placeholder="Enter Phone Number" {...field} />
                      <FormErrorMessage>
                        <FormErrorIcon />
                        {form.errors.phone}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </FastField>
                <FastField name="amount">
                  {({ field, form }) => (
                    <FormControl mb={8} isInvalid={form.errors.amount && form.touched.amount}>
                      <FormLabel fontSize="16px" fontWeight="normal">Initial Deposit</FormLabel>
                      <Input id="amount" placeholder="Enter Initial Deposit Value" {...field} />
                      <FormErrorMessage>
                        <FormErrorIcon />
                        {form.errors.amount}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </FastField>
                <HStack justifyContent="flex-end">
                  <Button
                    size="lg"
                    variant="outline"
                    colorScheme="purple"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="lg"
                    type="submit"
                    isLoading={isSubmitting}
                    colorScheme="purple"
                  >
                    Save
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const initialValues = {
  phone: '',
  amount: 0,
};

const DiscountCreateSchema = Yup.object().shape({
  phone: Yup.string()
    .required('Must not be empty'),
  amount: Yup.number()
    .required('Must not be empty'),
});

const mapDispatchToProps = {
  createWallet: createWalletAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(WalletHeaderAddWalletModal);
