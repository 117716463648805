import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import {
  Flex, HStack, Button,
} from '@chakra-ui/react';

/* =============================================================================
<OrdersFooter />
============================================================================= */
const OrdersFooter = ({
  canNext,
  canPrev,
  onNext,
  onPrev,
}) => (
  <HStack p={6} justify="flex-end" spacing={4}>
    <Flex
      as={Button}
      variant="unstyled"
      disabled={!canPrev}
      alignItems="center"
      justifyContent="center"
      border="1px"
      borderColor="#8898AA"
      borderRadius="full"
      onClick={onPrev}
    >
      <ChevronLeft color="#8898AA" />
    </Flex>
    <Flex
      as={Button}
      variant="unstyled"
      disabled={!canNext}
      alignItems="center"
      justifyContent="center"
      border="1px"
      borderColor="#8898AA"
      borderRadius="full"
      onClick={onNext}
    >
      <ChevronRight color="#8898AA" />
    </Flex>
  </HStack>
);

/* Export
============================================================================= */
export default OrdersFooter;
