import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Clock } from 'react-feather';
import {
  Box, HStack, Text,
} from '@chakra-ui/react';

import { getOrder } from '../../entities/redux/selectors';

import { calculateTimeDiff } from '../../util/functions';

/* =============================================================================
<OrderDetailsTiming />
============================================================================= */
const OrderDetailsTiming = ({ order }) => {
  const tag = order?.tag;
  const status = order?.status;
  const ongoing = status === 'pending' || status === 'accepted' || status === 'prepared';

  const now = moment();
  const createdAt = moment(order?.createdAt);
  const declinedAt = moment(order?.declinedAt);
  const canceledAt = moment(order?.canceledAt);
  const completedAt = moment(order?.completedAt);

  const ongoingSince = calculateTimeDiff(createdAt, now);
  const declinedIn = calculateTimeDiff(createdAt, declinedAt);
  const canceledIn = calculateTimeDiff(createdAt, canceledAt);
  const completedIn = calculateTimeDiff(createdAt, completedAt);

  return (
    <Box>
      <Text fontSize="lg" fontWeight="semibold">
        {`#${tag} - ${createdAt.format('MMMM Do YYYY, h:mm:ss A')}`}
      </Text>
      {status !== 'pending' && (
        <>
          <Text fontSize="sm" mt={1}>
            {ongoing && 'Ongoing Order'}
            {status === 'completed' && 'Order Completed In'}
            {status === 'declined' && 'Order Declined'}
            {status === 'canceled' && 'Order Canceled'}
          </Text>
          <HStack display={['none', 'none', 'flex']}>
            <Clock
              size="14px"
              color={(
                status === 'completed'
                  ? 'green'
                  : status === 'canceled' || status === 'declined'
                    ? 'red'
                    : 'orange'
              )}
            />
            <Text
              fontWeight="medium"
              color={status === 'completed' ? 'green'
                : status === 'canceled' ? 'red' : 'orange'}
            >
              {ongoing && ongoingSince}
              {status === 'completed' && completedIn}
              {status === 'declined' && declinedIn}
              {status === 'canceled' && canceledIn}
            </Text>
          </HStack>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.tag === nextProps.order?.tag
  && prevProps.order?.status === nextProps.order?.status
  && prevProps.order?.createdAt === nextProps.order?.createdAt
  && prevProps.order?.declinedAt === nextProps.order?.declinedAt
  && prevProps.order?.canceledAt === nextProps.order?.canceledAt
  && prevProps.order?.completedAt === nextProps.order?.completedAt
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderDetailsTiming, propsAreEqual));
