import React from 'react';
import { FastField } from 'formik';
import {
  Input,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<DealAspectRatio/>
============================================================================= */
const DealAspectRatio = () => (
  <FastField name="aspectRatio">
    {({ field, form }) => (
      <FormControl mb={8} isInvalid={form.errors.aspectRatio && form.touched.aspectRatio}>
        <FormLabel fontSize="16px" fontWeight="normal">Aspect Ratio</FormLabel>
        <Input
          type="number"
          id="aspectRatio"
          placeholder="ex. 2, 3"
          {...field}
        />
        <FormErrorMessage>
          <FormErrorIcon />
          {form.errors.aspectRatio}
        </FormErrorMessage>
      </FormControl>
    )}
  </FastField>
);

/* Export
============================================================================= */
export default DealAspectRatio;
