import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Stack } from '@chakra-ui/react';

import { FormSelect } from '../../../common';

import { getCategories as selectCategories } from '../../redux/selectors';
import { getCategories as getCategoriesAction } from '../../../street/redux/actions';

/* =============================================================================
<RestaurantFormCategories />
============================================================================= */
const RestaurantFormCategories = ({
  type,
  errors,
  categories,
  register,
  getCategories,
}) => {
  const isBranch = type === 'branch';
  const categoriesOptions = useMemo(() => categories
    .filter((category) => !category.special)
    .map((category) => ({
      label: category.name,
      value: category.id,
    })), [categories]);

  // Get categories
  useEffect(() => {
    getCategories();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isBranch) {
    return (
      <Stack direction={['column', 'column', 'row']} spacing={4} align="start">
        <FormSelect
          mb={6}
          name="category1"
          label="Primary Category"
          options={categoriesOptions}
          error={errors.category1}
          register={register}
        />
        <FormSelect
          mb={6}
          name="category2"
          label="Secondary Category"
          options={categoriesOptions}
          error={errors.category2}
          register={register}
        />
        <FormSelect
          mb={6}
          name="category3"
          label="Tertiary Category"
          options={categoriesOptions}
          error={errors.category3}
          register={register}
        />
      </Stack>
    );
  }

  return null;
};

const mapStateToProps = (state) => ({
  categories: selectCategories(state),
});

const mapDispatchTopProps = {
  getCategories: getCategoriesAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchTopProps)(RestaurantFormCategories);
