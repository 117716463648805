import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Text,
  HStack,
  Avatar,
} from '@chakra-ui/react';

import { getPost } from '../../../entities/redux/selectors';

/* =============================================================================
<PostDetailsRestaurantInfo />
============================================================================= */
const PostDetailsRestaurantInfo = ({ post }) => {
  const dishes = post?.dishes;
  const isBranch = post?.restaurant?.type === 'branch';
  const restaurantName = isBranch ? post?.restaurant?.chain?.name : post?.restaurant?.name;
  const restaurantPhoto = isBranch
    ? post?.restaurant?.chain?.photos && post?.restaurant?.chain?.photos[0]
    : post?.restaurant?.photos && post?.restaurant?.photos[0];

  return (
    <HStack mx={6} my={16} align="flex-start">
      <Box flex={1}>
        <Text fontSize="lg" fontWeight="semibold">Restaurant</Text>
        <Avatar mt={2} size="xl" src={restaurantPhoto} />
        <Text mt={2}>{restaurantName}</Text>
      </Box>
      <Box flex={1}>
        <Text fontSize="lg" fontWeight="semibold">Dishes</Text>
        <HStack>
          {dishes.map((dish) => (
            <Box textAlign="center">
              <Avatar mt={2} size="xl" src={dish?.photos && dish?.photos[0]} />
              <Text mt={2}>{dish?.name}</Text>
            </Box>
          ))}
        </HStack>
      </Box>
    </HStack>
  );
};

const mapStateToProps = (state, { id }) => ({
  post: getPost(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.post?.restaurant?.toString() === nextProps.post?.restaurant?.toString()
  && prevProps.post?.dishes.toString() === nextProps.post?.dishes.toString()
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(PostDetailsRestaurantInfo, propsAreEqual));
