import { schema } from 'normalizr';

export const role = new schema.Entity('roles');

export const address = new schema.Entity('addresses');

export const notification = new schema.Entity('notifications');

export const restaurant = new schema.Entity('restaurants');
restaurant.define({
  chain: restaurant,
});

export const user = new schema.Entity('users', {
  restaurant,
  role,
});
restaurant.define({
  owner: user,
});

export const category = new schema.Entity('categories', {
  featured: [restaurant],
  newest: [restaurant],
  trending: [restaurant],
});
restaurant.define({
  categories: [category],
});

export const choice = new schema.Entity('choices');

export const choiceGroup = new schema.Entity('choiceGroups', {
  choices: [choice],
});

export const dish = new schema.Entity('dishes', {
  choiceGroups: [choiceGroup],
  category,
  restaurant,
});

export const dealItem = new schema.Entity('dealItems', {
  dish,
  restaurant,
});

export const deal = new schema.Entity('deals', {
  items: [dealItem],
});

export const post = new schema.Entity('posts', {
  dishes: [dish],
  restaurant,
  author: user,
});

export const discount = new schema.Entity('discounts', {
  dishes: [dish],
});

export const order = new schema.Entity('orders', {
  rider: user,
  waiter: user,
  customer: user,
  restaurant,
});

export const coupon = new schema.Entity('coupons', {
  restaurant,
});

export const qrCode = new schema.Entity('qrCodes', {
  restaurant,
});

export const wallet = new schema.Entity('wallets');

export const walletTransaction = new schema.Entity('walletTransactions', {
  wallet,
});
