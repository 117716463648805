import { actionGenerator } from '../../util/reduxHelpers';

export const DEALS_GET = actionGenerator('DEALS/DEALS_GET');
export const DEAL_GET = actionGenerator('DEALS/DEAL_GET');
export const DEAL_CREATE = actionGenerator('DEALS/DEAL_CREATE');
export const DEAL_UPDATE = actionGenerator('DEALS/DEAL_UPDATE');
export const DEAL_DELETE = actionGenerator('DEALS/DEAL_DELETE');

export const DEALS_UPDATE = 'DEALS/DEALS_UPDATE';
export const DEAL_ITEM_CREATE = actionGenerator('DEALS/DEAL_ITEM_CREATE');
export const DEAL_ITEM_DELETE = actionGenerator('DEALS/DEAL_ITEM_DELETE');
export const DEAL_ON_STREET_UPDATE = actionGenerator('DEALS/DEAL_ON_STREET_UPDATE');
