/**
 * Get error
 */
export const getError = (state) => state.Notification.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.Notification.loading;

/**
 * Get notifications
 */
export const getNotifications = (state) => state.Notification.notifications;

/**
 * Get next cursor
 */
export const getNextCursor = (state) => state.Notification.nextCursor;
