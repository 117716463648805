import React from 'react';
import { Stack } from '@chakra-ui/react';

import { FormInput, FormSelect } from '../../../common';

/* =============================================================================
<RestaurantFormName />
============================================================================= */
const RestaurantFormName = ({ type, errors, register }) => {
  const isBranch = type === 'branch';

  if (!isBranch) {
    return (
      <>
        <Stack direction={['column', 'column', 'row']} spacing={4} align="start">
          <FormInput
            mb={6}
            name="name"
            label="Restaurant Name"
            error={errors.name}
            register={register}
          />
          <FormInput
            mb={6}
            name="email"
            label="Restaurant Email"
            error={errors.email}
            register={register}
          />
        </Stack>
        <Stack direction={['column', 'column', 'row']} spacing={4} align="start">
          <FormInput
            mb={6}
            name="about"
            label="Tag Line"
            error={errors.about}
            register={register}
          />
          <FormSelect
            mb={6}
            name="vatExclusive"
            label="GST / VAT Exclusive"
            error={errors.vatExclusive}
            selectProps={{ defaultValue: false }}
            options={[
              {
                label: 'No',
                value: false,
              },
              {
                label: 'Yes',
                value: true,
              },
            ]}
            register={register}
          />
        </Stack>
      </>
    );
  }

  return null;
};

/* Export
============================================================================= */
export default RestaurantFormName;
