import * as constants from './constants';

export const INITIAL_STATE = {
  type: 'all',
  posts: [],
  nextCursor: '',
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // TYPE_SET
    case constants.TYPE_SET:
      return {
        ...state,
        type: payload,
      };

    // POSTS_GET
    case constants.POSTS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.POSTS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.POSTS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        posts: [...new Set([...state.posts, ...payload.data])],
        nextCursor: payload.nextCursor,
      };
    case constants.POSTS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // POSTS_REFRESH
    case constants.POSTS_REFRESH.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.POSTS_REFRESH.FAIL:
      return {
        ...state,
        error,
      };
    case constants.POSTS_REFRESH.SUCCESS:
      return {
        ...state,
        error: null,
        posts: payload.data,
        nextCursor: payload.nextCursor,
      };
    case constants.POSTS_REFRESH.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // POST_GET
    case constants.POST_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.POST_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.POST_GET.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.POST_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

      // POST_UPDATE
    case constants.POST_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.POST_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.POST_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.POST_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
