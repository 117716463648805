import React, { useEffect, useState } from 'react';
import DropZone from 'react-dropzone';
import { useFormikContext } from 'formik';
import {
  Button,
  Center,
  Flex,
  Image,
  Input,
  Text,
  toast,
  VStack,
} from '@chakra-ui/react';

/* =============================================================================
<DealItemCreatePhotoPreview />
============================================================================= */
const DealItemCreatePhotoPreview = () => {
  const [photo, setPhoto] = useState(null);
  const { values, setFieldValue } = useFormikContext();
  const file = values?.photo;

  const _handleFileUpload = (files) => {
    setFieldValue('photo', files[0]);
  };

  const _handleDelete = () => {
    setPhoto(null);
    setFieldValue('photo', '');
  };

  // Read file
  useEffect(() => {
    if (file instanceof File) {
      const reader = new FileReader();

      reader.onabort = () => toast({
        title: 'file read was aboard.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      reader.onerror = () => toast({
        title: 'file read has failed.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      reader.onload = () => {
        const binaryStr = reader.result;
        setPhoto(binaryStr);
      };
      reader.readAsDataURL(file);
    } else {
      setPhoto(file);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  if (photo) {
    return (
      <VStack spacing={5}>
        <Image w="auto" h="170px" borderRadius={5} src={photo} />
        <Button variant="outline" onClick={_handleDelete}>
          Delete
        </Button>
      </VStack>
    );
  }

  return (
    <Center>
      <DropZone onDrop={_handleFileUpload}>
        {({ getRootProps, getInputProps }) => (
          <Flex
            p={4}
            w="340px"
            h="170px"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            borderRadius={5}
            bgColor="#EBE4E4"
            border="1px solid #d1d1d1"
            {...getRootProps()}
          >
            <Input {...getInputProps()} />
            <Text align="center" color="gray">
              Drag and Drop
              <br />
              or
              <br />
              Click to upload banner
            </Text>
          </Flex>
        )}
      </DropZone>
    </Center>
  );
};

/* Export
============================================================================= */
export default DealItemCreatePhotoPreview;
