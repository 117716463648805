import * as constants from './constants';

export const INITIAL_STATE = {
  discounts: [],
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // DISCOUNTS_GET
    case constants.DISCOUNTS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DISCOUNTS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DISCOUNTS_GET.SUCCESS:
      return {
        ...state,
        discounts: payload,
      };
    case constants.DISCOUNTS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // DISCOUNT_GET
    case constants.DISCOUNT_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DISCOUNT_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DISCOUNT_GET.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.DISCOUNT_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // DISCOUNT_CREATE
    case constants.DISCOUNT_CREATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DISCOUNT_CREATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DISCOUNT_CREATE.SUCCESS:
      return {
        ...state,
        error: null,
        discounts: [...state.discounts, payload],
      };
    case constants.DISCOUNT_CREATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // DISCOUNT_UPDATE
    case constants.DISCOUNT_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DISCOUNT_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DISCOUNT_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.DISCOUNT_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

      // DISCOUNT_DELETE
    case constants.DISCOUNT_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DISCOUNT_DELETE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DISCOUNT_DELETE.SUCCESS:
      return {
        ...state,
        error: null,
        discounts: state.discounts.filter((discount) => discount !== payload),
      };
    case constants.DISCOUNT_DELETE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

      // DISCOUNT_DISH_DELETE
    case constants.DISCOUNT_DISH_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DISCOUNT_DISH_DELETE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DISCOUNT_DISH_DELETE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.DISCOUNT_DISH_DELETE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
