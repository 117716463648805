import { actionGenerator } from '../../util/reduxHelpers';

export const RESTAURANTS_GET = actionGenerator(
  'RESTAURANTS/RESTAURANTS_GET',
);
export const RESTAURANTS_REFRESH = actionGenerator(
  'RESTAURANTS/RESTAURANTS_REFRESH',
);
export const RESTAURANT_GET = actionGenerator(
  'RESTAURANTS/RESTAURANT_GET',
);
export const RESTAURANT_CREATE = actionGenerator(
  'RESTAURANTS/RESTAURANT_CREATE',
);
export const RESTAURANT_UPDATE = actionGenerator(
  'RESTAURANTS/RESTAURANT_UPDATE',
);
export const RESTAURANT_DISHES_GET = actionGenerator(
  'RESTAURANTS/RESTAURANT_DISHES_GET',
);
