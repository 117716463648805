import React from 'react';
import { useFormikContext } from 'formik';
import DropZone from 'react-dropzone';
import {
  Flex,
  Input,
  FormLabel,
  InputGroup,
  FormControl,
  Text,
} from '@chakra-ui/react';
import { Paperclip } from 'react-feather';

/* =============================================================================
<DealItemCreatePhoto />
============================================================================= */
const DealItemCreatePhoto = ({ block }) => {
  const { values, setFieldValue } = useFormikContext();
  const photoName = values?.photo.name;

  const _handleFileUpload = (file) => {
    setFieldValue('photo', file);
  };

  return (
    <FormControl mb={8}>
      <FormLabel fontSize="16px" fontWeight="normal">Upload Banner</FormLabel>
      <DropZone onDrop={(files) => _handleFileUpload(files[0])}>
        {({ getRootProps, getInputProps }) => (
          <Flex
            p={4}
            w={!block && '350px'}
            flex={block && 1}
            h="43px"
            flexDir="column"
            overflow="hidden"
            alignItems="center"
            justifyContent="center"
            borderRadius="8px"
            border="1px solid #d1d1d1"
            {...getRootProps()}
          >
            <InputGroup justifyContent="space-between">
              <Input {...getInputProps()} placeholder="lskfjlskdj" />
              <Text>{photoName || 'ex.Deal1.png'}</Text>
              <Paperclip size="20px" color="#999" />
            </InputGroup>
          </Flex>
        )}
      </DropZone>
    </FormControl>
  );
};

/* Export
============================================================================= */
export default DealItemCreatePhoto;
