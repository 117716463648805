/**
 * Get error
 */
export const getError = (state) => state.QRCodes.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.QRCodes.loading;

/**
 * Get qrCodes
 */
export const getQRCodes = (state) => state.QRCodes.qrCodes;

/**
 * Get next cursor
 */
export const getNextCursor = (state) => state.QRCodes.nextCursor;
