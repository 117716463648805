import React from 'react';
import { Text } from '@chakra-ui/react';

/* =============================================================================
<StreetListFooter />
============================================================================= */
const StreetListFooter = ({ saving, saved, ...props }) => {
  if (saving || saved) {
    return (
      <Text fontSize="sm" align="center" my={4} {...props}>
        {saving ? 'Saving...' : 'Saved'}
      </Text>
    );
  }

  return null;
};

/* Export
============================================================================= */
export default StreetListFooter;
