import React from 'react';
import { FastField } from 'formik';
import {
  Textarea,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<DealItemCreateAbout />
============================================================================= */
const DealItemCreateAbout = () => (
  <FastField name="about">
    {({ field, form }) => (
      <FormControl mb={8} isInvalid={form.errors.about && form.touched.about}>
        <FormLabel fontSize="16px" fontWeight="normal">About</FormLabel>
        <Textarea id="about" {...field} />
        <FormErrorMessage>
          <FormErrorIcon />
          {form.errors.about}
        </FormErrorMessage>
      </FormControl>
    )}
  </FastField>
);

/* Export
============================================================================= */
export default DealItemCreateAbout;
