import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
} from '@chakra-ui/react';

import CouponsTableItem from './CouponsTableItem';
import CouponsTableHeadItem from './CouponsTableHeadItem';

/* =============================================================================
<CouponsTable />
============================================================================= */
const CouponsTable = ({ data }) => (
  <Box flex={1} overflow="auto">
    <Table variant="simple" colorScheme="gray" size="lg">
      <Thead>
        <Tr>
          {columns.map(renderColumn)}
        </Tr>
      </Thead>
      <Tbody>
        {data.map(renderItem)}
      </Tbody>
    </Table>
  </Box>
);

const renderColumn = (item, i) => <CouponsTableHeadItem key={`${i}`} title={item} />;
const renderItem = (item) => <CouponsTableItem key={item} id={item} />;

const columns = [
  'Name',
  'Amount',
  'Uses',
  'Max Uses',
  'Starts At',
  'Expires At',
  'Action'
];

/* Export
============================================================================= */
export default CouponsTable;
