/**
 * Get error
 */
export const getError = (state) => state.Coupons.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.Coupons.loading;

/**
 * Get coupons
 */
export const getCoupons = (state) => state.Coupons.coupons;

/**
 * Get next cursor
 */
export const getNextCursor = (state) => state.Coupons.nextCursor;
