import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Search as SearchIcon } from 'react-feather';
import {
  Flex,
  Input,
  Button,
  Spinner,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

import Card from '../../../common/Card';
import WalletHeaderAddWalletModal from './WalletHeaderAddWalletModal';

import {findWallet as findWalletAction} from '../../redux/actions';

/* =============================================================================
<WalletHeader />
============================================================================= */
const WalletHeader = ({findWallet, onWalletChange}) => {
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [addWalletModalVisible, setAddWalletModalVisible] = useState(false);

  // Get wallet with entered phone number
  useEffect(() => {
    if (phone.length === 13) {
      _findWallet();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);

  const _findWallet = async () => {
    setLoading(true);
    
    await findWallet(phone, (err, payload) => {
      onWalletChange(payload?.id || '');
    });

    setLoading(false);
  }

  const _handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const _showAddWalletModal = () => {
    setAddWalletModalVisible(true);
  };

  const _hideAddWalletModal = () => {
    setAddWalletModalVisible(false);
  };

  return (
    <Card
      p={[0, 2, 5]}
      mt={0}
      display={['block', 'block', 'flex']}
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            children={<SearchIcon color='#999' />}
          />
          <Input
            type='text'
            value={phone}
            placeholder="Enter Phone Number..."
            onChange={_handlePhoneChange}
          />
        </InputGroup>
        {loading && (
          <Spinner mx={4} alignSelf="center" colorScheme="purple" />
        )}
      </Flex>
      <Button
        variant="outline"
        colorScheme="purple"
        onClick={_showAddWalletModal}
      >
        Add Wallet
      </Button>
      <WalletHeaderAddWalletModal
        isOpen={addWalletModalVisible}
        onClose={_hideAddWalletModal}
      />
    </Card>
  );
};

const mapDispatchToProps = {
  findWallet: findWalletAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(WalletHeader);
