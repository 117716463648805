import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import ViewportList from "react-viewport-list";
import {
  Flex,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';

import LiveOrdersCard from '../LiveOrdersCard';
import LiveOrdersTabHeader from '../LiveOrdersTabHeader';
import OrderDetailsModal from '../../../common/OrderDetailsModal';
// import LiveOrdersAcceptedActions from './LiveOrdersAcceptedActions';

import { getOrdersByStatusAndType } from '../../redux/selectors';

/* =============================================================================
<LiveOrdersAccepted />
============================================================================= */
const LiveOrdersAccepted = ({ orders }) => {
  const list = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOrder, setSelectedOrder] = useState(null);

  const _handleDetailsModalOpen = (id) => {
    setSelectedOrder(id);
    onOpen();
  };

  const _handleDetailsModalClose = () => {
    setSelectedOrder(null);
    onClose();
  };

  return (
    <VStack flex={1}>
      <LiveOrdersTabHeader title={`Accepted (${orders.length})`} bg="orange.400" />
      <Flex direction="column" h="47vh" w="100%" overflowY="scroll" ref={list}>
        <ViewportList
          viewportRef={list}
          items={orders}
          itemMinSize={100}
        >
          {(order) => (
            <LiveOrdersCard
              id={order}
              key={order}
              // footer={<LiveOrdersAcceptedActions id={order} />}
              onDetailsClick={() => _handleDetailsModalOpen(order)}
            />
          )}
        </ViewportList>
      </Flex>
      <OrderDetailsModal
        id={selectedOrder}
        isOpen={isOpen}
        // footer={<LiveOrdersAcceptedActions id={selectedOrder} />}
        onClose={_handleDetailsModalClose}
      />
    </VStack>
  );
};

const mapStateToProps = (state) => ({
  orders: getOrdersByStatusAndType(state, { status: 'accepted' }),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.orders.toString() === nextProps.orders.toString();

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(LiveOrdersAccepted, propsAreEqual));
