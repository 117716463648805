import * as constants from './constants';

export const INITIAL_STATE = {
  restaurants: [],
  dishesByRestaurant: {},
  nextCursor: '',
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // RESTAURANTS_GET
    case constants.RESTAURANTS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANTS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANTS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        restaurants: [...new Set([...state.restaurants, ...payload.data])],
        nextCursor: payload.nextCursor,
      };
    case constants.RESTAURANTS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // RESTAURANTS_REFRESH
    case constants.RESTAURANTS_REFRESH.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANTS_REFRESH.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANTS_REFRESH.SUCCESS:
      return {
        ...state,
        error: null,
        restaurants: payload.data,
        nextCursor: payload.nextCursor,
      };
    case constants.RESTAURANTS_REFRESH.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // RESTAURANT_GET
    case constants.RESTAURANT_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANT_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANT_GET.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.RESTAURANT_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // RESTAURANT_CREATE
    case constants.RESTAURANT_CREATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANT_CREATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANT_CREATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.RESTAURANT_CREATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

      // RESTAURANT_UPDATE
    case constants.RESTAURANT_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANT_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANT_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.RESTAURANT_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

      // RESTAURANT_DISHES_GET
    case constants.RESTAURANT_DISHES_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANT_DISHES_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANT_DISHES_GET.SUCCESS:
      return {
        ...state,
        error: null,
        dishesByRestaurant: {
          ...state.dishesByRestaurant,
          [payload.restaurant]: payload.dishes,
        },
        nextCursor: payload.nextCursor,
      };
    case constants.RESTAURANT_DISHES_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
