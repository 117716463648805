import React from 'react';
import { HStack, Heading, Select, Spinner } from '@chakra-ui/react';

const STATUSES = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Accepted',
    value: 'accepted',
  },
  {
    label: 'Prepared',
    value: 'prepared',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
  {
    label: 'Declined',
    value: 'declined',
  },
];

/* =============================================================================
<OrdersHeader />
============================================================================= */
const OrdersHeader = ({
  loading,
  currentStatus,
  onStatusChange,
}) => (
  <HStack justifyContent="space-between" p={6}>
    <Heading fontSize="4xl" fontWeight="normal">
      Orders
    </Heading>
    <HStack flex={1} justifyContent="flex-end">
      {loading && <Spinner size="md" color="blue" />}
      <Select
        value={currentStatus}
        onChange={(el) => onStatusChange(el.target.value)}
        maxWidth="xs"
      >
        {STATUSES.map((status) => (
          <option key={status.value} value={status.value}>
            {status.label}
          </option>
        ))}
      </Select>
    </HStack>
  </HStack>
);

/* Export
============================================================================= */
export default OrdersHeader;
