import React, { useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { connect } from 'react-redux';

import Card from '../../common/Card';
import CouponsTable from '../components/CouponsTable';
import CouponsHeader from '../components/CouponsHeader';
import CouponsFooter from '../components/CouponsFooter';
import usePagination from '../../hooks/use-pagination';

import { getNextCursor, getCoupons as selectCoupons } from '../redux/selectors';
import {
  getCoupons as getCouponsAction,
  refreshCoupons as refreshCouponsAction,
} from '../redux/actions';

/* =============================================================================
<CouponsScreen />
============================================================================= */
const CouponsScreen = ({
  nextCursor,
  coupons,
  getCoupons,
  refreshCoupons,
}) => {
  const {
    next,
    prev,
    jumpTo,
    items,
    canNext,
    canPrev,
  } = usePagination(coupons);

  // Get coupons
  useEffect(() => {
    refreshCoupons();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleNext = () => {
    if (canNext) {
      next();
      return;
    }

    if (nextCursor) {
      getCoupons(nextCursor, (err) => {
        if (!err) {
          next(coupons);
        }
      });
    }
  };

  const _handlePrev = () => {
    if (canPrev) {
      prev();
    }
  };

  const _handleRefresh = () => {
    refreshCoupons(() => {
      jumpTo(1);
    });
  };

  return (
    <Card as={Flex} flex={1} overflow="hidden" direction="column" p={0}>
      <CouponsHeader onRefresh={_handleRefresh} />
      <CouponsTable data={items} />
      <CouponsFooter
        canNext={canNext || !!nextCursor}
        canPrev={canPrev}
        onNext={_handleNext}
        onPrev={_handlePrev}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  coupons: selectCoupons(state),
  nextCursor: getNextCursor(state),
});

const mapDispatchToProps = {
  getCoupons: getCouponsAction,
  refreshCoupons: refreshCouponsAction,
};

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.nextCursor === nextProps.nextCursor
  && prevProps.coupons.toString() === nextProps.coupons.toString()
);

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(CouponsScreen, propsAreEqual));
