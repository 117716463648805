import { actionGenerator } from '../../util/reduxHelpers';

export const TYPE_SET = 'LIVE_ORDERS/TYPE_SET';
export const QUERY_SET = 'LIVE_ORDERS/QUERY_SET';
export const DAYS_FILTER_SET = 'LIVE_ORDERS/DAYS_FILTER_SET';
export const ORDER_ADD = 'LIVE_ORDERS/ORDER_ADD';
export const ORDERS_GET = actionGenerator(
  'LIVE_ORDERS/ORDERS_GET',
);
export const ORDER_GET = actionGenerator(
  'LIVE_ORDERS/ORDER_GET',
);
