import { denormalize } from 'normalizr';

import * as schema from '../api/schema';

/**
 * Get user by id
 */
export const getUser = (state, { id, normalized }) => (
  normalized
    ? state.Entities.users && state.Entities.users[id]
    : denormalize(id, schema.user, state.Entities)
);

/**
 * Get dish by id
 */
export const getDish = (state, { id, normalized }) => (
  normalized
    ? state.Entities.dishes && state.Entities.dishes[id]
    : denormalize(id, schema.dish, state.Entities)
);

/**
 * Get choice group by id
 */
// eslint-disable-next-line max-len
export const getChoiceGroup = (state, { id, normalized }) => (
  normalized
    ? state.Entities.choiceGroups && state.Entities.choiceGroups[id]
    : denormalize(id, schema.choiceGroup, state.Entities)
);

/**
 * Get choice by id
 */
export const getChoice = (state, { id, normalized }) => (
  normalized
    ? state.Entities.choices && state.Entities.choices[id]
    : denormalize(id, schema.choice, state.Entities)
);

/**
 * Get address by id
 */
export const getAddress = (state, { id, normalized }) => (
  normalized
    ? state.Entities.addresses && state.Entities.addresses[id]
    : denormalize(id, schema.address, state.Entities)
);

/**
 * Get category by id
 */
export const getCategory = (state, { id, normalized }) => (normalized
  ? state.Entities.categories && state.Entities.categories[id]
  : denormalize(id, schema.category, state.Entities)
);

/**
 * Get post by id
 */
export const getPost = (state, { id, normalized }) => (normalized
  ? state.Entities.posts && state.Entities.posts[id]
  : denormalize(id, schema.post, state.Entities)
);

/**
 * Get order by id
 */
export const getOrder = (state, { id, normalized }) => (normalized
  ? state.Entities.orders && state.Entities.orders[id]
  : denormalize(id, schema.order, state.Entities)
);

/**
 * Get restaurant by id
 */
export const getRestaurant = (state, { id, normalized }) => (
  normalized
    ? state.Entities.restaurants && state.Entities.restaurants[id]
    : denormalize(id, schema.restaurant, state.Entities)
);

/**
 * Get notification by id
 */
// eslint-disable-next-line max-len
export const getNotification = (state, { id, normalized }) => (
  normalized
    ? state.Entities.notifications && state.Entities.notifications[id]
    : denormalize(id, schema.notification, state.Entities)
);

/**
 * Get discount by id
 */
export const getDiscount = (state, { id, normalized }) => (normalized
  ? state.Entities.discounts && state.Entities.discounts[id]
  : denormalize(id, schema.discount, state.Entities)
);

/**
 * Get deal by id
 */
export const getDeal = (state, { id, normalized }) => (normalized
  ? state.Entities.deals && state.Entities.deals[id]
  : denormalize(id, schema.deal, state.Entities)
);

/**
 * Get deal item by id
 */
export const getDealItem = (state, { id, normalized }) => (normalized
  ? state.Entities.dealItems && state.Entities.dealItems[id]
  : denormalize(id, schema.dealItem, state.Entities)
);

/**
 * Get coupon by id
 */
export const getCoupon = (state, { id, normalized }) => (normalized
  ? state.Entities.coupons && state.Entities.coupons[id]
  : denormalize(id, schema.coupon, state.Entities)
);

/**
 * Get qrcode by id
 */
export const getQRCode = (state, { id, normalized }) => (normalized
  ? state.Entities.qrCodes && state.Entities.qrCodes[id]
  : denormalize(id, schema.qrCode, state.Entities)
);

/**
 * Get wallet by id
 */
export const getWallet = (state, { id, normalized }) => (normalized
  ? state.Entities.wallets && state.Entities.wallets[id]
  : denormalize(id, schema.wallet, state.Entities)
);

/**
 * Get wallet transaction by id
 */
export const getWalletTransaction = (state, { id, normalized }) => (normalized
  ? state.Entities.walletTransactions && state.Entities.walletTransactions[id]
  : denormalize(id, schema.walletTransaction, state.Entities)
);
