import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
} from '@chakra-ui/react';

import OrdersTableItem from './OrdersTableItem';
import OrdersTableHeadItem from './OrdersTableHeadItem';

/* =============================================================================
<OrdersTable />
============================================================================= */
const OrdersTable = ({ data }) => (
  <Box flex={1} overflow="auto">
    <Table variant="simple" colorScheme="gray" size="lg">
      <Thead>
        <Tr>
          {columns.map(renderColumn)}
        </Tr>
      </Thead>
      <Tbody>
        {data.map(renderItem)}
      </Tbody>
    </Table>
  </Box>
);

const renderColumn = (item, i) => <OrdersTableHeadItem key={`${i}`} title={item} />;
const renderItem = (item) => <OrdersTableItem key={item} id={item} />;

const columns = [
  'Name',
  'Phone',
  'Restaurant',
  'Restaurant Phone',
  'Status',
  'Created',
  'Details',
];

/* Export
============================================================================= */
export default OrdersTable;
