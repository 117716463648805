import React from 'react';
import { get } from 'lodash';
import { Stack } from '@chakra-ui/react';

import { FormInput, FormSelect } from '../../../common';
import cities from '../../../static/cities.json';
import countries from '../../../static/countries.json';

/* =============================================================================
<RestaurantFormAddress />
============================================================================= */
const RestaurantFormAddress = ({
  type,
  errors,
  watch,
  register,
}) => {
  const country = watch('address.country');
  const isBranch = type === 'branch';

  return (
    <>
      <Stack direction={['column', 'column', 'row']} spacing={4} align="start">
        <FormInput
          mb={6}
          name="address.text"
          label="Address"
          error={get(errors, 'address.text')}
          register={register}
        />
        {isBranch && (
          <FormInput
            mb={6}
            name="address.branch"
            label="Branch Name"
            error={get(errors, 'address.branch')}
            register={register}
          />
        )}
      </Stack>
      <Stack direction={['column', 'column', 'row']} spacing={4} align="start">
        <FormSelect
          mb={6}
          name="address.country"
          label="Country"
          options={countries}
          error={get(errors, 'address.country')}
          register={register}
        />
        <FormSelect
          mb={6}
          name="address.city"
          label="City"
          options={cities[country] || []}
          error={get(errors, 'address.city')}
          register={register}
        />
        <FormInput
          mb={6}
          name="address.postcode"
          label="Postal Code"
          error={get(errors, 'address.postcode')}
          register={register}
        />
      </Stack>
    </>
  );
};

/* Export
============================================================================= */
export default RestaurantFormAddress;
