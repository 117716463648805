import { createSelector } from 'reselect';

/**
 * Get error
 */
export const getError = (state) => state.Deals.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.Deals.loading;

/**
 * Get deals
 */
export const getDeals = (state) => state.Deals.deals;

/**
 * Get deals entities
 */
const getDealsEntities = (state) => state.Entities.deals;

/**
 * Get deals with entities
 */
export const getDealsWithEntities = createSelector(
  [getDeals, getDealsEntities],
  (ids, byId) => ids.map((id) => byId[id]),
);
