import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Trash2 } from 'react-feather';
import {
  Box,
  Text,
  Image,
  Avatar,
  Spinner,
  ListItem,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';

import { ConfirmationModal } from '../../../common';
import { calculateDiscount } from '../../../util/functions';

import { getDish, getDiscount } from '../../../entities/redux/selectors';
import { deleteDiscountDish as deleteDiscountDishAction } from '../../redux/actions';

/* =============================================================================
<DiscountDishesListItem />
============================================================================= */
const DiscountDishesListItem = ({
  dish,
  discount,
  discountId,
  deleteDiscountDish,
}) => {
  const toast = useToast();
  const [deleting, setDeleting] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const discountType = discount?.type;
  const discountValue = discount?.value;

  const dishId = dish?.id;
  const dishName = dish?.name;
  const dishPrice = (dish?.variants && dish?.variants[0]?.price) || dish?.price;
  const dishPhoto = dish?.photos && dish?.photos[0];

  const restaurant = dish?.restaurant;
  const isBranch = restaurant?.type === 'branch';
  const restaurantPhoto = isBranch
    ? restaurant?.chain?.photos && restaurant?.chain?.photos[0]
    : restaurant?.photos && restaurant?.photos[0];

  const {
    discountPrice,
    originalPrice,
  } = calculateDiscount(dishPrice, discountType, discountValue);

  const _handleConfirmationModalSubmit = async () => {
    onClose();
    setDeleting(true);

    await deleteDiscountDish(discountId, dishId, (err) => {
      if (err) {
        toast({
          title: 'Error',
          description: err?.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Dish removed.',
          description: 'Dish removed successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    });

    setDeleting(false);
  };

  return (
    <ListItem
      mb={4}
      px={8}
      bg="gray.50"
      height={height}
      display="flex"
      borderRadius="lg"
      overflowX="hidden"
      alignItems="center"
      boxShadow="0 4px 8px 0 rgba(0,0,0,0.3)"
    >
      <Box flex={0.5}>
        <Avatar size="2xl" src={restaurantPhoto} />
      </Box>
      <Box flex={1}>
        <Image
          w="100%"
          height="150px"
          borderRadius={5}
          src={dishPhoto}
        />
      </Box>
      <Text flex={1} ml={5} fontSize="2xl">{dishName}</Text>
      <Text flex={1}>{`Original Price ${originalPrice} discount price ${discountPrice}`}</Text>
      {!deleting ? (
        <Trash2 size={14} onClick={onOpen} />
      ) : <Spinner />}
      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={_handleConfirmationModalSubmit}
      />
    </ListItem>
  );
};

const height = '160px';

const mapStateToProps = (state, { id, discountId }) => ({
  dish: getDish(state, { id }),
  discount: getDiscount(state, { id: discountId, normalized: true }),
});

const mapDispatchTopProps = {
  deleteDiscountDish: deleteDiscountDishAction,
};

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.id === nextProps.id
  && prevProps.discountId === nextProps.discountId
  && prevProps.dish?.name === nextProps.dish?.name
  && prevProps.dish?.price === nextProps.dish?.price
  && prevProps.discount?.type === nextProps.discount?.type
  && prevProps.discount?.value === nextProps.discount?.value
  && prevProps.dish?.restaurant?.type === nextProps.dish?.restaurant?.type
  && prevProps.dish?.restaurant?.photos?.toString()
    === nextProps.dish?.restaurant?.photos?.toString()
  && prevProps.dish?.restaurant?.chain?.photos?.toString()
    === nextProps.dish?.restaurant?.chain?.photos?.toString()
);

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchTopProps,
)(React.memo(DiscountDishesListItem, propsAreEqual));
