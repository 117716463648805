import { createSelector } from 'reselect';

/**
 * Get error
 */
export const getError = (state) => state.LiveOrders.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.LiveOrders.loading;

/**
 * Get order type
 */
export const getType = (state, props) => props?.type || state.LiveOrders.type;

/**
 * Get days filter
 */
export const getDaysFilter = (state, props) => props?.days || state.LiveOrders.days;

/**
 * Get query
 */
export const getQuery = (state) => state.LiveOrders.query;

/**
 * Get orders
 */
export const getOrders = (state) => state.LiveOrders.orders;

/**
 * Get riders
 */
const getRiders = (state) => state.LiveOrders.riders || {};

/**
 * Get users entity
 */
const getUsersEntity = (state) => state.Entities.users || {};

/**
 * Get orders entity
 */
const getOrdersEntity = (state) => state.Entities.orders || {};

/**
 * Get orders by search
 */
export const getOrdersBySearch = createSelector(
  [getOrders, getOrdersEntity, getUsersEntity, getQuery],
  (ids, ordersById, usersById, query) => ids?.filter((id) => {
    if (!query) {
      return true;
    }

    const order = ordersById[id];
    const regExp = new RegExp(query, 'i');
    const user = usersById[order?.customer];
    const phone = user?.phone;
    const username = (user?.profile?.firstName && user?.profile?.firstName)
      ? `${user.profile.firstName} ${user.profile.lastName}` : '';

    if (username?.search(regExp) > -1) {
      return true;
    }

    if (phone?.search(regExp) > -1) {
      return true;
    }
 
    if (order?.tag?.search(regExp) > -1) {
      return true;
    }

    return false;
  }),
);

/**
 * Get orders by type
 */
export const getOrdersByType = createSelector(
  [getOrdersBySearch, getOrdersEntity, getType],
  (allIds, byId, type) => {
    switch (type) {
      case 'all':
        return allIds?.filter((id) => (
          (byId[id]?.type === 'dine_in' || byId[id]?.type === 'delivery' || byId[id]?.type === 'gift' || byId[id]?.type === 'take_away') && byId[id]?.status !== 'declined'
        ));

      case 'dine_in':
        return allIds?.filter((id) => (
          byId[id]?.type === 'dine_in' && byId[id]?.status !== 'declined'
        ));

      case 'delivery':
        return allIds?.filter((id) => (
          (byId[id]?.type === 'delivery' || byId[id]?.type === 'gift') && byId[id]?.status !== 'declined'
        ));

      case 'take_away':
        return allIds?.filter((id) => (
          byId[id]?.type === 'take_away' && byId[id]?.status !== 'declined'
        ));

      default:
        return [];
    }
  },
);

/**
 * Get order status
 */
const getOrderStatus = (state, { status }) => status;

/**
 * Get orders by status
 */
export const getOrdersByStatus = createSelector(
  [getOrdersBySearch, getOrdersEntity, getOrderStatus],
  (allIds, byId, status) => {
    switch (status) {
      case 'pending':
        return allIds?.filter((id) => byId[id]?.status === 'pending');

      case 'accepted':
        return allIds?.filter((id) => {
          const order = byId[id];
          return (order?.type === 'take_away' || order?.type === 'dine_in')
            ? order?.status === 'accepted' || order?.status === 'prepared'
            : order?.status === 'accepted';
        })
          .sort((a, b) => (
            new Date(byId[b]?.acceptedAt).getTime() - new Date(byId[a]?.acceptedAt).getTime()
          ));

      case 'dispatched':
        return allIds?.filter((id) => {
          const order = byId[id];
          return (order?.type === 'delivery' || order?.type === 'gift') && order?.status === 'prepared';
        })
          .sort((a, b) => (
            new Date(byId[b]?.preparedAt).getTime() - new Date(byId[a]?.preparedAt).getTime()
          ));

      case 'completed':
        return allIds?.filter((id) => byId[id]?.status === 'completed')
          .sort((a, b) => (
            new Date(byId[b]?.completedAt).getTime() - new Date(byId[a]?.completedAt).getTime()
          ));

      default:
        return [];
    }
  },
);

/**
 * Get orders by status and type
 */
export const getOrdersByStatusAndType = createSelector(
  [getOrdersByType, getOrdersEntity, getOrderStatus],
  (allIds, byId, status) => {
    switch (status) {
      case 'pending':
        return allIds?.filter((id) => byId[id]?.status === 'pending');

      case 'accepted':
        return allIds?.filter((id) => {
          const order = byId[id];
          return (order?.type === 'take_away' || order?.type === 'dine_in')
            ? order?.status === 'accepted' || order?.status === 'prepared'
            : order?.status === 'accepted';
        })
          .sort((a, b) => (
            new Date(byId[b]?.acceptedAt).getTime() - new Date(byId[a]?.acceptedAt).getTime()
          ));

      case 'dispatched':
        return allIds?.filter((id) => {
          const order = byId[id];
          return (order?.type === 'delivery' || order?.type === 'gift') && order?.status === 'prepared';
        })
          .sort((a, b) => (
            new Date(byId[b]?.preparedAt).getTime() - new Date(byId[a]?.preparedAt).getTime()
          ));

      case 'completed':
        return allIds?.filter((id) => byId[id]?.status === 'completed')
          .sort((a, b) => (
            new Date(byId[b]?.completedAt).getTime() - new Date(byId[a]?.completedAt).getTime()
          ));

      default:
        return [];
    }
  },
);

/**
 * Get riders by Id
 */
export const getRidersById = createSelector(
  [getRiders, getUsersEntity],
  (allIds, byId) => allIds.map((id) => byId[id]),
);
