import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Button } from '@chakra-ui/react';

import RestaurantFormName from './RestaurantFormName';
import RestaurantFormType from './RestaurantFormType';
import RestaurantFormOwner from './RestaurantFormOwner';
import RestaurantFormPhone from './RestaurantFormPhone';
import RestaurantFormAddress from './RestaurantFormAddress';
import RestaurantFormLocation from './RestaurantFormLocation';
import RestaurantFormCategories from './RestaurantFormCategories';

/* =============================================================================
<RestaurantForm />
============================================================================= */
const RestaurantForm = ({
  isNew,
  initialValues,
  validationSchema,
  onSubmit,
}) => {
  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const {
    reset,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  const type = watch('type');

  // Initialize from with restaurant details on edit
  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <form onSubmit={handleSubmit(onSubmit({ reset }))}>
      <RestaurantFormOwner
        errors={errors}
        setValue={setValue}
        register={register}
      />
      <RestaurantFormName
        type={type}
        errors={errors}
        register={register}
      />
      <RestaurantFormAddress
        type={type}
        errors={errors}
        watch={watch}
        register={register}
      />
      <Stack direction={['column', 'column', 'row']} spacing={4}>
        <RestaurantFormLocation
          type={type}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />
        <RestaurantFormType
          type={type}
          isNew={isNew}
          errors={errors}
          watch={watch}
          setValue={setValue}
          register={register}
        />
      </Stack>
      <RestaurantFormPhone
        type={type}
        errors={errors}
        watch={watch}
        setValue={setValue}
        register={register}
      />
      <RestaurantFormCategories
        type={type}
        errors={errors}
        register={register}
      />
      <Button
        type="submit"
        float="right"
        colorScheme="purple"
        isLoading={isSubmitting}
      >
        Save
      </Button>
    </form>
  );
};

/* Export
============================================================================= */
export default RestaurantForm;
