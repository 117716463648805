import Resizer from 'react-image-file-resizer';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { nanoid } from 'nanoid/async';
import { storage } from '../config/firebase';

// const UPLOAD_URL = 'https://api.cloudinary.com/v1_1/dreamslab/image/upload';

const resizeFile = (file, quality) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      'JPEG',
      quality || 30,
      0,
      (uri) => {
        resolve(uri);
      },
      'blob',
    );
  });

const uploadToGCloud = async (
  { photo, quality, uploadPath },
  onUploadProgress,
) => {
  const resized = await resizeFile(photo, quality);
  const topFolder = process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev';
  // File type is always image/jpeg since it's compressed as that.
  const path = `${topFolder}/${uploadPath}/${await nanoid()}.jpg`;
  const imageRef = ref(storage, path);
  const task = uploadBytesResumable(imageRef, resized);
  if (onUploadProgress) {
    task.on('state_changed', (s) => {
      onUploadProgress(s.bytesTransferred / s.totalBytes);
    });
  }
  await task;
  const downloadUrl = await getDownloadURL(imageRef);
  return downloadUrl;
};

export const uploadRestaurantPhoto = (photo, onUploadProgress) =>
  uploadToGCloud({ photo, uploadPath: 'logos' }, onUploadProgress);

export const uploadCategoryPhoto = (photo, onUploadProgress) =>
  uploadToGCloud({ photo, uploadPath: 'categories' }, onUploadProgress);

export const uploadDealBanner = (photo, onUploadProgress) =>
  uploadToGCloud(
    { photo, quality: 90, uploadPath: 'banners' },
    onUploadProgress,
  );
