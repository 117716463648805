import React from 'react';
import { connect } from 'react-redux';
import { normalize } from 'normalizr';
import { AsyncSelect } from 'chakra-react-select';
import { FastField, useFormikContext } from 'formik';
import {
  Radio,
  VStack,
  RadioGroup,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

import request from '../../../../util/request';
import { restaurant as restaurantSchema } from '../../../../entities/api/schema';

import { addEntities as addEntitiesAction } from '../../../../entities/redux/actions';
import { FormInput } from '../../../../common';

/* =============================================================================
<CreateNotificationAddScreen />
============================================================================= */
const CreateNotificationAddScreen = ({ addEntities }) => {
  const {errors, setFieldValue} = useFormikContext();

  const _handleLoadRestaurants = async (value) => {
    try {
      const payload = await request({
        url: `/restaurants/find?q=${value}`,
        method: 'GET',
      });

      const { entities } = normalize(payload.data, [restaurantSchema]);
      addEntities(entities);

      return payload.data.map((_restaurant) => ({
        label: _restaurant.name,
        value: _restaurant.id,
      }));
    } catch (e) {
      return [];
    }
  };

  const _handleRestaurantsChange = ({ value }) => {
    setFieldValue('restaurant', value);
  };

  return (
    <FastField name="screen">
      {({ form }) => {
        const value = form?.values?.screen;

        return (
          <FormControl isInvalid={form.errors.screen && form.touched.screen}>
            <RadioGroup
              mt={3}
              size="lg"
              colorScheme="purple"
              value={value}
              onChange={form.handleChange('screen')}
            >
              <VStack justify="flex-start" alignItems="flex-start">
                <Radio fontSize="lg" value="launch">Go to Home Screen</Radio>
                <Radio fontSize="lg" value="restaurant_menu">Go to Restaurant Menu</Radio>
                {value === 'restaurant_menu' && (
                  <FormInput name="restaurant" mt={2} error={errors.restaurant}>
                    <AsyncSelect
                      placeholder="Select Restaurant"
                      cacheOptions
                      loadOptions={_handleLoadRestaurants}
                      onChange={_handleRestaurantsChange}
                    />
                  </FormInput>
                )}
              </VStack>
            </RadioGroup>
            <FormErrorMessage>
              <FormErrorIcon />
              {errors.screen}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    </FastField>
  );
};

const mapDispatchToProps = {
  addEntities: addEntitiesAction,
};
  
/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(CreateNotificationAddScreen);
