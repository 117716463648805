import React, { useState } from 'react';
import { Menu } from 'react-feather';
import { NavLink, useLocation } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  List,
  Link,
  Button,
  Heading,
  ListItem,
} from '@chakra-ui/react';

import NAV_LINKS from '../static/nav_links';
import { ReactComponent as HamburgerOpened } from '../assets/icons/nav-opened-hamburger-icon.svg';

/* =============================================================================
<SideNav />
============================================================================= */
const SideNav = ({ user }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const _toggleSideNav = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <Box bgColor="white" height="100vh" overflowY="auto">
      <Flex p={4} pr={open && '0'} align="center" justify="space-between">
        {open && (
          <Heading
            as="h1"
            color="blue.900"
            fontSize="2xl"
            fontFamily="Rajdhani"
          >
            FOOD STREET
          </Heading>
        )}
        <Button display="flex" variant="unstyled" onClick={_toggleSideNav}>
          {open ? <HamburgerOpened /> : <Menu />}
        </Button>
      </Flex>
      <List mt={4}>
        {NAV_LINKS.filter((link) =>
          link.allowedRoles.includes(user.role.app),
        ).map((link) => (
          <ListItem key={link.to}>
            <Link
              as={NavLink}
              to={link.to}
              display="flex"
              justifyContent={!open && 'center'}
              p={4}
              m={2}
              borderRadius="sm"
              bg={isActive(link.to, location) ? 'gray.100' : 'inherit'}
              _hover={{
                bg: 'gray.100',
              }}
              _focus="none"
            >
              {link.icon}
              {open && <Text ml={4}>{link.title}</Text>}
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const isActive = (link, location) => location.pathname.startsWith(link);

/* Export
============================================================================= */
export default SideNav;
