import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import { order as orderSchema } from '../../entities/api/schema';

/**
 * TYPE_SET
 */
export const setType = (payload) => ({
  type: constants.TYPE_SET,
  payload,
});

/**
 * QUERY_SET
 */
export const setQuery = (payload) => ({
  type: constants.QUERY_SET,
  payload,
});

/**
 * DAYS_FILTER_SET
 */
export const setDaysFilter = (payload) => ({
  type: constants.DAYS_FILTER_SET,
  payload,
});

/**
 * ORDER_ADD
 */
export const addOrder = (payload) => ({
  type: constants.ORDER_ADD,
  payload,
});

/**
 * ORDERS_GET
 */
export const getOrders = (days, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.ORDERS_GET.REQUEST });

    const payload = await request({
      url: '/orders/feed',
      method: 'GET',
      params: { t: Date.now(), days },
    });
    const { entities, result } = normalize(payload.data, [orderSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.ORDERS_GET.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.ORDERS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.ORDERS_GET.COMPLETE });
  }
};

/**
 * ORDER_GET
 */
export const getOrder = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.ORDER_GET.REQUEST });

    const payload = await request({
      url: `/orders/${id}`,
      method: 'GET',
    });
    const { entities } = normalize(payload, orderSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.ORDER_GET.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.ORDER_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.ORDER_GET.COMPLETE });
  }
};
