import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import { notification as notificationSchema } from '../../entities/api/schema';

/**
 * NOTIFICATIONS_GET
 */
export const getNotifications = (nextCursor, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.NOTIFICATIONS_GET.REQUEST });

    const payload = await request({
      url: '/notifications',
      method: 'GET',
      params: {
        next_cursor: nextCursor,
      },
    });
    const { entities, result } = normalize(payload.data, [notificationSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.NOTIFICATIONS_GET.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.NOTIFICATIONS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.NOTIFICATIONS_GET.COMPLETE });
  }
};

/**
 * NOTIFICATIONS_REFRESH
 */
export const refreshNotifications = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.NOTIFICATIONS_REFRESH.REQUEST });

    const payload = await request({
      url: '/notifications',
      method: 'GET',
    });

    const { entities, result } = normalize(payload.data, [notificationSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.NOTIFICATIONS_REFRESH.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.NOTIFICATIONS_REFRESH.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.NOTIFICATIONS_REFRESH.COMPLETE });
  }
};

/**
 * NOTIFICATION_CREATE
 */
export const createNotification = (data, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.NOTIFICATION_CREATE.REQUEST });

    const payload = await request({
      url: '/notifications',
      method: 'POST',
      data,
    });

    const { entities, result } = normalize(payload, notificationSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.NOTIFICATION_CREATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.NOTIFICATION_CREATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.NOTIFICATION_CREATE.COMPLETE });
  }
};

/**
 * NOTIFICATION_DELETE
 */
export const deleteNotification = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.NOTIFICATION_DELETE.REQUEST });

    await request({
      url: `/notifications/${id}`,
      method: 'DELETE',
    });

    dispatch({
      type: constants.NOTIFICATION_DELETE.SUCCESS,
      payload: id,
    });
    if (typeof cb === 'function') {
      cb(null, id);
    }
  } catch (error) {
    dispatch({
      type: constants.NOTIFICATION_DELETE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.NOTIFICATION_DELETE.COMPLETE });
  }
};
