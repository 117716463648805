import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { MoreVertical, Trash } from 'react-feather';
import {
  Text,
  Image,
  Avatar,
  HStack,
  Spinner,
  ListItem,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';

import { ConfirmationModal } from '../../../common';

import { getDealItem } from '../../../entities/redux/selectors';
import { deleteDealItem as deleteDealItemAction } from '../../redux/actions';

/* =============================================================================
<DealItemsListItem />
============================================================================= */
const DealItemsListItem = ({
  id,
  index,
  dealId,
  dealItem,
  deleteDealItem,
}) => {
  const toast = useToast();
  const [deleting, setDeleting] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const photo = dealItem?.photo;
  const name = dealItem?.name;
  const restaurantPhoto = dealItem.restaurant?.photos && dealItem.restaurant.photos[0];

  const _handleConfirmationModalSubmit = () => {
    setDeleting(true);
    onClose();
    deleteDealItem(dealId, id, (err) => {
      if (err) {
        toast({
          title: 'Error occured.',
          description: err?.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Deal item removed successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
      setDeleting(false);
    });
  };
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={provided.draggableProps.style}
          mb={4}
          px={8}
          bg="gray.50"
          height={height}
          display="flex"
          borderRadius="lg"
          overflowX="hidden"
          alignItems="center"
          justifyContent="space-between"
          boxShadow="0 3px 9px 0 rgba(0,0,0,0.3)"
        >
          <div {...provided.dragHandleProps}>
            <MoreVertical color="#707070" size={20} />
          </div>
          <HStack spacing={6} flex={1} mx={4}>
            {restaurantPhoto && (
              <Avatar size="xl" src={restaurantPhoto} />
            )}
            <Image w="auto" h="120px" src={photo} />
            <Text flex={1} fontSize="2xl" noOfLines={1}>{name}</Text>
          </HStack>
          {!deleting ? (
            <Trash
              color="grey"
              isLoading={deleting}
              onClick={onOpen}
            />
          ) : (
            <Spinner />
          )}
          <ConfirmationModal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={_handleConfirmationModalSubmit}
          />
        </ListItem>
      )}
    </Draggable>
  );
};

const height = '130px';

const mapStatToProps = (state, { id }) => ({
  dealItem: getDealItem(state, { id }),
});

const mapDispatchToProps = {
  deleteDealItem: deleteDealItemAction,
};

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.id === nextProps.id
  && prevProps.index === nextProps.index
  && prevProps.dealItem?.name === nextProps.dealItem?.name
  && prevProps.dealItem?.photo === nextProps.dealItem?.photo
  && prevProps.dealItem?.restaurant?.type === nextProps.dealItem?.restaurant?.type
  && prevProps.dealItem?.restaurant?.photos?.toString()
    === nextProps.dealItem?.restaurant?.photos?.toString()
  && prevProps.dealItem?.restaurant?.chain?.photos?.toString()
    === nextProps.dealItem?.restaurant?.chain?.photos?.toString()
);

/* Export
============================================================================= */
export default connect(
  mapStatToProps,
  mapDispatchToProps,
)(React.memo(DealItemsListItem, propsAreEqual));
