import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import {
  wallet as walletSchema,
  walletTransaction as walletTransactionSchema,
} from '../../entities/api/schema';

/**
 * WALLET_FIND
 */
export const findWallet = (phone, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.WALLET_FIND.REQUEST });

    const payload = await request({
      url: '/wallets/find',
      method: 'GET',
      params: {
        phone,
      },
    });
    const { entities, result } = normalize(payload, walletSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.WALLET_FIND.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.WALLET_FIND.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.WALLET_FIND.COMPLETE });
  }
};

/**
 * WALLET_CREATE
 */
export const createWallet = (wallet, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.WALLET_CREATE.REQUEST });

    const payload = await request({
      url: '/wallets',
      method: 'POST',
      data: wallet,
    });
    const { entities, result } = normalize(payload, walletSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.WALLET_CREATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.WALLET_CREATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.WALLET_CREATE.COMPLETE });
  }
};

/**
 * WALLET_UPDATE
 */
export const updateWallet = (wallet, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.WALLET_UPDATE.REQUEST });

    const payload = await request({
      url: `/wallets/${wallet.id}`,
      method: 'PUT',
      data: wallet,
    });
    const { entities, result } = normalize(payload, walletSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.WALLET_UPDATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.WALLET_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.WALLET_UPDATE.COMPLETE });
  }
};

/**
 * WALLET_TRANSACTIONS_GET
 */
export const getWalletTransactions = (wallet, nextCursor, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.WALLET_TRANSACTIONS_GET.REQUEST });

    const payload = await request({
      url: `/wallets/${wallet}/transactions`,
      method: 'GET',
      params: {
        next_cursor: nextCursor,
      },
    });
    const { entities, result } = normalize(payload.data, [walletTransactionSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.WALLET_TRANSACTIONS_GET.SUCCESS,
      payload: {
        wallet,
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.WALLET_TRANSACTIONS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.WALLET_TRANSACTIONS_GET.COMPLETE });
  }
};

/**
 * WALLET_TRANSACTIONS_REFRESH
 */
export const refreshWalletTransactions = (wallet, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.WALLET_TRANSACTIONS_REFRESH.REQUEST });

    const payload = await request({
      url: `/wallets/${wallet}/transactions`,
      method: 'GET',
    });

    const { entities, result } = normalize(payload.data, [walletTransactionSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.WALLET_TRANSACTIONS_REFRESH.SUCCESS,
      payload: {
        wallet,
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.WALLET_TRANSACTIONS_REFRESH.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.WALLET_TRANSACTIONS_REFRESH.COMPLETE });
  }
};
