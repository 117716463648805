import React from 'react';
import { FastField, useFormikContext } from 'formik';
import { AsyncSelect } from 'chakra-react-select';
import {
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

import { normalize } from 'normalizr';
import request from '../../../util/request';

import { addEntities } from '../../../entities/redux/actions';
import { restaurant as restaurantSchema } from '../../../entities/api/schema';

/* =============================================================================
<DealItemCreateRestaurant />
============================================================================= */
const DealItemCreateRestaurant = () => {
  const { setFieldValue } = useFormikContext();

  const _handleLoadRestaurants = async (value) => {
    try {
      const payload = await request({
        url: `/restaurants/find?q=${value}`,
        method: 'GET',
      });

      const { entities } = normalize(payload.data, [restaurantSchema]);
      addEntities(entities);

      return payload.data.map((_restaurant) => ({
        label: _restaurant.name,
        value: _restaurant.id,
      }));
    } catch (e) {
      return [];
    }
  };

  const _handleRestaurantsChange = ({ value }) => {
    setFieldValue('restaurant', value);
  };

  return (
    <FastField name="restaurant">
      {({ form }) => (
        <FormControl mb={8} isInvalid={form.errors.restaurant && form.touched.restaurant}>
          <FormLabel fontSize="16px" fontWeight="normal">Restaurant</FormLabel>
          <AsyncSelect
            id="restaurants"
            cacheOptions
            onChange={_handleRestaurantsChange}
            loadOptions={_handleLoadRestaurants}
          />
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.restaurant}
          </FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  );
};

/* Export
============================================================================= */
export default DealItemCreateRestaurant;
