import React from 'react';
import {
  Box,
  Button,
  HStack,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import CreateNotificationTitle from './CreateNotificationTitle';
import CreateNotificationAddScreen from './CreateNotificationAddScreen';
import CreateNotificationBody from './CreateNotificationBody';

/* =============================================================================
<CreateNotification />
============================================================================= */
const CreateNotification = ({ onNextClick }) => {
  const { values } = useFormikContext();
  const disabled = !values.title || !values.body || !values.screen;

  return (
    <Box>
      <HStack alignItems="flex-start" spacing={20}>
        <CreateNotificationTitle />
        <CreateNotificationAddScreen />
      </HStack>
      <HStack alignItems="flex-end">
        <CreateNotificationBody />
        <Button
          size="lg"
          colorScheme="green"
          onClick={onNextClick}
          disabled={disabled}
        >
          Next
        </Button>
      </HStack>
    </Box>
  );
};

/* Export
============================================================================= */
export default CreateNotification;
