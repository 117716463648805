import React from 'react';
import { Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';

import Layout from '../common/Layout';
import LiveOrdersListener from '../liveOrders/components/LiveOrdersListener';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';

import AuthScreen from '../auth/screens/AuthScreen';
import PostsScreen from '../posts/screens/PostsScreen';
import DealsScreen from '../deals/screens/DealsScreen';
import OrdersScreen from '../orders/screens/OrdersScreen';
import StreetScreen from '../street/screens/StreetScreen';
import WalletScreen from '../wallets/screens/WalletScreen';
import CouponsScreens from '../coupons/screens/CouponsScreens';
import QRCodesScreens from '../qrcodes/screens/QRCodesScreens';
import DiscountsScreen from '../discounts/screens/DiscountsScreen';
import DashboardScreen from '../dashboard/screens/DashboardScreen';
import LiveOrdersScreen from '../liveOrders/screens/LiveOrdersScreen';
import RestaurantsScreens from '../restaurants/screens/RestaurantsScreens';
import NotificationScreens from '../notification/screens/NotificationScreens';
import SearchScreen from '../care/screens/SearchScreen';
import { ROLES } from '../config/roles';

/* =============================================================================
<AppNavigation />
============================================================================= */
const AppNavigation = () => (
  <Router>
    <Layout>
      <Switch>
        <Redirect to="/auth/login" from="/" exact />
        <UnauthenticatedRoute path="/auth">
          <AuthScreen />
        </UnauthenticatedRoute>
        <AuthenticatedRoute allowedRoles={[ROLES.ADMIN]} path="/dashboard">
          <DashboardScreen />
        </AuthenticatedRoute>
        <AuthenticatedRoute allowedRoles={[ROLES.ADMIN]} path="/restaurants">
          <RestaurantsScreens />
        </AuthenticatedRoute>
        <AuthenticatedRoute allowedRoles={[ROLES.ADMIN]} path="/street">
          <StreetScreen />
        </AuthenticatedRoute>
        <AuthenticatedRoute allowedRoles={[ROLES.ADMIN]} path="/timeline_posts">
          <PostsScreen />
        </AuthenticatedRoute>
        <AuthenticatedRoute allowedRoles={[ROLES.ADMIN]} path="/discounts">
          <DiscountsScreen />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          allowedRoles={[ROLES.ADMIN]}
          path="/deals_promotions"
        >
          <DealsScreen />
        </AuthenticatedRoute>
        <AuthenticatedRoute allowedRoles={[ROLES.ADMIN]} path="/notifications">
          <NotificationScreens />
        </AuthenticatedRoute>
        <AuthenticatedRoute allowedRoles={[ROLES.ADMIN]} path="/coupons">
          <CouponsScreens />
        </AuthenticatedRoute>
        <AuthenticatedRoute allowedRoles={[ROLES.ADMIN]} path="/qrcodes">
          <QRCodesScreens />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          allowedRoles={[ROLES.ADMIN, ROLES.SUB_ADMIN]}
          path="/orders"
        >
          <OrdersScreen />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          allowedRoles={[ROLES.ADMIN, ROLES.SUB_ADMIN]}
          path="/live_orders"
        >
          <LiveOrdersScreen />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          allowedRoles={[ROLES.ADMIN, ROLES.SUB_ADMIN]}
          path="/care"
        >
          <SearchScreen />
        </AuthenticatedRoute>
        <AuthenticatedRoute allowedRoles={[ROLES.ADMIN]} path="/wallet">
          <WalletScreen />
        </AuthenticatedRoute>
      </Switch>
    </Layout>
    <LiveOrdersListener />
  </Router>
);

/* Export
============================================================================= */
export default AppNavigation;
