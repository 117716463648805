import React, {useState, useEffect } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { Stack } from '@chakra-ui/react';
import { normalize } from 'normalizr';

import { AsyncSelect } from 'chakra-react-select';
import { FormInput, FormSelect } from '../../../common';
import request from '../../../util/request';

import { addEntities } from '../../../entities/redux/actions';
import { getRestaurant } from '../../../entities/redux/selectors';
import { restaurant as restaurantSchema } from '../../../entities/api/schema';

/* =============================================================================
<QRCodeFormRestaurant />
============================================================================= */
const QRCodeFormRestaurant = ({ id, errors, watch, setValue, register }) => {
  const [branches, setBranches] = useState([]);

  const dispatch = useDispatch();
  const restaurantId = watch('restaurant');
  const restaurant = useSelector(state => getRestaurant(state, {id: restaurantId}));
  const isChain = restaurant?.type === 'chain';
  const isBranch = restaurant?.type === 'branch';

  // Get branches
  useEffect(() => {
    if (restaurantId && isChain) {
      (async () => {
        try {
          const payload = await request({
            url: `/restaurants/${restaurantId}/branches`,
            method: 'GET',
          });
    
          const { entities } = normalize(payload.data, [restaurantSchema]);
          dispatch(addEntities(entities));

          setBranches(payload.data.map((_branch) => ({
            label: `${_branch.address.branch}`,
            value: _branch.id,
          })));
        } catch (e) {
          // TODO
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId, isChain]);

  // Set branches
  useEffect(() => {
    if (isBranch) {
      setBranches([{
        label: restaurant?.address?.branch,
        value: restaurantId,
      }]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBranch, restaurantId]);

  const _handleLoadRestaurants = async (value) => {
    try {
      const payload = await request({
        url: `/restaurants/find?q=${value}`,
        method: 'GET',
      });

      const { entities } = normalize(payload.data, [restaurantSchema]);
      dispatch(addEntities(entities));

      return payload.data.map((_restaurant) => ({
        label: _restaurant.name,
        value: _restaurant.id,
      }));
    } catch (e) {
      return [];
    }
  };

  const _handleRestaurantChange = ({ value }) => {
    setValue('restaurant', value);
  };

  return (
    <Stack mt={4} direction={['column', 'column', 'row']} spacing={4} align="start">
      {id ? (
        <FormInput
          mb={6}
          name="restaurantName"
          label="Restaurant"
          error={errors.restaurant}
          inputProps={{disabled: true}}
          register={register}
        />
      ) : (
        <FormInput mb={6} name="restaurant" label="Restaurant" error={errors.restaurant}>
          <AsyncSelect
            id="restaurants"
            cacheOptions
            loadOptions={_handleLoadRestaurants}
            onChange={_handleRestaurantChange}
          />
        </FormInput>
      )}
      <FormSelect
        mb={6}
        name="branch"
        label="Branch"
        options={branches}
        error={errors.branch}
        selectProps={{disabled: id ||  !isChain}}
        register={register}
      />
    </Stack>
  );
};

/* Export
============================================================================= */
export default QRCodeFormRestaurant;
