import React from 'react';
import {
  Button,
  DrawerHeader,
  HStack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { ArrowLeft } from 'react-feather';

/* =============================================================================
<OrderDetailsHeader />
============================================================================= */
const OrderDetailsHeader = ({ onClose }) => {
  const [isSmallerThan766] = useMediaQuery('(max-width: 766px)');

  return (
    <DrawerHeader px={[3, 3, 5]} py={[3, 3, 5]} borderBottomWidth={['0.3px', '0.3px', '0px']}>
      {isSmallerThan766 ? (
        <HStack flex={1} justify="space-between" position="relative">
          <Button
            zIndex={40}
            variant="unstyled"
            onClick={onClose}
          >
            <ArrowLeft />
          </Button>
          <Text
            pt={2}
            h="100%"
            left="0px"
            right="0px"
            textAlign="center"
            position="absolute"
          >
            Order Detail
          </Text>
        </HStack>
      ) : (
        <Button
          variant="outline"
          colorScheme="gray"
          leftIcon={<ArrowLeft size="20px" />}
          onClick={onClose}
        >
          Back to Order
        </Button>
      )}
    </DrawerHeader>
  );
};

/* Export
============================================================================= */
export default OrderDetailsHeader;
