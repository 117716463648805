import { createSelector } from 'reselect';

import { getCategory as getCategoryEntity } from '../../entities/redux/selectors';

/**
 * Get error
 */
export const getError = (state) => state.Street.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.Street.loading;

/**
 * Get categories
 */
export const getCategories = (state) => state.Street.categories;

/**
 * Get restaurants
 */
export const getRestaurants = (state) => state.Street.restaurants;

/**
 * Get special category id
 */
export const getSpecialCategoryId = (state) => state.Street.specialCategory;

/**
 * Get special category
 */
// eslint-disable-next-line max-len
export const getSpecialCategory = (state, { normalized }) => getCategoryEntity(state, { id: getSpecialCategoryId(state), normalized });

/**
 * Get category
 */
const getCategory = (state, { category, id }) => category || id;

/**
 * Get restaurants by category
 */
export const getRestaurantsByCategory = createSelector(
  [getRestaurants, getCategory],
  (restaurants, category) => restaurants[category] || [],
);
