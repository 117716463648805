import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Text,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  Spinner,
  InputRightElement,
  Checkbox,
  Flex,
  Grid,
  VStack,
  useToast,
  Select,
  useDisclosure,
  Table,
  Tbody,
  Thead,
  IconButton,
  Td,
  Tr,
  Badge,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { useCombobox } from 'downshift';
import { useDebouncedCallback } from 'use-debounce';
import { CheckIcon, DownloadIcon } from '@chakra-ui/icons';
import { Download, Trash } from 'react-feather';
import { pdf } from '@react-pdf/renderer';
import request from '../../util/request';
import Card from '../../common/Card';
import GenerateInvoiceModal, {
  saveFile,
} from '../components/GenerateInvoiceModal';
import { ConfirmationModal } from '../../common';
import InvoicePreview from '../components/InvoicePreview';

const d = new Date();
d.setDate(1);
d.setMonth(d.getMonth() - 1);
const LAST_MONTH_ISO_DATE_FIRST_DAY = d.toISOString().split('T')[0]; // yyyy-mm-dd
const s = new Date();
s.setDate(0);
const LAST_MONTH_ISO_DATE_LAST_DAY = s.toISOString().split('T')[0]; // yyyy-mm-dd
const TODAY_ISO_DATE = new Date().toISOString().split('T')[0]; // yyyy-mm-dd
/* =============================================================================
<DashboardScreen />
============================================================================= */

const useRestaurants = (searchText) => {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchRestaurants = useCallback(async () => {
    if (!searchText) {
      setResults(null);
      return;
    }

    setLoading(true);
    try {
      const response = await request({
        url: '/restaurants/search',
        method: 'GET',
        params: {
          q: searchText,
        },
      });

      setResults(response.data);
    } catch (error) {
      // captureException(error);
    } finally {
      setLoading(false);
    }
  }, [searchText]);

  const fetchRestaurantsDebounced = useDebouncedCallback(fetchRestaurants, 500);

  useEffect(() => {
    fetchRestaurantsDebounced();
  }, [fetchRestaurantsDebounced, searchText]);

  return [results, loading];
};

const useInvoices = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState(null);
  const [updating, setUpdating] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [downloading, setDownloading] = useState(null);

  const fetchInvoices = ({ restaurantId, from, to }) => {
    if (!restaurantId) {
      setLoading(false);
      setInvoices(null);
      return;
    }

    setLoading(true);
    setInvoices(null);
    request({
      url: '/invoices',
      params: {
        from,
        to,
        customerId: restaurantId,
      },
    })
      .then((result) => {
        setInvoices(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * @param {string} id - Invoice Id
   */
  const markInvoiceAsPaid = async (id) => {
    setUpdating(id);
    try {
      await request({
        url: `/invoices/${id}`,
        method: 'PUT',
        data: {
          paid: true,
        },
      });
      toast({
        status: 'success',
        title: 'Updated successfully',
      });
      const i = invoices.findIndex((inv) => inv.id === id);

      if (i > -1) {
        invoices[i].paid = true;
        setInvoices(invoices);
      }
    } catch (error) {
      toast({
        status: 'error',
        title: 'An error occurred',
        description: 'Updates were unsuccessful',
      });
    } finally {
      setUpdating(null);
    }
  };

  /**
   * @param {string} id - Invoice Id
   */
  const deleteInvoice = async (id) => {
    setDeleting(id);
    try {
      await request({
        url: `/invoices/${id}`,
        method: 'DELETE',
      });

      const i = invoices.findIndex((inv) => inv.id === id);

      if (i > -1) {
        invoices.splice(i, 1);
        setInvoices(invoices);
      }

      toast({
        status: 'success',
        title: 'Deleted successfully',
      });
    } catch (error) {
      toast({
        status: 'error',
        title: 'An error occurred',
        description: 'Updates were unsuccessful',
      });
    } finally {
      setDeleting(null);
    }
  };

  const downloadInvoicePdf = async (invoice) => {
    setDownloading(invoice.id);
    try {
      const blob = await pdf(
        <InvoicePreview {...{ ...invoice, invoiceId: invoice.id }} />,
      ).toBlob();
      saveFile(
        `Invoice_${invoice.id}_${invoice.customerName.replaceAll(' ', '')}_${
          invoice.invoicePeriodFrom
        }_${invoice.invoicePeriodTo}.pdf`,
        'application/octet-binary',
        blob,
      );
    } catch (error) {
      toast({
        status: 'error',
        title: 'An error occurred',
      });
    } finally {
      setDownloading(null);
    }
  };

  return {
    invoices,
    updating,
    deleting,
    loading,
    downloading,
    fetchInvoices,
    markInvoiceAsPaid,
    deleteInvoice,
    downloadInvoicePdf,
  };
};

const DashboardScreen = () => {
  const toast = useToast();
  const [currentInvoiceId, setCurrentInvoiceId] = useState(null);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);
  const [downloadingHistory, setDownloadingHistory] = useState(false);
  const [declinedReport, setDeclinedReport] = useState(null);
  const [completedReport, setCompletedReport] = useState(null);
  const [reports, setReports] = useState(null);
  const [orderStatus, setOrderStatus] = useState('completed');
  const [formValues, setFormValues] = useState({
    from: LAST_MONTH_ISO_DATE_FIRST_DAY,
    to: LAST_MONTH_ISO_DATE_LAST_DAY,
    excludeTax: false,
    comission: '',
  });
  const {
    isOpen: isInvoiceModalOpen,
    onOpen: openInvoiceModal,
    onClose: closeInvoiceModal,
  } = useDisclosure();
  const {
    isOpen: isConfirmUpdateModalOpen,
    onOpen: openConfirmUpdateModal,
    onClose: closeConfirmUpdateModal,
  } = useDisclosure();
  const {
    isOpen: isConfirmDeleteModalOpen,
    onOpen: openConfirmDeleteModal,
    onClose: closeConfirmDeleteModal,
  } = useDisclosure();
  const {
    invoices,
    updating: updatingInvoice,
    deleting: deletingInvoice,
    downloading: downloadingInvoice,
    fetchInvoices,
    markInvoiceAsPaid,
    deleteInvoice,
    downloadInvoicePdf,
  } = useInvoices();

  const onSubmit = async (values) => {
    if (
      !selectedRestaurant ||
      values.comission === undefined ||
      values.comission < 0 ||
      values.comission > 100
    ) {
      return;
    }

    setFormValues(values);
    setLoadingReport(true);

    fetchInvoices({
      restaurantId: selectedRestaurant._id,
    });

    try {
      const result = await request({
        url: `/restaurants/${selectedRestaurant._id}/report`,
        params: {
          to: values.to,
          from: values.from,
        },
      });

      setReports(result.data);
      setDeclinedReport(result.data.byStatus.find((r) => r._id === 'declined'));
      setCompletedReport(
        result.data.byStatus.find((r) => r._id === 'completed'),
      );
    } catch (error) {
      toast({
        status: 'error',
        title: 'Something went wrong',
      });
    }

    setLoadingReport(false);
  };

  const [searchText, setSearchText] = useState('');
  const [results, loading] = useRestaurants(
    selectedRestaurant ? '' : searchText,
  );

  const {
    isOpen: isSearchMenuOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
  } = useCombobox({
    items: results || [],
    itemToString: (item) => item?.name || '',
    onInputValueChange: ({ inputValue, selectedItem }) => {
      setSearchText(inputValue || '');
      if (inputValue !== selectedItem?.name) {
        setSelectedRestaurant(null);
        setCompletedReport(null);
        setDeclinedReport(null);
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        closeMenu();
        setSelectedRestaurant(selectedItem);
      }
    },
  });

  let totalCommission =
    (completedReport?.totalRevenue -
      completedReport?.totalDelivery -
      (formValues?.excludeTax ? 0 : completedReport?.totalTax)) *
    (formValues?.comission / 100);
  totalCommission = Number.isNaN(totalCommission)
    ? 0
    : Math.round(totalCommission);

  const bykeaReports = reports?.byPartnerAndMethod?.filter(
    (r) => r._id.partner === 'bykea',
  );
  const totalDeliveryByBykea =
    bykeaReports?.reduce((a, b) => a + b.totalDelivery, 0) || 0;
  const bykeaCodReport = bykeaReports?.filter((r) => r._id.method === 'cod')[0];
  const codCollectedByBykea = bykeaCodReport?.totalRevenue || 0;
  const bykeaCodDeliveryFee = bykeaCodReport?.totalDelivery || 0;
  const amountPayableByBykea = codCollectedByBykea - bykeaCodDeliveryFee;
  const bykeaOnlineDeliveryFee = totalDeliveryByBykea - bykeaCodDeliveryFee;
  const onlineAmount =
    reports?.byPartnerAndMethod
      ?.filter((r) => r._id.method === 'card')
      .reduce((a, b) => a + b.totalRevenue, 0) || 0;
  const onlineDues = onlineAmount - bykeaOnlineDeliveryFee;
  const onlineQty =
    reports?.byPartnerAndMethod
      ?.filter((r) => r._id.method === 'card')
      .reduce((a, b) => a + b.count, 0) || 0;

  let totalBalance =
    totalCommission -
    completedReport?.totalCouponDiscount -
    completedReport?.totalQrCodeDiscount -
    amountPayableByBykea -
    onlineDues;

  totalBalance = Number.isNaN(totalBalance) ? 0 : Math.round(totalBalance);

  const downloadHistory = async () => {
    setDownloadingHistory(true);
    try {
      const result = await request({
        url: '/orders/download',
        params: {
          from: formValues.from,
          to: formValues.to,
          restaurantId: selectedRestaurant._id,
          status: orderStatus,
          includeCustomer: 0,
        },
      });
      const _url = window.URL.createObjectURL(
        new Blob([result], { type: 'text/csv' }),
      );
      window.open(_url);
    } catch (error) {
      toast({
        status: 'error',
        title: 'Something went wrong',
      });
    }
    setDownloadingHistory(false);
  };

  return (
    <Box overflowY="auto" height="100%">
      <VStack
        p={5}
        w="full"
        alignItems="start"
        bgColor="blue.900"
        borderRadius="xl"
      >
        <Box color="white" w="full">
          <HStack>
            <Text ml={5} fontSize="xl">
              Get finance report for
            </Text>
            <Box maxW="480px">
              <InputGroup size="lg" {...getComboboxProps()}>
                <Input
                  size="lg"
                  autoComplete="off"
                  placeholder="Enter restaurant name..."
                  onFocus={() => openMenu()}
                  onBlur={() => closeMenu()}
                  {...getInputProps()}
                />
                <InputRightElement>
                  {loading && (
                    <Spinner size="sm" color="brand" emptyColor="gray.200" />
                  )}
                  {selectedRestaurant && <CheckIcon color="green" />}
                </InputRightElement>
              </InputGroup>
              <Box {...getMenuProps()}>
                {isSearchMenuOpen && results && results.length > 0 && (
                  <Box
                    mt={2}
                    position="absolute"
                    zIndex={99}
                    bgColor="white"
                    textAlign="left"
                    fontWeight="medium"
                    boxShadow="lg"
                    maxH="225px"
                    overflowY="auto"
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="xl"
                    py={3}
                    color="gray.900"
                  >
                    {results?.map((item, index) => (
                      <Box
                        py={3}
                        px={4}
                        cursor="pointer"
                        userSelect="none"
                        bgColor={
                          highlightedIndex === index ? 'gray.100' : 'white'
                        }
                        key={item._id}
                        {...getItemProps({ item, index })}
                      >
                        <Text>{item.name}</Text>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
            {selectedRestaurant && (
              <HStack flex={1} justify="flex-end">
                <Text>Download history for</Text>
                <Select
                  width="auto"
                  value={orderStatus}
                  onChange={(e) => setOrderStatus(e.target.value)}
                >
                  <option value="completed">completed</option>
                  <option value="declined">declined</option>
                  <option value="pending">pending</option>
                </Select>
                <Text>orders</Text>
                <Button
                  leftIcon={<DownloadIcon />}
                  colorScheme="purple"
                  isLoading={downloadingHistory}
                  isDisabled={downloadingHistory}
                  onClick={downloadHistory}
                >
                  Download
                </Button>
              </HStack>
            )}
          </HStack>
          <Formik initialValues={formValues} onSubmit={onSubmit}>
            {({ handleChange, handleSubmit, values }) => (
              <>
                <HStack ml={5} mt={5}>
                  <FormControl width="150px">
                    <Checkbox
                      value={values.excludeTax}
                      isChecked={values.excludeTax}
                      onChange={(e) => {
                        setFormValues((v) => ({
                          ...v,
                          excludeTax: e.target.checked,
                        }));
                        handleChange('excludeTax')(e);
                      }}
                    >
                      Exclude tax
                    </Checkbox>
                  </FormControl>
                  <FormControl maxWidth="200px">
                    <InputGroup>
                      <Input
                        placeholder="Comission"
                        type="number"
                        value={values.comission}
                        min={0}
                        max={100}
                        onChange={(e) => {
                          setFormValues((v) => ({
                            ...v,
                            comission: Number(e.target.value),
                          }));
                          handleChange('comission')(e);
                        }}
                      />
                      <InputRightElement>
                        <Text>%</Text>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                </HStack>
                <HStack p={5} alignItems="flex-end">
                  <FormControl width="300px">
                    <FormLabel htmlFor="fromFilter">From</FormLabel>
                    <Input
                      id="fromFilter"
                      type="date"
                      min="2022-03-01"
                      max={TODAY_ISO_DATE}
                      value={values.from}
                      onChange={(e) => {
                        setFormValues((v) => ({
                          ...v,
                          from: e.target.value,
                        }));
                        handleChange('from')(e);
                      }}
                    />
                  </FormControl>
                  <FormControl width="300px">
                    <FormLabel htmlFor="toFilter">To</FormLabel>
                    <Input
                      id="toFilter"
                      type="date"
                      min="2022-03-01"
                      max={TODAY_ISO_DATE}
                      value={values.to}
                      onChange={(e) => {
                        setFormValues((v) => ({
                          ...v,
                          to: e.target.value,
                        }));
                        handleChange('to')(e);
                      }}
                    />
                  </FormControl>
                  <Button colorScheme="purple" onClick={handleSubmit}>
                    Get
                  </Button>
                  <Button
                    isDisabled={!selectedRestaurant || !completedReport}
                    colorScheme="purple"
                    onClick={openInvoiceModal}
                  >
                    Download Invoice
                  </Button>
                </HStack>
              </>
            )}
          </Formik>
        </Box>
        {invoices && invoices.length && (
          <Box py={6}>
            <Text color="white" fontWeight="bold" fontSize="lg">
              Previous invoices:
            </Text>
            <Table>
              <Thead color="white">
                <Tr>
                  <Td>#</Td>
                  <Td>From</Td>
                  <Td>To</Td>
                  <Td>Created At</Td>
                  <Td />
                  <Td />
                </Tr>
              </Thead>
              <Tbody>
                {invoices.map((invoice) => (
                  <Tr>
                    <Td color="white">{invoice.id}</Td>
                    <Td color="white">{invoice.invoicePeriodFrom}</Td>
                    <Td color="white">{invoice.invoicePeriodTo}</Td>
                    <Td color="white">
                      {new Date(invoice.createdAt).toLocaleString()}
                    </Td>
                    <Td>
                      {invoice.paid ? (
                        <Badge variant="solid" colorScheme="green">
                          Paid
                        </Badge>
                      ) : (
                        <Badge variant="solid" colorScheme="red">
                          Unpaid
                        </Badge>
                      )}
                    </Td>
                    <Td>
                      <HStack>
                        {!invoice.paid && (
                          <Button
                            onClick={() => {
                              setCurrentInvoiceId(invoice.id);
                              openConfirmUpdateModal();
                            }}
                            isLoading={invoice.id === updatingInvoice}
                          >
                            Mark as Paid
                          </Button>
                        )}
                        <IconButton
                          onClick={() => downloadInvoicePdf(invoice)}
                          icon={<Download />}
                          isLoading={invoice.id === downloadingInvoice}
                        />
                        {!invoice.paid && (
                          <IconButton
                            onClick={() => {
                              setCurrentInvoiceId(invoice.id);
                              openConfirmDeleteModal();
                            }}
                            isLoading={invoice.id === deletingInvoice}
                            icon={<Trash />}
                          />
                        )}
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
        <Grid m={5} gridTemplateColumns="repeat(3, 400px)" gridGap={6}>
          <Card>
            <Text>Total Sales</Text>
            <Flex
              py={6}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              {loadingReport ? (
                <Spinner />
              ) : (
                <>
                  <Text fontSize="4xl" fontWeight="bold">
                    {completedReport?.totalRevenue?.toLocaleString() || 0}
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    {completedReport?.count?.toLocaleString() || 0} completed
                    orders
                  </Text>
                </>
              )}
            </Flex>
            {!loadingReport && reports && (
              <HStack justify="center" fontSize="sm" spacing={5}>
                {reports.byPartnerAndMethod
                  ?.filter((r) => !r._id.partner)
                  .map((r) => (
                    <VStack spacing={0}>
                      <Box
                        textTransform="uppercase"
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        {r._id.method}
                      </Box>
                      <Box>
                        Rs.{' '}
                        {reports.byPartnerAndMethod
                          .filter((rep) => rep._id.method === r._id.method)
                          .reduce((a, b) => a + b.totalRevenue, 0)
                          .toLocaleString() || 0}
                      </Box>
                      <Box>
                        {reports.byPartnerAndMethod
                          .filter((rep) => rep._id.method === r._id.method)
                          .reduce((a, b) => a + b.count, 0)
                          .toLocaleString()}{' '}
                        orders
                      </Box>
                    </VStack>
                  ))}
              </HStack>
            )}
          </Card>
          <Card>
            <Text>Revenue lost from canceled orders</Text>
            <Flex
              py={6}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              {loadingReport ? (
                <Spinner />
              ) : (
                <>
                  <Text
                    fontSize="4xl"
                    fontWeight="bold"
                    color={declinedReport?.totalRevenue && 'red.600'}
                  >
                    {declinedReport?.totalRevenue?.toLocaleString() || 0}
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    {declinedReport?.count?.toLocaleString() || 0} cancelled
                    orders
                  </Text>
                </>
              )}
            </Flex>
          </Card>
          <Card>
            <Text>Discounts from restaurant</Text>
            <Flex
              py={6}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              {loadingReport ? (
                <Spinner />
              ) : (
                <>
                  <Text fontSize="4xl" fontWeight="bold">
                    {completedReport?.totalDiscount?.toLocaleString() || 0}
                  </Text>
                </>
              )}
            </Flex>
          </Card>
          <Card>
            <Text>Food Street Promo Discounts</Text>
            <Flex
              py={6}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              {loadingReport ? (
                <Spinner />
              ) : (
                <>
                  <Text fontSize="4xl" fontWeight="bold">
                    {completedReport?.totalCouponDiscount?.toLocaleString() ||
                      0}
                  </Text>
                </>
              )}
            </Flex>
          </Card>
          <Card>
            <Text>Food Street Dine-In Discounts</Text>
            <Flex
              py={6}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              {loadingReport ? (
                <Spinner />
              ) : (
                <>
                  <Text fontSize="4xl" fontWeight="bold">
                    {completedReport?.totalQrCodeDiscount?.toLocaleString() ||
                      0}
                  </Text>
                </>
              )}
            </Flex>
          </Card>
          <Card>
            <Text>Delivery fees</Text>
            <Flex
              py={6}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              {loadingReport ? (
                <Spinner />
              ) : (
                <>
                  <Text fontSize="4xl" fontWeight="bold">
                    {completedReport?.totalDelivery?.toLocaleString() || 0}
                  </Text>
                </>
              )}
            </Flex>
            {!loading && totalDeliveryByBykea && (
              <HStack justify="center" fontSize="sm" spacing={5}>
                <VStack spacing={0}>
                  <Box
                    textTransform="uppercase"
                    fontWeight="bold"
                    fontSize="xs"
                  >
                    bykea
                  </Box>
                  <Box>Rs. {totalDeliveryByBykea.toLocaleString() || 0}</Box>
                </VStack>
              </HStack>
            )}
          </Card>
          <Card>
            <Text>Taxes</Text>
            <Flex
              py={6}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              {loadingReport ? (
                <Spinner />
              ) : (
                <>
                  <Text fontSize="4xl" fontWeight="bold">
                    {completedReport?.totalTax?.toLocaleString() || 0}
                  </Text>
                </>
              )}
            </Flex>
          </Card>
          {selectedRestaurant && (
            <Card>
              <Text>Commission ({formValues?.comission || 0}%)</Text>
              <Flex
                py={6}
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                {loadingReport ? (
                  <Spinner />
                ) : (
                  <>
                    <Text fontSize="4xl" fontWeight="bold">
                      {totalBalance.toLocaleString()}
                    </Text>
                    <Text fontSize="sm" color="gray.600">
                      Payable by{' '}
                      {totalBalance < 0 ? 'Food Street' : 'Restaurant'}
                    </Text>
                  </>
                )}
              </Flex>
            </Card>
          )}
        </Grid>
      </VStack>
      <GenerateInvoiceModal
        isOpen={isInvoiceModalOpen}
        onClose={closeInvoiceModal}
        restaurant={selectedRestaurant}
        completedReport={{
          ...completedReport,
          totalCommission,
          totalBalance,
          amountPayableByBykea,
          onlineAmount,
          onlineDues,
          onlineQty,
        }}
        commissionPerc={formValues.comission}
        invoiceFrom={formValues.from}
        invoiceTo={formValues.to}
      />
      <ConfirmationModal
        isOpen={isConfirmUpdateModalOpen}
        title="Confirm update?"
        description={`Are you sure you want to mark this invoice #${currentInvoiceId} as paid?`}
        onClose={closeConfirmUpdateModal}
        onSubmit={() => {
          closeConfirmUpdateModal();
          markInvoiceAsPaid(currentInvoiceId);
          setCurrentInvoiceId(null);
        }}
      />
      <ConfirmationModal
        isOpen={isConfirmDeleteModalOpen}
        title="Confirm delete?"
        description={`Are you sure you want to delete this invoice #${currentInvoiceId}?`}
        onClose={closeConfirmDeleteModal}
        onSubmit={() => {
          closeConfirmDeleteModal();
          deleteInvoice(currentInvoiceId);
          setCurrentInvoiceId(null);
        }}
      />
    </Box>
  );
};

/* Export
============================================================================= */
export default DashboardScreen;
