import React from 'react';
import { connect } from 'react-redux';
import { Phone } from 'react-feather';
import {
  Text,
  Box,
  Avatar,
  HStack,
  Divider,
} from '@chakra-ui/react';

import { getOrder } from '../../entities/redux/selectors';

/* =============================================================================
<OrderDetailsRiderInfo />
============================================================================= */
const OrderDetailsRiderInfo = ({ order }) => {
  if (order?.rider) {
    const {phone, profile} = order.rider;
    const name = profile
      ? `${profile.firstName} ${profile.lastName}` : '';
    const avatar = profile?.avatar;
  
    return (
      <Box>
        <Divider my={5} />
        <Text fontSize="sm">Delivery Guy</Text>
        <HStack mt={2}>
          <Avatar src={avatar} />
          <Box>
            <Text fontSize="sm">{name}</Text>
            <HStack>
              <Phone size="15px" />
              <Text fontSize="sm">{phone}</Text>
            </HStack>
          </Box>
        </HStack>
      </Box>
    );
  }

  if (order?.partner === 'bykea' && order?.bykea?.partner) {
    const {name, phone} = order.bykea?.partner;

    if (name && phone) {
      return (
        <Box>
          <Divider my={5} />
          <Text fontSize="sm">Delivery Guy</Text>
          <HStack mt={2}>
            <Avatar />
            <Box>
              <Text fontSize="sm">{name}</Text>
              <HStack>
                <Phone size="15px" />
                <Text fontSize="sm">{phone}</Text>
              </HStack>
            </Box>
          </HStack>
        </Box>
      );
    }
  }

  return null;
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.partner === nextProps.order?.partner &&
  prevProps.order?.bykea?.partner?.name === nextProps.order?.bykea?.partner?.name &&
  prevProps.order?.bykea?.partner?.phone === nextProps.order?.bykea?.partner?.phone &&
  prevProps.order?.rider?.profile?.firstName === nextProps.order?.rider?.profile?.firstName
    && prevProps.order?.rider?.profile?.lastName === nextProps.order?.rider?.profile?.lastName
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderDetailsRiderInfo, propsAreEqual));
