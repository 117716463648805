import React from 'react';
import { connect } from 'react-redux';
import { Info, Phone } from 'react-feather';
import {
  Box,
  HStack,
  Text,
  Avatar,
  Button,
} from '@chakra-ui/react';

import { getOrder } from '../../../entities/redux/selectors';

/* =============================================================================
<LiveOrdersCardUser />
============================================================================= */
const LiveOrdersCardUser = ({ order, onDetailsClick }) => {
  const username = order?.customer?.profile
    ? `${order.customer.profile.firstName} ${order.customer.profile.lastName}` : '';
  const avatar = order?.customer?.profile?.avatar;
  const phone = order?.customer?.phone;

  return (
    <HStack mb={2} justify="space-between">
      <HStack>
        <Avatar w="40px" h="40px" src={avatar} />
        <Box>
          <Text fontSize="sm">{username}</Text>
          <HStack>
            <Phone size="15px" />
            <Text fontSize="sm">
              {phone}
            </Text>
          </HStack>
        </Box>
      </HStack>
      <Button
        variant="outline"
        colorScheme="gray"
        onClick={onDetailsClick}
      >
        <Info size="18px" />
        <Text display={['none', 'none', 'block']} ml={2}>Detail</Text>
      </Button>
    </HStack>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.customer?.profile?.firstName === nextProps.order?.customer?.profile?.firstName
    && prevProps.order?.customer?.profile?.lastName === nextProps.order?.customer?.profile?.lastName
    && prevProps.order?.customer?.profile?.avatar === nextProps.order?.customer?.profile?.avatar
    && prevProps.order?.customer?.phone === nextProps.order?.customer?.phone
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(LiveOrdersCardUser, propsAreEqual));
