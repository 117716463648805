import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Tr,
  Td,
} from '@chakra-ui/react';

import { getWalletTransaction } from '../../../../entities/redux/selectors';

/* =============================================================================
<WalletTransactionsTableItem />
============================================================================= */
const WalletTransactionsTableItem = ({ transaction }) => {
  const type = transaction?.type;
  const amount = transaction?.amount?.toFixed(2);
  const description = transaction?.description;
  const time = moment(transaction?.createdAt).format('LT');
  const date = moment(transaction?.createdAt).format('L');
  const isCredit = type === '+';

  return (
    <Tr>
      <Td>{isCredit ? 'Credit' : 'Debit'}</Td>
      <Td color={isCredit ? 'green.500' : 'red.500'}>Rs.{amount}</Td>
      <Td>{description}</Td>
      <Td>
        {time}
        <br />
        {date}
      </Td>
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  transaction: getWalletTransaction(state, { id }),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.id === nextProps.id
  && prevProps.transaction?.type === nextProps.transaction?.type
  && prevProps.transaction?.amount === nextProps.transaction?.amount
  && prevProps.transaction?.description === nextProps.transaction?.description
  && prevProps.transaction?.createdAt?.toString() === nextProps.transaction?.createdAt?.toString();

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(WalletTransactionsTableItem, propsAreEqual));
