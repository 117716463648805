import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Edit2, Trash2 } from 'react-feather';
import {
  Text,
  Spinner,
  ListItem,
  useToast,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';

import { ConfirmationModal } from '../../../common';
import DiscountEditModal from '../DiscountModals/DiscountEditModal/index';

import { getDiscount } from '../../../entities/redux/selectors';
import { deleteDiscount as deleteDiscountAction } from '../../redux/actions';

/* =============================================================================
<DiscountsListItem />
============================================================================= */
const DiscountsListItem = ({
  id,
  discount,
  selected,
  onClick,
  deleteDiscount,
}) => {
  const toast = useToast();
  const [deleting, setDeleting] = useState(false);
  const {
    isOpen: isConfirmationModalOpen,
    onClose: onConfirmationModalClose,
    onOpen: onConfirmationModalOpen,
  } = useDisclosure();
  const {
    isOpen: isEditModalOpen,
    onClose: onEditModalClose,
    onOpen: onEditModalOpen,
  } = useDisclosure();

  const title = discount?.name;
  const textColor = selected ? '#fff' : '#707070';

  const _handleClick = (e) => {
    onClick(e, id);
  };

  const _handleConfirmationModalSubmit = () => {
    setDeleting(true);
    onConfirmationModalClose();
    deleteDiscount(id, (err) => {
      if (err) {
        toast({
          title: 'Error occured.',
          description: err?.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'discount deleted.',
          description: 'discount deleted successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
      setDeleting(false);
    });
  };

  return (
    <ListItem
      height={height}
      display="flex"
      alignItems="center"
      pr={3}
      pl={1}
      mb={4}
      borderRadius="lg"
      boxShadow="md"
      bg={selected ? 'purple.500' : 'gray.50'}
      onClick={_handleClick}
    >
      <Text mx={4} flex={1} color={textColor} noOfLines={1}>{title}</Text>
      <HStack spacing={4}>
        {!deleting && (
        <Trash2 size={14} color={textColor} onClick={onConfirmationModalOpen} />
        )}
        {deleting && (
        <Spinner size="sm" color="red.500" />
        )}
        {!deleting && (
          <Edit2 size={14} color={textColor} onClick={onEditModalOpen} />
        )}
      </HStack>
      <DiscountEditModal id={id} isOpen={isEditModalOpen} onClose={onEditModalClose} />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={onConfirmationModalClose}
        onSubmit={_handleConfirmationModalSubmit}
      />
    </ListItem>
  );
};

const height = '48px';

const mapStateToProps = (state, { id }) => ({
  discount: getDiscount(state, { id }),
});

const mapDispatchTopProps = {
  deleteDiscount: deleteDiscountAction,
};

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.id === nextProps.id
  && prevProps.selected === nextProps.selected
  && prevProps.onClick === nextProps.onClick
  && prevProps.discount?.name === nextProps.discount?.name
);

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchTopProps,
)(React.memo(DiscountsListItem, propsAreEqual));
