import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { MoreVertical, Trash2 } from 'react-feather';
import {
  Text,
  Spinner,
  ListItem,
  useToast,
} from '@chakra-ui/react';

import { ConfirmationModal } from '../../../common';

import { getCategory } from '../../../entities/redux/selectors';
import { deleteCategory as deleteCategoryAction } from '../../redux/actions';

/* =============================================================================
<StreetCategoriesListItem />
============================================================================= */
const StreetCategoriesListItem = ({
  id,
  index,
  category,
  selected,
  onClick,
  deleteCategory,
}) => {
  const toast = useToast();
  const [deleting, setDeleting] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const title = category?.name;
  const isSpecial = category?.special;
  const color = (selected || isSpecial) ? '#fff' : '#707070';

  const _toggleConfirmationModal = () => {
    setConfirmationModal((prevState) => !prevState);
  };

  const _handleClick = (e) => {
    if (!isSpecial) {
      onClick(e, id);
    }
  };

  const _handleDeleteClick = (event) => {
    event.stopPropagation();
    if (!isSpecial) {
      _toggleConfirmationModal();
    }
  };

  const _handleConfirmationModalSubmit = () => {
    setDeleting(true);
    _toggleConfirmationModal();
    deleteCategory(id, (err) => {
      if (err) {
        toast({
          title: 'Error occured.',
          description: err?.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Category deleted.',
          description: 'Category deleted successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
      setDeleting(false);
    });
  };

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={provided.draggableProps.style}
          height={height}
          display="flex"
          alignItems="center"
          pr={3}
          pl={1}
          mb={4}
          borderRadius="lg"
          boxShadow="md"
          bg={selected ? 'purple.500' : isSpecial ? 'red.400' : 'gray.50'}
          onClick={_handleClick}
        >
          <div {...provided.dragHandleProps}>
            <MoreVertical color={color} size={14} />
          </div>
          <Text mx={4} flex={1} color={color} noOfLines={1}>{title}</Text>
          {!isSpecial && !deleting && (
            <Trash2 color={color} size={14} onClick={_handleDeleteClick} />
          )}
          {!isSpecial && deleting && (
            <Spinner size="sm" color="red.500" />
          )}
          <ConfirmationModal
            isOpen={confirmationModal}
            onClose={_toggleConfirmationModal}
            onSubmit={_handleConfirmationModalSubmit}
          />
        </ListItem>
      )}
    </Draggable>
  );
};

const height = '48px';

const mapStateToProps = (state, { id }) => ({
  category: getCategory(state, { id }),
});

const mapDispatchTopProps = {
  deleteCategory: deleteCategoryAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchTopProps)(StreetCategoriesListItem);
