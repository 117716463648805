import { actionGenerator } from '../../util/reduxHelpers';

export const CATEGORIES_GET = actionGenerator('STREET/CATEGORIES_GET');
export const CATEGORY_CREATE = actionGenerator('STREET/CATEGORY_CREATE');
export const CATEGORY_UPDATE = actionGenerator('STREET/CATEGORY_UPDATE');
export const CATEGORY_DELETE = actionGenerator('STREET/CATEGORY_DELETE');
export const CATEGORIES_UPDATE = 'STREET/CATEGORIES_UPDATE';
export const RESTAURANTS_GET = actionGenerator('STREET/RESTAURANTS_GET');
export const RESTAURANTS_ADD = actionGenerator('STREET/RESTAURANTS_ADD');
export const RESTAURANT_UPDATE = actionGenerator('STREET/RESTAURANT_UPDATE');
export const RESTAURANT_REMOVE = actionGenerator('STREET/RESTAURANT_REMOVE');
export const RESTAURANTS_UPDATE = 'STREET/RESTAURANTS_UPDATE';
export const SPECIAL_CATEGORY_GET = actionGenerator('STREET/SPECIAL_CATEGORY_GET');
