import React, { useEffect } from 'react';
import { get } from 'lodash';
import { Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { FormInput } from '../../../common';

import { getRestaurant } from '../../../entities/redux/selectors';

/* =============================================================================
<RestaurantFormPhone />
============================================================================= */
const RestaurantFormPhone = ({
  type,
  errors,
  watch,
  setValue,
  register,
}) => {
  const chain = watch('chain');
  const restaurant = useSelector((state) => getRestaurant(state, { id: chain }));
  const isChain = type === 'chain';
  const isBranch = type === 'branch';
  const delivery1 = watch('phone.delivery1');
  const delivery2 = watch('phone.delivery2');

  useEffect(() => {
    if (isBranch && !delivery1) {
      setValue('phone.delivery1', restaurant?.phone?.delivery1);
    }
    if (isBranch && !delivery2) {
      setValue('phone.delivery2', restaurant?.phone?.delivery2);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBranch, restaurant?.phone?.delivery1, restaurant?.phone?.delivery2]);

  return (
    <Stack direction={['column', 'column', 'row']} spacing={4}>
      <FormInput
        mb={6}
        name="phone.delivery1"
        label="Delivery Phone 1"
        error={get(errors, 'phone.delivery1')}
        register={register}
      />
      <FormInput
        mb={6}
        name="phone.delivery2"
        label="Delivery Phone 2"
        error={get(errors, 'phone.delivery2')}
        register={register}
      />
      <FormInput
        mb={6}
        name="phone.branch"
        label={isChain ? 'Head Office Phone' : 'Branch Phone (for personal use)'}
        error={get(errors, 'phone.branch')}
        register={register}
      />
    </Stack>
  );
};

/* Export
============================================================================= */
export default RestaurantFormPhone;
