import { actionGenerator } from '../../util/reduxHelpers';

export const WALLET_FIND = actionGenerator(
  'WALLETS/WALLET_FIND',
);
export const WALLET_CREATE = actionGenerator(
  'WALLETS/WALLET_CREATE',
);
export const WALLET_UPDATE = actionGenerator(
  'WALLETS/WALLET_UPDATE',
);
export const WALLET_TRANSACTIONS_GET = actionGenerator(
  'WALLETS/WALLET_TRANSACTIONS_GET',
);
export const WALLET_TRANSACTIONS_REFRESH = actionGenerator(
  'WALLETS/WALLET_TRANSACTIONS_REFRESH',
);
