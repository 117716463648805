import React from 'react';
import { Flex } from '@chakra-ui/react';
import {QRCodeSVG} from 'qrcode.react';

/* =============================================================================
<QRCodeForm />
============================================================================= */
const QRCodeForm = ({ id }) => (
  <Flex justify="center" mb={6}>
    {Boolean(id) && (
      <QRCodeSVG value={`${FOODSTREET_DYNAMIC_URL}/?link=${encodeURIComponent(`${FOODSTREET_URL}?qr=${id}`)}&apn=${FOODSTREET_ANDROID_PACKAGE_NAME}&ibi=${FOODSTREET_IOS_BUNDLE_ID}`} />
    )}
  </Flex>
);

const FOODSTREET_URL = 'https://foodstreetpk.com';
const FOODSTREET_DYNAMIC_URL = 'https://foodstreet.page.link';
const FOODSTREET_IOS_BUNDLE_ID = 'com.foodstreet';
const FOODSTREET_ANDROID_PACKAGE_NAME = 'com.foodstreet';

/* Export
============================================================================= */
export default QRCodeForm;
