import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import {
  restaurant as restaurantSchema,
  dish as dishSchema,
} from '../../entities/api/schema';

/**
 * RESTAURANTS_GET
 */
export const getRestaurants = (nextCursor, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANTS_GET.REQUEST });

    const payload = await request({
      url: '/restaurants',
      method: 'GET',
      params: {
        next_cursor: nextCursor,
      },
    });
    const { entities, result } = normalize(payload.data, [restaurantSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.RESTAURANTS_GET.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.RESTAURANTS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RESTAURANTS_GET.COMPLETE });
  }
};

/**
 * RESTAURANTS_REFRESH
 */
export const refreshRestaurants = (query, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANTS_REFRESH.REQUEST });

    const payload = await request({
      url: '/restaurants',
      method: 'GET',
      params: {
        query,
      },
    });
    const { entities, result } = normalize(payload.data, [restaurantSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.RESTAURANTS_REFRESH.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.RESTAURANTS_REFRESH.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RESTAURANTS_REFRESH.COMPLETE });
  }
};

/**
 * RESTAURANT_GET
 */
export const getRestaurant = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANT_GET.REQUEST });

    const payload = await request({
      url: `/restaurants/${id}`,
      method: 'GET',
    });
    const { entities, result } = normalize(payload, restaurantSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.RESTAURANT_GET.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.RESTAURANT_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RESTAURANT_GET.COMPLETE });
  }
};

/**
 * RESTAURANT_CREATE
 */
export const createRestaurant = (data, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANT_CREATE.REQUEST });

    const payload = await request({
      url: '/restaurants',
      method: 'POST',
      data,
    });
    const { entities, result } = normalize(payload, restaurantSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.RESTAURANT_CREATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.RESTAURANT_CREATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RESTAURANT_CREATE.COMPLETE });
  }
};

/**
 * RESTAURANT_UPDATE
 */
export const updateRestaurant = (restaurant, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANT_UPDATE.REQUEST });

    const payload = await request({
      url: `/restaurants/${restaurant.id}`,
      method: 'PUT',
      data: restaurant,
    });
    const { entities, result } = normalize(payload, restaurantSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.RESTAURANT_UPDATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.RESTAURANT_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RESTAURANT_UPDATE.COMPLETE });
  }
};

/**
 * RESTAURANT_UPDATE
 */
export const getRestaurantDishes = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANT_DISHES_GET.REQUEST });

    const payload = await request({
      url: `/restaurants/${id}/dishes`,
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [dishSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.RESTAURANT_DISHES_GET.SUCCESS,
      payload: {
        restaurant: id,
        dishes: result,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.RESTAURANT_DISHES_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RESTAURANT_DISHES_GET.COMPLETE });
  }
};
