import React from 'react';
import { connect } from 'react-redux';
import { Flex, Box } from '@chakra-ui/react';

import Header from './Header';
import SideNav from './SideNav';
import NavHeader from './NavHeader';

import { getUser } from '../auth/redux/selectors';

/* =============================================================================
<Layout />
============================================================================= */
const Layout = ({ children, authenticated, user }) => (
  <Flex h="100%">
    {authenticated && <SideNav user={user} />}
    <Flex flex={1} flexFlow="column nowrap" pos="relative" overflowY="hidden">
      <Box w="100%" h="50vh" pos="fixed" zIndex={-1} />
      {authenticated && (
        <>
          <Header />
          <NavHeader />
        </>
      )}
      <Flex flex={1} p={8} overflowY="auto" flexFlow="column nowrap">
        {children}
      </Flex>
    </Flex>
  </Flex>
);

const mapStateToProps = (state) => ({
  user: getUser(state),
  authenticated: !!getUser(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps, null)(Layout);
