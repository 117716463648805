import React from 'react';
import { FastField } from 'formik';
import {
  Textarea,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<CreateNotificationBody />
============================================================================= */
const CreateNotificationBody = () => (
  <FastField name="body">
    {({ field, form }) => (
      <FormControl mt={4} isInvalid={form.errors.body && form.touched.body}>
        <FormLabel fontSize="16px" fontWeight="normal">Notification</FormLabel>
        <Textarea w="500px" h="150px" id="body" {...field} />
        <FormErrorMessage>
          <FormErrorIcon />
          {form.errors.body}
        </FormErrorMessage>
      </FormControl>
    )}
  </FastField>
);

/* Export
============================================================================= */
export default CreateNotificationBody;
