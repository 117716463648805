import React from 'react';
import {
  HStack,
  Heading,
  Button,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

/* =============================================================================
<CouponsHeader />
============================================================================= */
const CouponsHeader = () => (
  <HStack justifyContent="space-between" p={6}>
    <Heading fontSize="4xl" fontWeight="normal">Coupons</Heading>
    <Button colorScheme="purple" as={NavLink} to="/coupons/create">Create</Button>
  </HStack>
);

/* Export
============================================================================= */
export default CouponsHeader;
