import React from 'react';
import { connect } from 'react-redux';
import { Box, HStack, Text } from '@chakra-ui/react';

import { getOrder } from '../../entities/redux/selectors';

/* =============================================================================
<OrderDetailsStatus />
============================================================================= */
const OrderDetailsStatus = ({ order }) => {
  const status = order?.status;
  const type = order?.type;
  const amount = order?.payment?.amount;
  const walletAmount = order?.payment?.wallet;
  const paymentMethod = order?.payment?.method;
  const paymentStatus = order?.payment?.status;

  const orderType = ORDER_TYPES[type] || 'Unknown';
  const orderPaymentMethod = paymentMethod === 'card' || walletAmount === amount
    ? 'Online'
    : 'Cash';
  const orderPaymentStatus = paymentStatus === 'success'
    ? 'Paid'
    : 'Pending';

  return (
    <HStack mt={5} alignItems="flex-start" justify="space-between">
      <Box flex={1}>
        <Text fontSize={['xs', 'xs', 'sm']} mb={1}>Order Type</Text>
        <Text
          py={2}
          align="center"
          color="red.700"
          bgColor="red.100"
          borderRadius="40px"
          fontSize={['xs', 'xs', 'md']}
        >
          {orderType}
        </Text>
      </Box>
      <Box flex={1}>
        <Text fontSize={['xs', 'xs', 'sm']} mb={1}>Payment Status</Text>
        <Text
          py={2}
          fontSize={['xs', 'xs', 'md']}
          align="center"
          color={orderPaymentStatus === 'Paid' ? 'green.500' : 'orange.500'}
          borderRadius="40px"
          bgColor={orderPaymentStatus === 'Paid' ? 'green.100' : 'orange.100'}
        >
          {orderPaymentStatus}
        </Text>
      </Box>
      <Box flex={1}>
        <Text fontSize={['xs', 'xs', 'sm']} mb={1}>Payment Method</Text>
        <Text
          py={2}
          fontSize={['xs', 'xs', 'md']}
          color="gray.600"
          align="center"
          borderRadius="40px"
          bgColor="gray.100"
        >
          {orderPaymentMethod}
        </Text>
      </Box>
      <Box flex={1}>
        <Text fontSize={['xs', 'xs', 'sm']} mb={1}>Order Status</Text>
        <Text
          py={2}
          fontSize={['xs', 'xs', 'md']}
          color={(
            status === 'completed'
              ? 'green.500'
              : (status === 'declined' || status === 'canceled')
                ? 'red.500'
                : 'orange.500'
          )}
          align="center"
          textTransform="capitalize"
          borderRadius="40px"
          bgColor={(
            status === 'completed'
              ? 'green.100'
              : (status === 'declined' || status === 'canceled')
                ? 'red.100'
                : 'orange.100'
          )}
        >
          {status}
        </Text>
      </Box>
    </HStack>
  );
};

const ORDER_TYPES = {
  gift: 'Gift',
  dine_in: 'Dine In',
  delivery: 'Delivery',
  take_away: 'Takeaway',
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.status === nextProps.order?.status
  && prevProps.order?.type === nextProps.order?.type
  && prevProps.order?.payment?.method === nextProps.order?.payment?.method
  && prevProps.order?.payment?.status === nextProps.order?.payment?.status
  && prevProps.order?.payment?.amount === nextProps.order?.payment?.amount
  && prevProps.order?.payment?.wallet === nextProps.order?.payment?.wallet
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderDetailsStatus, propsAreEqual));
