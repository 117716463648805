import { useEffect } from 'react';
import io from 'socket.io-client';
import { connect } from 'react-redux';
import { normalize } from 'normalizr';

import { order as orderSchema } from '../../entities/api/schema';
import { API_URL } from '../../config/secrets';

import { getUser } from '../../auth/redux/selectors';
import { addOrder as addOrderAction } from '../redux/actions';
import { addEntities as addEntitiesAction } from '../../entities/redux/actions';

/* =============================================================================
<LiveOrdersListener />
============================================================================= */
const LiveOrdersListener = ({
  authenticated,
  addOrder,
  addEntities,
}) => {
  // Listen for new orders and updates
  useEffect(() => { // eslint-disable-line consistent-return
    if (authenticated) {
      const socket = io(`${API_URL}/orders`, {
        auth: {
          token: localStorage.getItem('@foodstreet/token'),
        },
        transports: ['websocket']
      });

      // Order create listener
      socket.on('create', (payload) => {
        const { entities, result } = normalize(payload, orderSchema);
        addEntities(entities);
        addOrder(result);
        audio.play();
      });

      // Order update listener
      socket.on('update', (payload) => {
        const { entities } = normalize(payload, orderSchema);
        addEntities(entities);
      });

      return () => {
        socket.off('create');
        socket.off('update');
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  return null;
};

const audio = new Audio('/audios/new_order.mp3');

const mapStateToProps = (state) => ({
  authenticated: Boolean(getUser(state)),
});

const mapDispatchToProps = {
  addOrder: addOrderAction,
  addEntities: addEntitiesAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchToProps)(LiveOrdersListener);
