import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Tr,
  Td,
  Text,
  Button,
  useToast,
} from '@chakra-ui/react';

import { getNotification } from '../../../entities/redux/selectors';
import { deleteNotification as deleteNotificationAction } from '../../redux/actions';

/* =============================================================================
<NotificationTableItem />
============================================================================= */
const NotificationTableItem = ({
  id,
  notification,
  deleteNotification,
}) => {
  const toast = useToast();
  const [deleting, setDeleting] = useState(false);

  const title = notification?.title;
  const body = notification?.body;
  const screen = notification?.screen;
  const delivery = notification?.delivery;
  const createdAt = moment(notification?.createdAt).format('MMMM Do YYYY, h:mm:ss a');
  const timestamp = delivery === 'schedule' ? moment(notification?.timestamp).format('MMMM Do YYYY, h:mm:ss a') : '--';
  const isCancellable = delivery === 'schedule' && new Date(notification?.timestamp).getTime() > Date.now();

  const _handleDeleteClick = async () => {
    setDeleting(true);
    await deleteNotification(id, (err) => {
      if (err) {
        toast({
          title: 'Error',
          status: 'error',
          duration: 3000,
          isClosable: true,
          description: err.message,
        });
      } else {
        toast({
          title: 'Notification Canceled Successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    });
    setDeleting(false);
  };

  return (
    <Tr>
      <Td>{title}</Td>
      <Td>
        <Text noOfLines={2}>{body}</Text>
      </Td>
      <Td>{screen}</Td>
      <Td>{createdAt}</Td>
      <Td>{timestamp}</Td>
      <Td>
        {isCancellable ? (
          <Button
            isLoading={deleting}
            colorScheme="green"
            onClick={_handleDeleteClick}
          >
            Cancel
          </Button>
        ) : '--'}
      </Td>
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  notification: getNotification(state, { id }),
});

const mapDispatchToProps = {
  deleteNotification: deleteNotificationAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.id === nextProps.id
  && prevProps.notification?.body === nextProps.notification?.body
  && prevProps.notification?.title === nextProps.notification?.title
  && prevProps.notification?.screen === nextProps.notification?.screen
  && prevProps.notification?.delivery === nextProps.notification?.delivery
  && prevProps.notification?.createdAt === nextProps.notification?.createdAt
  && prevProps.notification?.timestamp === nextProps.notification?.timestamp;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(NotificationTableItem, propsAreEqual));
