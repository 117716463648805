import * as constants from './constants';

export const INITIAL_STATE = {
  coupons: [],
  nextCursor: '',
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // COUPONS_GET
    case constants.COUPONS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.COUPONS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.COUPONS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        coupons: [...new Set([...state.coupons, ...payload.data])],
        nextCursor: payload.nextCursor,
      };
    case constants.COUPONS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // COUPONS_REFRESH
    case constants.COUPONS_REFRESH.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.COUPONS_REFRESH.FAIL:
      return {
        ...state,
        error,
      };
    case constants.COUPONS_REFRESH.SUCCESS:
      return {
        ...state,
        error: null,
        coupons: payload.data,
        nextCursor: payload.nextCursor,
      };
    case constants.COUPONS_REFRESH.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // COUPON_GET
    case constants.COUPON_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.COUPON_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.COUPON_GET.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.COUPON_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // COUPON_CREATE
    case constants.COUPON_CREATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.COUPON_CREATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.COUPON_CREATE.SUCCESS:
      return {
        ...state,
        error: null,
        coupons: [payload, ...state.coupons],
      };
    case constants.COUPON_CREATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // COUPON_UPDATE
    case constants.COUPON_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.COUPON_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.COUPON_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.COUPON_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // COUPON_DELETE
    case constants.COUPON_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.COUPON_DELETE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.COUPON_DELETE.SUCCESS:
      return {
        ...state,
        error: null,
        coupons: state.coupons.filter((id) => id !== payload),
      };
    case constants.COUPON_DELETE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
