import React, { useState } from 'react';
import { AsyncSelect } from 'chakra-react-select';
import {
  Center,
  Button,
  Select,
  FormLabel,
  FormControl,
} from '@chakra-ui/react';

import request from '../../../util/request';

/* =============================================================================
<StreetSpecialBannerAddRestaurant />
============================================================================= */
const StreetSpecialBannerAddRestaurant = ({
  bannerOptions,
  banner,
  onBannerChange,
  onAddRestaurant,
}) => {
  const [restaurant, setRestaurant] = useState(null);
  const [saving, setSaving] = useState(false);

  const _handleLoadRestaurants = async (value) => {
    try {
      const payload = await request({
        url: `/restaurants/find?q=${value}`,
        method: 'GET',
      });

      return payload.data.map((_restaurant) => ({
        label: _restaurant.name,
        value: _restaurant.id,
      }));
    } catch (e) {
      return [];
    }
  };

  const _handleRestaurantsChange = (value) => {
    setRestaurant(value);
  };

  const _handleSubmit = async () => {
    if (restaurant) {
      setSaving(true);
      await onAddRestaurant(restaurant.value);
      setSaving(false);
    }
  };

  return (
    <>
      <FormControl>
        <FormLabel htmlFor="banner">Special Banner</FormLabel>
        <Select
          id="banner"
          value={banner}
          placeholder="Select Banner"
          onChange={(e) => onBannerChange(e.target.value)}
        >
          {bannerOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl my={4}>
        <FormLabel htmlFor="restaurants">Enter Restaurant</FormLabel>
        <AsyncSelect
          id="restaurants"
          cacheOptions
          loadOptions={_handleLoadRestaurants}
          onChange={_handleRestaurantsChange}
        />
      </FormControl>
      <Center>
        <Button
          type="submit"
          colorScheme="purple"
          isLoading={saving}
          mb={4}
          onClick={_handleSubmit}
        >
          Add
        </Button>
      </Center>
    </>
  );
};

/* Export
============================================================================= */
export default StreetSpecialBannerAddRestaurant;
