import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import { qrCode as qrCodeSchema } from '../../entities/api/schema';

/**
 * QRCODES_GET
 */
export const getQRCodes = (nextCursor, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.QRCODES_GET.REQUEST });

    const payload = await request({
      url: '/qrcodes',
      method: 'GET',
      params: {
        next_cursor: nextCursor,
      },
    });
    const { entities, result } = normalize(payload.data, [qrCodeSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.QRCODES_GET.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.QRCODES_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.QRCODES_GET.COMPLETE });
  }
};

/**
 * QRCODES_REFRESH
 */
export const refreshQRCodes = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.QRCODES_REFRESH.REQUEST });

    const payload = await request({
      url: '/qrcodes',
      method: 'GET',
    });

    const { entities, result } = normalize(payload.data, [qrCodeSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.QRCODES_REFRESH.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.QRCODES_REFRESH.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.QRCODES_REFRESH.COMPLETE });
  }
};

/**
 * QRCODE_GET
 */
export const getQRCode = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.QRCODE_GET.REQUEST });

    const payload = await request({
      url: `/qrcodes/${id}`,
      method: 'GET',
    });

    const { entities } = normalize(payload, qrCodeSchema);

    dispatch(addEntities(entities));
    dispatch({type: constants.QRCODE_GET.SUCCESS});
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.QRCODE_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.QRCODE_GET.COMPLETE });
  }
};

/**
 * QRCODE_CREATE
 */
export const createQRCode = (data, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.QRCODE_CREATE.REQUEST });

    const payload = await request({
      url: '/qrcodes',
      method: 'POST',
      data,
    });

    const { entities, result } = normalize(payload, qrCodeSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.QRCODE_CREATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.QRCODE_CREATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.QRCODE_CREATE.COMPLETE });
  }
};

/**
 * QRCODE_UPDATE
 */
export const updateQRCode = (data, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.QRCODE_UPDATE.REQUEST });

    const payload = await request({
      url: `/qrcodes/${data.id}`,
      method: 'PUT',
      data,
    });

    const { entities } = normalize(payload, qrCodeSchema);

    dispatch(addEntities(entities));
    dispatch({type: constants.QRCODE_UPDATE.SUCCESS});
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.QRCODE_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.QRCODE_UPDATE.COMPLETE });
  }
};

/**
 * QRCODE_DELETE
 */
export const deleteQRCode = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.QRCODE_DELETE.REQUEST });

    await request({
      url: `/qrcodes/${id}`,
      method: 'DELETE',
    });

    dispatch({
      type: constants.QRCODE_DELETE.SUCCESS,
      payload: id,
    });
    if (typeof cb === 'function') {
      cb(null, id);
    }
  } catch (error) {
    dispatch({
      type: constants.QRCODE_DELETE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.QRCODE_DELETE.COMPLETE });
  }
};
