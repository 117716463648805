import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Tr,
  Td,
  Button,
  useDisclosure,
  Tag,
  Text,
  HStack,
} from '@chakra-ui/react';
import { getOrder } from '../../../entities/redux/selectors';

import OrderDetailsModal from '../../../common/OrderDetailsModal';

/* =============================================================================
<OrdersTableItem />
============================================================================= */
const OrdersTableItem = ({ id, order }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  if (order) {
    const customerName = order.customer?.profile?.firstName || '-';
    const customerPhone = order.customer?.phone || '-';
    const restaurantName =
      order.restaurant.name || order.restaurant.chain?.name;
    const restaurantBranch = order.restaurant.address.branch;
    const restaurantPhone = order.restaurant.phone.branch;
    const orderStatus = order.status;
    const createdAt = moment(order.createdAt).fromNow();
    return (
      <Tr key={id}>
        <Td>
          <HStack>
            {order.callOrder && <Tag colorScheme="blue">Call Order</Tag>}
            {order.partner === 'bykea' && <Tag colorScheme="green">Bykea</Tag>}
            {order.payment.method !== 'cod' && (
              <Tag colorScheme="purple" whiteSpace="nowrap">
                Online Paid
              </Tag>
            )}
          </HStack>
          <Text>{customerName}</Text>
        </Td>
        <Td>{customerPhone}</Td>
        <Td>
          {restaurantName}
          {restaurantBranch && ` (${restaurantBranch})`}
        </Td>
        <Td>{restaurantPhone}</Td>
        <Td textTransform="capitalize">{orderStatus}</Td>
        <Td>{createdAt}</Td>
        <Td>
          <Button variant="outline" onClick={onOpen}>
            Details
          </Button>
        </Td>
        <OrderDetailsModal id={id} isOpen={isOpen} onClose={onClose} />
      </Tr>
    );
  }
  return null;
};
const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.id === nextProps.id;

/* Export
============================================================================= */
export default connect(mapStateToProps)(
  React.memo(OrdersTableItem, propsAreEqual),
);
