import React from 'react';
import { Th } from '@chakra-ui/react';

/* =============================================================================
<WalletTransactionsTableHeadItem />
============================================================================= */
const WalletTransactionsTableHeadItem = ({ title }) => (
  <Th bg="gray.100">
    {title}
  </Th>
);

/* Export
============================================================================= */
export default WalletTransactionsTableHeadItem;
