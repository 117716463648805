import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Flex, HStack } from '@chakra-ui/react';

import LiveOrdersHeader from '../components/LiveOrdersHeader';
import LiveOrdersNew from '../components/LiveOrdersNew';
import LiveOrdersAccepted from '../components/LiveOrdersAccepted';
import LiveOrdersOnTheWay from '../components/LiveOrdersOnTheWay';
import LiveOrdersCompleted from '../components/LiveOrdersCompleted';

import { getDaysFilter } from '../redux/selectors';
import { getOrders as getOrdersAction } from '../redux/actions';

/* =============================================================================
<LiveOrdersScreen />
============================================================================= */
const LiveOrdersScreen = ({ days, getOrders }) => {
  // Get orders
  useEffect(() => {
    getOrders(days);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  return (
    <Flex flex={1} overflow="hidden" direction="column">
      <LiveOrdersHeader />
      <HStack
        flex={1}
        overflow="hidden"
        overflowX="auto"
        alignItems="flex-start"
        my={6}
        spacing={5}
      >
        <LiveOrdersNew />
        <LiveOrdersAccepted />
        <LiveOrdersOnTheWay />
        <LiveOrdersCompleted />
      </HStack>
    </Flex>
  );
};

const mapStateToProps = (state) => ({
  days: getDaysFilter(state),
});

const mapDispatchToProps = {
  getOrders: getOrdersAction,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.days === nextProps;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(LiveOrdersScreen, propsAreEqual));
