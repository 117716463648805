import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import { coupon as couponSchema } from '../../entities/api/schema';

/**
 * COUPONS_GET
 */
export const getCoupons = (nextCursor, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.COUPONS_GET.REQUEST });

    const payload = await request({
      url: '/coupons',
      method: 'GET',
      params: {
        next_cursor: nextCursor,
      },
    });
    const { entities, result } = normalize(payload.data, [couponSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.COUPONS_GET.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.COUPONS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.COUPONS_GET.COMPLETE });
  }
};

/**
 * COUPONS_REFRESH
 */
export const refreshCoupons = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.COUPONS_REFRESH.REQUEST });

    const payload = await request({
      url: '/coupons',
      method: 'GET',
    });

    const { entities, result } = normalize(payload.data, [couponSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.COUPONS_REFRESH.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.COUPONS_REFRESH.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.COUPONS_REFRESH.COMPLETE });
  }
};

/**
 * COUPON_GET
 */
export const getCoupon = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.COUPON_GET.REQUEST });

    const payload = await request({
      url: `/coupons/${id}`,
      method: 'GET',
    });

    const { entities } = normalize(payload, couponSchema);

    dispatch(addEntities(entities));
    dispatch({type: constants.COUPON_GET.SUCCESS});
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.COUPON_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.COUPON_GET.COMPLETE });
  }
};

/**
 * COUPON_CREATE
 */
export const createCoupon = (data, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.COUPON_CREATE.REQUEST });

    const payload = await request({
      url: '/coupons',
      method: 'POST',
      data,
    });

    const { entities, result } = normalize(payload, couponSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.COUPON_CREATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.COUPON_CREATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.COUPON_CREATE.COMPLETE });
  }
};

/**
 * COUPON_UPDATE
 */
export const updateCoupon = (data, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.COUPON_UPDATE.REQUEST });

    const payload = await request({
      url: `/coupons/${data.id}`,
      method: 'PUT',
      data,
    });

    const { entities } = normalize(payload, couponSchema);

    dispatch(addEntities(entities));
    dispatch({type: constants.COUPON_UPDATE.SUCCESS});
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.COUPON_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.COUPON_UPDATE.COMPLETE });
  }
};

/**
 * COUPON_DELETE
 */
export const deleteCoupon = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.COUPON_DELETE.REQUEST });

    await request({
      url: `/coupons/${id}`,
      method: 'DELETE',
    });

    dispatch({
      type: constants.COUPON_DELETE.SUCCESS,
      payload: id,
    });
    if (typeof cb === 'function') {
      cb(null, id);
    }
  } catch (error) {
    dispatch({
      type: constants.COUPON_DELETE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.COUPON_DELETE.COMPLETE });
  }
};
