import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Text,
  HStack,
  Avatar,
  Image,
  Stack,
} from '@chakra-ui/react';

import { getPost } from '../../../entities/redux/selectors';

/* =============================================================================
<PostDetailsUserInfo />
============================================================================= */
const PostDetailsUserInfo = ({ post }) => {
  const photo = post?.photo;
  const comment = post?.text;
  const userAvatar = post?.author?.profile?.avatar;
  const userName = `${post?.author?.profile?.firstName} ${post?.author?.profile?.lastName}`;

  return (
    <Box>
      <HStack align="flex-start" justify="space-between">
        <HStack>
          <Avatar src={userAvatar} />
          <Text fontWeight="semibold">{userName}</Text>
        </HStack>
        <Stack w="60%" h="250" borderRadius={10} overflow="hidden">
          <Image src={photo} alt="No Picture" />
        </Stack>
      </HStack>
      <Text
        mt={5}
        mx={6}
        noOfLines={6}
        color="#650202"
      >
        {comment}
      </Text>
    </Box>
  );
};

const mapStateToProps = (state, { id }) => ({
  post: getPost(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.post?.author?.profile?.firstName === nextProps.post?.author?.profile?.firstName
  && prevProps.post?.author?.profile?.lastName === nextProps.post?.author?.profile?.lastName
  && prevProps.post?.author?.profile?.avatar === nextProps.post?.author?.profile?.avatar
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(PostDetailsUserInfo, propsAreEqual));
