import React, { useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { connect } from 'react-redux';

import Card from '../../common/Card';
import PostsTable from '../components/PostsTable';
import PostsHeader from '../components/PostsHeader';
import PostsFooter from '../components/PostsFooter';
import usePagination from '../../hooks/use-pagination';

import { getNextCursor, getPostByType } from '../redux/selectors';
import {
  getPosts as getPostsAction,
  refreshPosts as refreshPostsAction,
} from '../redux/actions';

/* =============================================================================
<PostsScreen />
============================================================================= */
const PostsScreen = ({
  posts,
  getPosts,
  nextCursor,
  refreshPosts,
}) => {
  const {
    next,
    prev,
    jumpTo,
    items,
    canNext,
    canPrev,
  } = usePagination(posts);

  // Get posts
  useEffect(() => {
    refreshPosts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleNext = () => {
    if (canNext) {
      next();
      return;
    }

    if (nextCursor) {
      getPosts(nextCursor, (err) => {
        if (!err) {
          next(posts);
        }
      });
    }
  };

  const _handlePrev = () => {
    if (canPrev) {
      prev();
    }
  };

  const _handleRefresh = () => {
    refreshPosts(() => {
      jumpTo(1);
    });
  };

  return (
    <Card as={Flex} flex={1} overflow="hidden" direction="column" p={0}>
      <PostsHeader onRefresh={_handleRefresh} />
      <PostsTable data={items} />
      <PostsFooter
        canNext={canNext || !!nextCursor}
        canPrev={canPrev}
        onNext={_handleNext}
        onPrev={_handlePrev}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  posts: getPostByType(state),
  nextCursor: getNextCursor(state),
});

const mapDispatchToProps = {
  getPosts: getPostsAction,
  refreshPosts: refreshPostsAction,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.nextCursor === nextProps.nextCursor
  && prevProps.posts.toString() === nextProps.posts.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(PostsScreen, propsAreEqual));
