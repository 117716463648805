/**
 * Get error
 */
export const getError = (state) => state.Wallets.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.Wallets.loading;

/**
 * Get transactions
 */
export const getTransactions = (state) => state.Wallets.transactions;

/**
 * Get wallet transactions
 */
export const getWalletTransactions = (state, {wallet}) =>
  state.Wallets.transactions[wallet]?.data || [];

/**
 * Get wallet transactions next cursor
 */
export const getWalletTransactionsNextCursor = (state, {wallet}) =>
  state.Wallets.transactions[wallet]?.nextCursor;
