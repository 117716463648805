import React from 'react';

import {
  Box,
  User,
  Home,
  Mail,
  Send,
  Gift,
  Percent,
  Settings,
  FileText,
  Maximize,
  MessageSquare,
  Phone,
  Cast,
} from 'react-feather';
import { ROLES } from '../config/roles';

export default [
  {
    to: '/dashboard',
    title: 'Dashboard',
    icon: <Home size="25px" color="#7764E4" />,
    color: 'purple.600',
    allowedRoles: [ROLES.ADMIN],
  },
  {
    to: '/restaurants',
    title: 'Restaurants',
    icon: <Mail size="25px" color="#F53C56" />,
    color: 'purple.600',
    allowedRoles: [ROLES.ADMIN],
  },
  {
    to: '/street',
    title: 'Main Street',
    icon: <Box size="25px" color="skyblue" />,
    color: 'purple.600',
    allowedRoles: [ROLES.ADMIN],
  },
  {
    to: '/deals_promotions',
    title: 'Promotions and Deals',
    icon: <FileText size="25px" color="#FB6340" />,
    color: 'purple.600',
    allowedRoles: [ROLES.ADMIN],
  },
  {
    to: '/timeline_posts',
    title: 'Timeline Posts',
    icon: <Send size="25px" color="#F53C56" />,
    color: 'purple.600',
    allowedRoles: [ROLES.ADMIN],
  },
  {
    to: '/discounts',
    title: 'Discounts',
    icon: <Percent size="25px" color="#FB6340" />,
    color: 'purple.600',
    allowedRoles: [ROLES.ADMIN],
  },
  {
    to: '/notifications',
    title: 'Notifications',
    icon: <MessageSquare size="25px" color="orange" />,
    color: 'purple.600',
    allowedRoles: [ROLES.ADMIN],
  },
  {
    to: '/coupons',
    title: 'Coupons',
    icon: <Gift size="25px" color="#FB6340" />,
    color: 'purple.600',
    allowedRoles: [ROLES.ADMIN],
  },
  {
    to: '/qrcodes',
    title: 'QRCodes',
    icon: <Maximize size="25px" color="#FB6340" />,
    color: 'purple.600',
    allowedRoles: [ROLES.ADMIN],
  },
  // {
  //   to: '/payments',
  //   title: 'Payments',
  //   icon: <Calendar size="25px" color="#FB6340" />,
  //   color: 'purple.600',
  // },
  {
    to: '/customers',
    title: 'Customers',
    icon: <User size="25px" color="skyblue" />,
    color: 'purple.600',
    allowedRoles: [ROLES.ADMIN],
  },
  {
    to: '/orders',
    title: 'Orders',
    icon: <Settings size="25px" color="#FB6340" />,
    color: 'purple.600',
    allowedRoles: [ROLES.ADMIN, ROLES.SUB_ADMIN],
  },
  {
    to: '/care',
    title: 'Call Order',
    icon: <Phone size="25px" color="#FB6340" />,
    color: 'purple.600',
    allowedRoles: [ROLES.ADMIN, ROLES.SUB_ADMIN],
  },
  {
    to: '/live_orders',
    title: 'Live Orders',
    icon: <Cast size="25px" color="#FB6340" />,
    color: 'purple.600',
    allowedRoles: [ROLES.ADMIN, ROLES.SUB_ADMIN],
  },
];
