import React from 'react';
import { connect } from 'react-redux';
import { Link as ReactLink } from 'react-router-dom';
import { RefreshCw } from 'react-feather';
import { HStack, Heading, Button, Spinner, Input } from '@chakra-ui/react';

import { getLoading } from '../redux/selectors';

/* =============================================================================
<RestaurantsHeader />
============================================================================= */
const RestaurantsHeader = ({
  searchQuery,
  onUpdateSearchQuery,
  loading,
  onRefresh,
}) => (
  <HStack justifyContent="space-between" p={6}>
    <HStack spacing={4} flex={1}>
      <Heading fontSize="xl" fontWeight="medium">
        List of Restaurants
      </Heading>
      {loading ? <Spinner /> : <RefreshCw onClick={onRefresh} />}
    </HStack>
    <HStack justifyContent="end" flex={1}>
      <Input
        placeholder="Search"
        value={searchQuery}
        onChange={(e) => onUpdateSearchQuery(e.target.value)}
        // maxW="300px"
      />
      <Button
        as={ReactLink}
        to="/restaurants/new"
        variant="outline"
        colorScheme="purple"
        width="100%"
      >
        Add Restaurant
      </Button>
    </HStack>
  </HStack>
);

const mapStateToProps = (state, { id }) => ({
  loading: getLoading(state, { id }),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(RestaurantsHeader);
