import { useRef, useEffect } from 'react';
import { createCustomEqual } from 'fast-equals';

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a, b) => {
    if (
      isLatLngLiteral(a)
      || a instanceof window.google.maps.LatLng
      || isLatLngLiteral(b)
      || b instanceof window.google.maps.LatLng
    ) {
      return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  },
);

function useDeepCompareMemoize(value) {
  const ref = useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback,
  dependencies,
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

function isLatLngLiteral(obj) {
  return (
    typeof obj === 'object'
    && Number.isFinite(obj.lat)
    && Number.isFinite(obj.lng)
  );
}

export default useDeepCompareEffectForMaps;
