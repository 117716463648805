import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { MoreVertical } from 'react-feather';
import {
  Text,
  Avatar,
  HStack,
  Button,
  ListItem,
  useToast,
} from '@chakra-ui/react';

import { ConfirmationModal } from '../../../common';

import { getRestaurant } from '../../../entities/redux/selectors';
import { removeRestaurant as removeRestaurantAction } from '../../redux/actions';

/* =============================================================================
<StreetRestaurantsListItem />
============================================================================= */
const StreetRestaurantsListItem = ({
  id,
  index,
  category,
  restaurant,
  removeRestaurant,
}) => {
  const toast = useToast();
  const [deleting, setDeleting] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const title = restaurant?.name;
  const photo = restaurant?.photos && restaurant.photos[0];

  const _toggleConfirmationModal = () => {
    setConfirmationModal((prevState) => !prevState);
  };

  const _handleDeleteClick = (event) => {
    event.stopPropagation();
    _toggleConfirmationModal();
  };

  const _handleConfirmationModalSubmit = () => {
    setDeleting(true);
    _toggleConfirmationModal();
    removeRestaurant(category, id, (err) => {
      if (err) {
        toast({
          title: 'Error occured.',
          description: err?.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Restaurant removed.',
          description: 'Restaurant removed successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
      setDeleting(false);
    });
  };

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={provided.draggableProps.style}
          height={height}
          display="flex"
          alignItems="center"
          pr={3}
          pl={1}
          mb={4}
          borderRadius="lg"
          boxShadow="md"
          bg="gray.50"
          overflowX="hidden"
        >
          <div {...provided.dragHandleProps}>
            <MoreVertical size={16} />
          </div>
          <HStack flex={1} mx={4}>
            <Avatar src={photo} />
            <Text flex={1} fontSize="2xl" noOfLines={1}>{title}</Text>
          </HStack>
          <Button
            color="grey"
            fontSize="xs"
            variant="unstyled"
            isLoading={deleting}
            onClick={_handleDeleteClick}
          >
            Remove
          </Button>
          <ConfirmationModal
            isOpen={confirmationModal}
            onClose={_toggleConfirmationModal}
            onSubmit={_handleConfirmationModalSubmit}
          />
        </ListItem>
      )}
    </Draggable>
  );
};

const height = '75px';

const mapStateToProps = (state, { id }) => ({
  restaurant: getRestaurant(state, { id }),
});

const mapDispatchTopProps = {
  removeRestaurant: removeRestaurantAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchTopProps)(StreetRestaurantsListItem);
