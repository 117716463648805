import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import { post as postSchema } from '../../entities/api/schema';

/**
 * TYPE_SET
 */
export const setType = (payload) => ({
  type: constants.TYPE_SET,
  payload,
});

/**
 * POSTS_GET
 */
export const getPosts = (nextCursor, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.POSTS_GET.REQUEST });

    const payload = await request({
      url: '/posts',
      method: 'GET',
      params: {
        next_cursor: nextCursor,
      },
    });
    const { entities, result } = normalize(payload.data, [postSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.POSTS_GET.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.POSTS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.POSTS_GET.COMPLETE });
  }
};

/**
 * POSTS_REFRESH
 */
export const refreshPosts = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.POSTS_REFRESH.REQUEST });

    const payload = await request({
      url: '/posts',
      method: 'GET',
    });

    const { entities, result } = normalize(payload.data, [postSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.POSTS_REFRESH.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.POSTS_REFRESH.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.POSTS_REFRESH.COMPLETE });
  }
};

/**
 * POST_GET
 */
export const getPost = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.POST_GET.REQUEST });

    const payload = await request({
      url: `/posts/${id}`,
      method: 'GET',
    });
    const { entities, result } = normalize(payload, postSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.POST_GET.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.POST_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.POST_GET.COMPLETE });
  }
};

/**
 * POST_UPDATE
 */
export const updatePost = (post, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.POST_UPDATE.REQUEST });

    const payload = await request({
      url: `/posts/${post.id}`,
      method: 'PUT',
      data: post,
    });
    const { entities, result } = normalize(payload, postSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.POST_UPDATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.POST_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.POST_UPDATE.COMPLETE });
  }
};
