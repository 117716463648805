import React from 'react';
import {
  Select,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<FormSelect />
============================================================================= */
const FormSelect = ({
  name,
  label,
  placeholder,
  options,
  error,
  children,
  register,
  selectProps,
  ...rest
}) => (
  <FormControl isInvalid={error} {...rest}>
    {Boolean(label) && (
      <FormLabel>{label}</FormLabel>
    )}
    {children || (
      <Select {...register(name)} {...selectProps}>
        <option value="">{placeholder}</option>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    )}
    <FormErrorMessage>
      <FormErrorIcon />
      {error?.message}
    </FormErrorMessage>
  </FormControl>
);

FormSelect.defaultProps = {
  placeholder: 'Select',
};

/* Export
============================================================================= */
export default FormSelect;
