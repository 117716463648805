import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import { discount as discountSchema } from '../../entities/api/schema';
import { getDiscount as selectDiscount } from '../../entities/redux/selectors';

/**
 * DISCOUNTS_GET
 */
export const getDiscounts = () => async (dispatch) => {
  try {
    dispatch({ type: constants.DISCOUNTS_GET.REQUEST });

    const payload = await request({
      url: '/discounts',
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [discountSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.DISCOUNTS_GET.SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: constants.DISCOUNTS_GET.FAIL,
      error,
    });
  } finally {
    dispatch({ type: constants.DISCOUNTS_GET.COMPLETE });
  }
};

/**
 * DISCOUNT_GET
 */
export const getDiscount = (id) => async (dispatch) => {
  try {
    dispatch({ type: constants.DISCOUNT_GET.REQUEST });

    const payload = await request({
      url: `/discounts/${id}`,
      method: 'GET',
    });
    const { entities } = normalize(payload, discountSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.DISCOUNT_GET.SUCCESS });
  } catch (error) {
    dispatch({
      type: constants.DISCOUNT_GET.FAIL,
      error,
    });
  } finally {
    dispatch({ type: constants.DISCOUNT_GET.COMPLETE });
  }
};

/**
 * DISCOUNT_CREATE
 */
export const createDiscount = (data, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.DISCOUNT_CREATE.REQUEST });

    const payload = await request({
      url: '/discounts',
      method: 'POST',
      data,
    });
    const { entities, result } = normalize(payload, discountSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.DISCOUNT_CREATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.DISCOUNT_CREATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DISCOUNT_CREATE.COMPLETE });
  }
};

/**
 * DISCOUNT_UPDATE
 */
export const updateDiscount = (discount, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.DISCOUNT_UPDATE.REQUEST });

    const payload = await request({
      url: `/discounts/${discount.id}`,
      method: 'PUT',
      data: discount,
    });
    const { entities, result } = normalize(payload, discountSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.DISCOUNT_UPDATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.DISCOUNT_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DISCOUNT_UPDATE.COMPLETE });
  }
};

/**
 * DISCOUNT_DELETE
 */
export const deleteDiscount = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.DISCOUNT_DELETE.REQUEST });

    await request({
      url: `/discounts/${id}`,
      method: 'DELETE',
    });

    dispatch({
      type: constants.DISCOUNT_DELETE.SUCCESS,
      payload: id,
    });
    if (typeof cb === 'function') {
      cb(null, id);
    }
  } catch (error) {
    dispatch({
      type: constants.DISCOUNT_DELETE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DISCOUNT_DELETE.COMPLETE });
  }
};

/**
 * DISCOUNT_DISH_DELETE
 */
export const deleteDiscountDish = (discountId, dishId, cb) => async (dispatch, getState) => {
  try {
    dispatch({ type: constants.DISCOUNT_DISH_DELETE.REQUEST });

    await request({
      url: `/discounts/${discountId}/dishes/${dishId}`,
      method: 'DELETE',
    });

    const discount = selectDiscount(getState(), { id: discountId });

    const { entities } = normalize({
      id: discountId,
      dishes: discount.dishes.filter((dish) => dish.id !== dishId),
    }, discountSchema);

    dispatch(addEntities(entities));

    dispatch({ type: constants.DISCOUNT_DISH_DELETE.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, dishId);
    }
  } catch (error) {
    dispatch({
      type: constants.DISCOUNT_DISH_DELETE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DISCOUNT_DISH_DELETE.COMPLETE });
  }
};
