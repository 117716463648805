import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import { deal as dealSchema, dealItem as dealItemSchema } from '../../entities/api/schema';
import { getDeal as selectDeal } from '../../entities/redux/selectors';

/**
 * DEALS_GET
 */
export const getDeals = () => async (dispatch) => {
  try {
    dispatch({ type: constants.DEALS_GET.REQUEST });

    const payload = await request({
      url: '/deals',
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [dealSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.DEALS_GET.SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: constants.DEALS_GET.FAIL,
      error,
    });
  } finally {
    dispatch({ type: constants.DEALS_GET.COMPLETE });
  }
};

/**
 * DEAL_GET
 */
export const getDeal = (id) => async (dispatch) => {
  try {
    dispatch({ type: constants.DEAL_GET.REQUEST });

    const payload = await request({
      url: `/deals/${id}`,
      method: 'GET',
    });
    const { entities } = normalize(payload, dealSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.DEAL_GET.SUCCESS });
  } catch (error) {
    dispatch({
      type: constants.DEAL_GET.FAIL,
      error,
    });
  } finally {
    dispatch({ type: constants.DEAL_GET.COMPLETE });
  }
};

/**
 * DEAL_CREATE
 */
export const createDeal = (data, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.DEAL_CREATE.REQUEST });

    const payload = await request({
      url: '/deals',
      method: 'POST',
      data,
    });
    const { entities, result } = normalize(payload, dealSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.DEAL_CREATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.DEAL_CREATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DEAL_CREATE.COMPLETE });
  }
};

/**
 * DEAL_UPDATE
 */
export const updateDeal = (deal, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.DEAL_UPDATE.REQUEST });

    const payload = await request({
      url: `/deals/${deal.id}`,
      method: 'PUT',
      data: deal,
    });
    const { entities, result } = normalize(payload, dealSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.DEAL_UPDATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.DEAL_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DEAL_UPDATE.COMPLETE });
  }
};

/**
 * DEAL_DELETE
 */
export const deleteDeal = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.DEAL_DELETE.REQUEST });

    await request({
      url: `/deals/${id}`,
      method: 'DELETE',
    });

    dispatch({
      type: constants.DEAL_DELETE.SUCCESS,
      payload: id,
    });
    if (typeof cb === 'function') {
      cb(null, id);
    }
  } catch (error) {
    dispatch({
      type: constants.DEAL_DELETE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DEAL_DELETE.COMPLETE });
  }
};

/**
 * DEALS_UPDATE
 */
export const updateDeals = (payload) => ({
  type: constants.DEALS_UPDATE,
  payload,
});

/**
 * DEAL_ITEM_CREATE
 */
export const createDealItem = (data, id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.DEAL_ITEM_CREATE.REQUEST });

    const payload = await request({
      url: `/deals/${id}/items`,
      method: 'POST',
      data,
    });
    const { entities: dealEntities } = normalize(payload, dealSchema);
    const { entities: dealItemEntities } = normalize(payload.items, [dealItemSchema]);

    dispatch(addEntities(dealEntities));
    dispatch(addEntities(dealItemEntities));
    dispatch({ type: constants.DEAL_ITEM_CREATE.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.DEAL_ITEM_CREATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DEAL_ITEM_CREATE.COMPLETE });
  }
};

/**
 * DEAL_ITEM_DELETE
 */
export const deleteDealItem = (dealId, dealItemId, cb) => async (dispatch, getState) => {
  try {
    dispatch({ type: constants.DEAL_ITEM_DELETE.REQUEST });

    await request({
      url: `/deals/${dealId}/items/${dealItemId}`,
      method: 'DELETE',
    });
    const deal = selectDeal(getState(), { id: dealId });

    const { entities } = normalize({
      id: dealId,
      items: deal.items.filter((dish) => dish.id !== dealItemId),
    }, dealSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.DEAL_ITEM_DELETE.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, dealItemId);
    }
  } catch (error) {
    dispatch({
      type: constants.DEAL_ITEM_DELETE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DEAL_ITEM_DELETE.COMPLETE });
  }
};

/**
 * DEAL_ON_STREET_UPDATE
 */
export const updateOnStreetDeal = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.DEAL_ON_STREET_UPDATE.REQUEST });

    const payload = await request({
      url: `/deals/${id}/on_street`,
      method: 'PUT',
    });

    if (payload) {
      const { entities } = normalize(payload, dealSchema);
      dispatch(addEntities(entities));
    }

    dispatch({ type: constants.DEAL_ON_STREET_UPDATE.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, id);
    }
  } catch (error) {
    dispatch({
      type: constants.DEAL_ON_STREET_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DEAL_ON_STREET_UPDATE.COMPLETE });
  }
};
