import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Heading, useToast } from '@chakra-ui/react';

import {Card} from '../../common';
import QRCodeForm from '../components/QRCodeForm';

import {
  getQRCode as getQRCodeAction,
  updateQRCode as updateQRCodeAction,
} from '../redux/actions';

/* =============================================================================
<EditQRCodeScreen />
============================================================================= */
const EditQRCodeScreen = ({getQRCode, updateQRCode}) => {
  const toast = useToast();
  const {id} = useParams();

  // Get coupon
  useEffect(() => {
    if (id) {
      getQRCode(id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const _handleSubmit = () => async (values) => {
    await updateQRCode({...values, id}, (err) => {
      if (err) {
        toast({
          title: 'Error',
          status: 'error',
          duration: 3000,
          isClosable: true,
          description: err.message,
        });
      } else {
        toast({
          title: 'QRCode updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
  <Card flex={1} overflow="auto">
    <Heading fontSize="4xl" fontWeight="semibold">Edit QRCode</Heading>
    <QRCodeForm id={id} onSubmit={_handleSubmit} />
  </Card>
);
  };

const mapDispatchToProps = {
  getQRCode: getQRCodeAction,
  updateQRCode: updateQRCodeAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(EditQRCodeScreen);
