import { actionGenerator } from '../../util/reduxHelpers';

export const QRCODES_GET = actionGenerator(
  'QRCODES/QRCODES_GET',
);

export const QRCODES_REFRESH = actionGenerator(
  'QRCODES/QRCODES_REFRESH',
);

export const QRCODE_GET = actionGenerator(
  'QRCODES/QRCODE_GET',
);

export const QRCODE_CREATE = actionGenerator(
  'QRCODES/QRCODE_CREATE',
);

export const QRCODE_UPDATE = actionGenerator(
  'QRCODES/QRCODE_UPDATE',
);

export const QRCODE_DELETE = actionGenerator(
  'QRCODES/QRCODE_DELETE',
);
