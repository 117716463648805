/**
 * Get error
 */
export const getError = (state) => state.Discounts.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.Discounts.loading;

/**
 * Get discounts
 */
export const getDiscounts = (state) => state.Discounts.discounts;
