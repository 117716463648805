import React, { useCallback, useState } from 'react';
import { HStack } from '@chakra-ui/react';

import DealsList from '../components/DealsList';
import DealItemsList from '../components/DealItemsList';

/* =============================================================================
<DealsScreen />
============================================================================= */
const DealsScreen = () => {
  const [selectedDeal, setSelectedDeal] = useState(null);

  const _handleItemClick = useCallback((e, id) => {
    setSelectedDeal(id);
  }, []);

  return (
    <HStack flex={1} align="stretch" overflowY="hidden" spacing={5}>
      <DealsList
        selected={selectedDeal}
        onItemClick={_handleItemClick}
      />
      <DealItemsList id={selectedDeal} />
    </HStack>
  );
};

/* Export
============================================================================= */
export default DealsScreen;
