import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import { user as userSchema } from '../../entities/api/schema';

/**
 * USER_GET
 */
export const getUser = () => async (dispatch) => {
  try {
    dispatch({ type: constants.USER_GET.REQUEST });

    const payload = await request({
      url: '/users/me',
      method: 'GET',
    });
    const { entities, result } = normalize(payload, userSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.USER_GET.SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: constants.USER_GET.FAIL,
      error,
    });
    if (error === 'Unauthorized') {
      dispatch(logout());
    }
  } finally {
    dispatch({ type: constants.USER_GET.COMPLETE });
  }
};

/**
 * LOGIN
 */
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: constants.LOGIN.REQUEST });

    const payload = await request({
      url: '/auth/login',
      method: 'POST',
      data: {
        email,
        password,
      },
    });
    window.localStorage.setItem('@foodstreet/token', payload.token);
    const { entities, result } = normalize(payload.user, userSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.LOGIN.SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: constants.LOGIN.FAIL,
      error,
    });
  } finally {
    dispatch({ type: constants.LOGIN.COMPLETE });
  }
};

/**
 * PASSWORD_RESET_CODE_SEND
 */
export const sendPasswordResetCode = (email, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.PASSWORD_RESET_CODE_SEND.REQUEST });

    const payload = await request({
      url: '/auth/reset/send_verification_code',
      method: 'POST',
      data: { email },
    });

    dispatch({ type: constants.PASSWORD_RESET_CODE_SEND.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.PASSWORD_RESET_CODE_SEND.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.PASSWORD_RESET_CODE_SEND.COMPLETE });
  }
};

/**
 * PASSWORD_RESET_CODE_VERIFICATION
 */
export const verifyPasswordResetCode =
  (email, code, cb) => async (dispatch) => {
    try {
      dispatch({ type: constants.PASSWORD_RESET_CODE_VERIFICATION.REQUEST });

      const payload = await request({
        url: '/auth/reset/verify_code',
        method: 'POST',
        data: {
          email,
          code,
        },
      });

      if (payload.status !== 'approved') {
        throw new Error('Invalid code provided');
      }

      dispatch({ type: constants.PASSWORD_RESET_CODE_VERIFICATION.SUCCESS });
      if (typeof cb === 'function') {
        cb(null, payload);
      }
    } catch (error) {
      dispatch({
        type: constants.PASSWORD_RESET_CODE_VERIFICATION.FAIL,
        error,
      });
      if (typeof cb === 'function') {
        cb(error);
      }
    } finally {
      dispatch({ type: constants.PASSWORD_RESET_CODE_VERIFICATION.COMPLETE });
    }
  };

/**
 * PASSWORD_RESET
 */
export const resetPassword = (password, token, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.PASSWORD_RESET.REQUEST });

    const payload = await request({
      url: '/auth/reset/create_password',
      method: 'POST',
      data: {
        password,
        token,
      },
    });
    window.localStorage.setItem('@foodstreet/token', payload.token);
    const { entities, result } = normalize(payload.user, userSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.PASSWORD_RESET.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.PASSWORD_RESET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.PASSWORD_RESET.COMPLETE });
  }
};

/**
 * LOGOUT
 */
export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: constants.LOGOUT.REQUEST });

    window.localStorage.removeItem('@foodstreet/token');

    dispatch({ type: constants.LOGOUT.SUCCESS });
  } catch (error) {
    dispatch({
      type: constants.LOGOUT.FAIL,
      error,
    });
  } finally {
    dispatch({ type: constants.LOGOUT.COMPLETE });
  }
};

/**
 * ERROR_SET
 */
export const setError = (payload) => ({
  type: constants.ERROR_SET,
  payload,
});
