import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import ReactPickyDateTime from 'react-picky-date-time';
import {
  Button,
  HStack,
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
} from '@chakra-ui/react';
import moment from 'moment';

/* =============================================================================
<NotificationTiming />
============================================================================= */
const NotificationTiming = ({ onNextClick }) => {
  const { values, setFieldValue } = useFormikContext();
  const delivery = values?.delivery;
  const [date, setDate] = useState();
  const [second, setSecond] = useState();
  const [minute, setMinute] = useState();
  const [hour, setHour] = useState();
  const [meridiem, setMeridiem] = useState(moment().format('A'));
  const disabled = !date || !second || !minute || !hour || !meridiem;

  const _handleNextClick = () => {
    if (delivery === 'instant') {
      setFieldValue('scheduledAt', '');
      onNextClick();
      return;
    }
    if (!disabled) {
      // eslint-disable-next-line no-shadow
      const dateAndTime = `${date.month}/${date.date}/${date.year} ${hour.value}:${minute.value}:${second.value} ${meridiem}`;
      setFieldValue('scheduledAt', dateAndTime);
      onNextClick();
    }
  };

  return (
    <HStack align="flex-end">
      <FormControl>
        <FormLabel fontSize="16px" fontWeight="semibold">Choose Date and Time</FormLabel>
        <Field>
          {({ form }) => (
            <FormControl>
              <RadioGroup
                mt={6}
                size="lg"
                colorScheme="purple"
                value={form.values.delivery}
                onChange={form.handleChange('delivery')}
              >
                <Radio value="instant" mr={5}>Now</Radio>
                <Radio value="schedule">Schedule</Radio>
              </RadioGroup>
            </FormControl>
          )}
        </Field>
        {delivery === 'schedule' && (
          <ReactPickyDateTime
            show
            size="m"
            mode={1}
            locale="en-us"
            defaultTime={moment().format('hh:mm:ss A')}
            defaultDate={moment().format('MM/DD/YYYY')}
            onDatePicked={(res) => setDate(res)}
            onSecondChange={(res) => setSecond(res)}
            onMinuteChange={(res) => setMinute(res)}
            onHourChange={(res) => setHour(res)}
            onMeridiemChange={(res) => setMeridiem(res)}
          />
        ) }
      </FormControl>
      <Button
        size="lg"
        colorScheme="green"
        disabled={delivery === 'schedule' ? disabled : false}
        onClick={_handleNextClick}
      >
        Next
      </Button>
    </HStack>
  );
};

/* Export
============================================================================= */
export default NotificationTiming;
