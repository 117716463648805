import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';

import { getDeal } from '../../../../entities/redux/selectors';

/* =============================================================================
<DealEditModalInitialize />
============================================================================= */
const DealEditModalInitialize = ({ deal }) => {
  const { resetForm } = useFormikContext();

  const id = deal?.id;
  const name = deal?.name;
  const type = deal?.type;
  const view = deal?.view;
  const aspectRatio = deal?.aspectRatio;

  // Set default values
  useEffect(() => {
    resetForm({
      values: {
        name,
        view,
        type,
        aspectRatio,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    name,
    view,
    type,
    aspectRatio,
  ]);

  return null;
};

const mapStateToProps = (state, { id }) => ({
  deal: getDeal(state, { id, normalize: true }),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(DealEditModalInitialize);
