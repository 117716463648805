import React, { useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { connect } from 'react-redux';

import Card from '../../../common/Card';
import WalletTransactionsTable from './WalletTransactionsTable';
import WalletTransactionsHeader from './WalletTransactionsHeader';
import WalletTransactionsFooter from './WalletTransactionsFooter';
import usePagination from '../../../hooks/use-pagination';

import {
  getWalletTransactionsNextCursor,
  getWalletTransactions as selectWalletTransactions,
} from '../../redux/selectors';
import {
  getWalletTransactions as getWalletTransactionsAction,
  refreshWalletTransactions as refreshWalletTransactionsAction,
} from '../../redux/actions';

/* =============================================================================
<WalletTransactions />
============================================================================= */
const WalletTransactions = ({
  wallet,
  nextCursor,
  transactions,
  onEditClick,
  getWalletTransactions,
  refreshWalletTransactions,
}) => {
  const {
    next,
    prev,
    jumpTo,
    items,
    canNext,
    canPrev,
  } = usePagination(transactions);

  // Get transactions
  useEffect(() => {
    refreshWalletTransactions(wallet);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  const _handleNext = () => {
    if (canNext) {
      next();
      return;
    }

    if (nextCursor) {
      getWalletTransactions(wallet, nextCursor, (err) => {
        if (!err) {
          next(transactions);
        }
      });
    }
  };

  const _handlePrev = () => {
    if (canPrev) {
      prev();
    }
  };

  const _handleRefresh = () => {
    refreshWalletTransactions(wallet, () => {
      jumpTo(1);
    });
  };

  return (
    <Card as={Flex} flex={1} mt={6} overflow="hidden" direction="column" p={0}>
      <WalletTransactionsHeader
        id={wallet}
        onRefresh={_handleRefresh}
        onEditClick={onEditClick}
      />
      <WalletTransactionsTable data={items} />
      <WalletTransactionsFooter
        canNext={canNext || !!nextCursor}
        canPrev={canPrev}
        onNext={_handleNext}
        onPrev={_handlePrev}
      />
    </Card>
  );
};

const mapStateToProps = (state, {wallet}) => ({
  nextCursor: getWalletTransactionsNextCursor(state, {wallet}),
  transactions: selectWalletTransactions(state, {wallet}),
});

const mapDispatchToProps = {
  getWalletTransactions: getWalletTransactionsAction,
  refreshWalletTransactions: refreshWalletTransactionsAction,
};

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.wallet === nextProps.wallet &&
  prevProps.nextCursor === nextProps.nextCursor &&
  prevProps.transactions.toString() === nextProps.transactions.toString()
);

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(WalletTransactions, propsAreEqual));
